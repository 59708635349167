/* REACT */
import { ReactElement, ChangeEvent, useState, useEffect } from 'react';

/* REACT HOOK FORM */
import { useForm } from 'react-hook-form';

/* COMPONENTS */
import ThirdPartiesAutocomplete from './ThirdPartiesAutocomplete';

/* VARIABLES */
import { ALL_DEVICES_TYPE, ALL_VEHICLE_TYPE } from './AllDeviceType';

/* CONTEXT */
import { useToast } from 'contexts/ToastContext';

/* YUP */
import validationSchema from './validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';

/* MUI */
import { Container, Paper, Grid, TextField, Typography, Button, Autocomplete } from '@mui/material';

/* REDUX */
import {
	useDeviceExistingQuery,
	useAllTpQuery,
	useThirdPartiesDevicesQuery,
	useLinkDeviceTpMutation
} from 'api/fleetApiSlice';

/* TYPES */
import { ThirdParty } from 'types/ThirdParty';

export type FormValues = {
	deviceId: string;
	vehicleName: string;
	immatriculation: string;
	deviceType: string;
	type: string;
	thirdParties: ThirdParty[];
};

export type LinkDeviceData = {
	deviceId: string;
	name: string | null;
	immatriculation: string | null;
	deviceType: string;
	type: string;
	thirdPartiesID: number[];
};

/** COMPONENT qui contient tous les champs d'informations pour lier un device à une ou plusieurs third parties*/
export default function DevicesLinkForm(): ReactElement {
	/* State */
	const [deviceId, setDeviceId] = useState('');

	/* Hooks */
	const {
		register,
		formState: { errors },
		setError,
		clearErrors,
		handleSubmit,
		setValue
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(validationSchema)
	});

	const { data: device, isSuccess } = useDeviceExistingQuery({ deviceId });
	const { data: thirdParties } = useAllTpQuery();
	const { data: thirdPartiesDevices } = useThirdPartiesDevicesQuery({ deviceId });
	const [linkDeviceTp] = useLinkDeviceTpMutation();
	const { showToast } = useToast();

	/* Functions */
	async function handleSubmitForm(data: FormValues) {
		const deviceTypeEn = data.type ? data.type.match(/\((.*?)\)/) : '';

		const linkData: LinkDeviceData = {
			deviceId: data.deviceId,
			name: data.vehicleName ?? '',
			immatriculation: data.immatriculation ?? '',
			deviceType: data.deviceType ?? '',
			type: deviceTypeEn ? deviceTypeEn[1] : '',
			thirdPartiesID: [] as number[]
		};

		data.thirdParties.map((thirdParty: ThirdParty) => linkData.thirdPartiesID.push(thirdParty.id));

		try {
			const linkDevice = await linkDeviceTp({ data: linkData });

			if ('data' in linkDevice && linkDevice.data.success) {
				showToast('Le device a été lié avec succès !', 'success');
			} else {
				throw new Error('Un problème a été détecté lors de la liaison du device');
			}
		} catch (error) {
			console.error(error);

			showToast('Un problème a été détecté lors de la liaison du device', 'error');
		}
	}

	function handleChangedDeviceId(event: ChangeEvent<HTMLInputElement>) {
		setDeviceId(event.target.value);
	}

	useEffect(() => {
		if (deviceId.length === 15 && !isSuccess) {
			setError('deviceId', {
				type: 'manual',
				message: 'Device inconnu'
			});
		}

		if (isSuccess) {
			clearErrors('deviceId');
		}
	}, [deviceId]);

	/* render */
	return (
		<Container
			component='main'
			maxWidth='sm'
		>
			<Paper
				variant='outlined'
				sx={{
					my: { xs: 3, md: 6 },
					p: { xs: 2, md: 3 }
				}}
			>
				<form onSubmit={handleSubmit(handleSubmitForm)}>
					<Typography
						component='h1'
						variant='h4'
						align='center'
					>
						Lier un device à une third-party
					</Typography>
					<Grid
						container
						spacing={3}
						sx={{ mt: 1, justifyContent: 'center' }}
					>
						<Grid
							item
							xs={10}
							sm={10}
						>
							<TextField
								required
								id='deviceId'
								label='IMEI du boitier'
								fullWidth
								variant='standard'
								error={Boolean(errors.deviceId)}
								{...register('deviceId', {
									minLength: 15,
									required: true
								})}
								onChange={handleChangedDeviceId}
							/>
							{errors.deviceId && (
								<Typography
									color='error'
									sx={{ fontSize: '12px' }}
								>
									{errors.deviceId.message}
								</Typography>
							)}
						</Grid>
						{isSuccess && device && thirdParties && thirdPartiesDevices && (
							<>
								<Grid
									item
									xs={10}
									sm={10}
								>
									<TextField
										required
										maxLength={255}
										fullWidth
										id='vehicleName'
										label='Nom du véhicule'
										variant='standard'
										error={Boolean(errors.vehicleName)}
										{...register('vehicleName', {
											maxLength: 255,
											required: true
										})}
										defaultValue={device?.data?.name}
									/>
									{errors.vehicleName && (
										<Typography
											color='error'
											sx={{ fontSize: '12px' }}
										>
											{errors.vehicleName.message}
										</Typography>
									)}
								</Grid>
								<Grid
									item
									xs={10}
									sm={10}
								>
									<TextField
										required
										maxLength={20}
										fullWidth
										id='immatriculation'
										label='Immatriculation du véhicule'
										variant='standard'
										error={Boolean(errors.immatriculation)}
										{...register('immatriculation', {
											maxLength: 20,
											required: true
										})}
										defaultValue={device?.data?.immatriculation}
									/>
									{errors.immatriculation && (
										<Typography
											color='error'
											sx={{ fontSize: '12px' }}
										>
											{errors.immatriculation.message}
										</Typography>
									)}
								</Grid>
								<Grid
									item
									marginTop={2}
									xs={8}
									sm={8}
								>
									<Autocomplete
										disablePortal
										defaultValue={device?.data?.deviceType ? device.data.deviceType : null}
										maxLength={15}
										id='deviceType'
										options={ALL_DEVICES_TYPE}
										sx={{ width: 300 }}
										{...register('deviceType', {
											maxLength: 15,
											required: true
										})}
										onChange={(event, value) => {
											setValue('deviceType', value ?? '');
										}}
										renderInput={(params) => {
											return (
												<TextField
													{...params}
													label='Sélectionner un type de device'
												/>
											);
										}}
									/>
									<Typography
										color='error'
										sx={{ fontSize: '12px' }}
									>
										{
											"Pour le kilométrage réel et niveau d'essence / niveau de batterie le boitier doit être de type FMC003"
										}
									</Typography>
								</Grid>

								<Grid
									item
									marginTop={2}
									xs={8}
									sm={8}
								>
									<Autocomplete
										disablePortal
										maxLength={25}
										defaultValue={device?.data?.type ? device.data.type : null}
										id='type'
										options={ALL_VEHICLE_TYPE}
										sx={{ width: 300 }}
										{...register('type', {
											maxLength: 25,
											required: true
										})}
										onChange={(event, value) => {
											setValue('type', value ?? '');
										}}
										renderInput={(params) => {
											return (
												<TextField
													{...params}
													label='Sélectionner un type de véhicule'
												/>
											);
										}}
									/>
								</Grid>
								<Grid
									item
									xs={8}
									sm={8}
								>
									<ThirdPartiesAutocomplete
										thirdParties={thirdParties}
										thirdPartiesDevices={thirdPartiesDevices}
										register={register}
										setValue={setValue}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sx={{ mt: 2 }}
								>
									<Button type='submit'>Envoyer</Button>
								</Grid>
							</>
						)}
					</Grid>
				</form>
			</Paper>
		</Container>
	);
}
