/* REDUX */
import { createSlice } from '@reduxjs/toolkit';

/* STATE */
const initalSelectedDeviceState = {
	deviceId: null,
	thirdPartyId: null,
	thirdPartyName: null,
	userLogin: null
};

/* SLICE REDUX */
export const devicesSlice = createSlice({
	name: 'selectedDeviceId',
	initialState: initalSelectedDeviceState,
	reducers: {
		getSelectedDeviceId: (state, action) => {
			state.deviceId = action.payload;
		},
		getSelectedThirdPartyId: (state, action) => {
			state.thirdPartyId = action.payload;
		},
		getSelectedThirdPartyName: (state, action) => {
			state.thirdPartyName = action.payload;
		}
	}
});

export const { getSelectedDeviceId, getSelectedThirdPartyId, getSelectedThirdPartyName } =
	devicesSlice.actions;
export default devicesSlice.reducer;
