import React from 'react';

/* HOOKS */
import { useNewClientContext } from 'contexts/NewClientContext';

/* STYLED COMPONENTS */
import { StyledTableCell } from './StylesTableReview';

/* MATERIAL UI */
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography
} from '@mui/material';

function ReviewAlertMovementForm(): React.ReactElement {
	/* Hooks */
	const clientInfos = useNewClientContext().clientInfos;

	return (
		<TableContainer
			component={Paper}
			sx={{
				marginTop: 4
			}}
		>
			<Typography
				variant='h6'
				id='tableTitle'
			>
				Récapitulatif des alertes de mouvement
			</Typography>
			<Table
				sx={{ minWidth: 650 }}
				stickyHeader
				ria-label='customized table'
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align='right'>Nom</StyledTableCell>
						<StyledTableCell align='right'>
							Alerte mouvement avant l&lsquo;heure indiquée
						</StyledTableCell>
						<StyledTableCell align='right'>
							Alerte mouvement après l&lsquo;heure indiquée
						</StyledTableCell>
						<StyledTableCell align='right'>Alerte mouvement jours</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{clientInfos.alertMovementInfos.map((row) => (
						<TableRow
							key={row.name}
							sx={{ border: 1 }}
						>
							<TableCell align='right'>{row.name}</TableCell>
							<TableCell align='right'>{row.movementAlertBefore}</TableCell>
							<TableCell align='right'>{row.movementAlertAfter}</TableCell>
							<TableCell align='right'>{row.movementAlertDays}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ReviewAlertMovementForm;
