export const smsCommands = [
	{
		command: 'getinfo',
		description: "Informations sur le système d'exécution de l'appareil",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getver',
		description:
			"Renvoie la version du code, l'IMEI de l'appareil, la version de l'application du modem, l'heure d'initialisation et RTC, le temps de disponibilité et l'adresse MAC BT",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getstatus',
		description: "Informations sur l'état du modem",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getgps',
		description: 'Données GPS actuelles, date et heure',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getio',
		description: "Lecture d'entrée analogique, d'entrée et de sortie numériques",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'ggps',
		description: 'Renvoie les informations de localisation avec le lien Google Maps',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'readio ',
		description: "Renvoie l'état des Entrées/Sorties (I/O) de l'appareil - #ID;",
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'cpureset',
		description: "Réinitialiser l'appareil",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'fwstats',
		description: 'Obtenir des données sur le microprogramme et les redémarrages',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getrecord',
		description:
			"La commande SMS lance la sauvegarde et l'envoi d'un enregistrement hautement prioritaire",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getimeiccid ',
		description:
			"La réponse est envoyée à l'expéditeur de la commande SMS ainsi qu'au numéro GSM fourni en paramètre - #Numéro de GSM;",
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getimsi',
		description: "Envoie le numéro IMSI de la carte SIM insérée en réponse à l'expéditeur",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'allver',
		description:
			"Répond avec des informations sur les versions du matériel et du micrologiciel et l'espace de stockage",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getparam ',
		description: 'Renvoie la valeur du paramètre sélectionné - #ID;ID2;ID3;',
		parameterNumber: 3,
		isIgnoredValue: true
	},
	{
		command: 'setparam ',
		description: 'Définit la valeur du paramètre sélectionné - #ID:valeur;',
		parameterNumber: 8,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'flush ',
		description:
			"Redirige l'appareil vers un autre serveur - #IMEI,APN,LOGIN,PASSWORD,IP,PORT,MODE;",
		parameterNumber: 7,
		parameterSeparator: ',',
		isIgnoredValue: false
	},
	{
		command: 'countrecs',
		description: "Renvoie le numéro d'enregistrement",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'web_connect',
		description: "Force l'appareil à se connecter immédiatement à Fota WEB",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'deleterecords',
		description: 'Supprimer tous les enregistrements de la carte SD',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'battery',
		description: 'Returns battery state info',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'wdlog',
		description: 'Renvoie toutes les informations sur les chiens de garde',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'defaultcfg',
		description: 'Charger la configuration par défaut',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'setkey',
		description:
			"Ajoutez un nouveau mot-clé de configuration ou modifiez-le. Si l'appareil est verrouillé, le mot-clé ne peut pas être modifié - #ANCIENNE CLE NOUVELLE CLE;",
		parameterNumber: 2,
		parameterSeparator: ' ',
		isIgnoredValue: false
	},
	{
		command: 'delkey',
		description:
			"Supprimer le mot-clé existant (si l'appareil est verrouillé, le mot-clé ne peut pas être modifié) - #ANCIENNE CLE;",
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'bbread',
		description:
			"Renvoyer les informations de la boîte noire (valeur HEX de l'ID d'événement et valeur HEX du champ de données personnalisé)",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'bbinfo',
		description:
			"Renvoie les mêmes informations que la commande bbread mais en plus, l'horodatage de tous les événements est écrit en HEX",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'sdformat',
		description: 'Formater la carte SD.',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'btgetlist ',
		description:
			'Renvoie la liste Bluetooth demandée - #0; ou #1; ou #2; (0 = Découvert, 1 = Couplé, 2 = Connecté, 3 = Appareils BT4/BLE découverts)',
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'btscan',
		description: "Démarre l'analyse Bluetooth",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'btvisible ',
		description: '	Sets Bluetooth to visible with TMO - #Number between 1 and 255seconds;',
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'btrelease ',
		description:
			"Se déconnecte de l'appareil actuel et met en pause la fonctionnalité de connexion automatique pour TMO - #Number between 1 and 255seconds;",
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'btunpair ',
		description: 'Dissocier un appareil Bluetooth - #Bluetooth address;',
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'fc_reset',
		description: 'Réinitialise les paramètres de consommation de carburant',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'towingreact',
		description: 'Réactivation du remorquage',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'auto_calibrate:get',
		description: 'Returns the state of calibration',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'odoset:',
		description: 'Définir la valeur totale du compteur kilométrique - #New odometer value in km;',
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'odoget',
		description: 'Afficher la valeur actuelle du compteur kilométrique',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'on_demand_tracking0',
		description: 'Arrête la fonctionnalité de suivi à la demande',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'on_demand_tracking1',
		description: 'Démarre la fonctionnalité de suivi à la demande',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'on_demand_tracking2',
		description:
			"Génère un enregistrement hautement prioritaire et lance l'envoi de données au serveur",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'obdinfo',
		description:
			"La commande fonctionne sur les trackers OBD avec capacités de lecture de données (FMX00Y). La réponse affiche toutes les informations disponibles à partir de l'OBD",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'faultcodes',
		description:
			"La commande fonctionne sur les trackers OBD avec capacités de lecture de données (FMX00Y). La réponse affiche tous les codes d'erreur visible",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'cleardtc',
		description:
			"La commande fonctionne sur les trackers OBD avec des capacités de lecture de données (FMX00Y). La commande efface tous les codes d'erreur enregistrés dans le véhicule",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'getvin',
		description:
			'La commande fonctionne sur les trackers OBD avec des capacités de lecture de données (FMX00Y). La réponse affiche le code VIN du véhicule',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcansetprog ',
		description:
			"Définir le numéro de programme sur l'adaptateur CAN - #Numéro spécifique du véhicule de 3 à 5 chiffres;",
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcangetprog',
		description: "Obtenir le numéro de programme de l'adaptateur CAN",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcangetinfo',
		description: "Obtenir des informations sur l'adaptateur CAN connecté",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcansimpletacho ',
		description: 'Ajouter ou supprimer un octet de démarrage tachymétrique simple - #0 ou #1;',
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanclear ',
		description:
			'Effacer les valeurs des paramètres de kilométrage total, de temps de fonctionnement du moteur et de consommation de carburant - #0; ou #1; ou #2; (0 – Engine work time (counted), 1 – Fuel Consumed (counted), 2 – Vehicle Mileage (counted))',
		parameterNumber: 1,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanfaultcodes',
		description: "Lire les codes d'erreur DTC",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanopenalldoors',
		description: 'Ouvrir toutes les portes',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'vcanclosealldoors',
		description: 'Fermer toutes les portes',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanopentrunk',
		description: 'Ouvrir le coffre',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanblockengine',
		description: 'Bloquer le moteur du véhicule',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanunblockengine',
		description: 'Débloquer le moteur du véhicule',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanturninglights',
		description: 'Faire clignoter les feux',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanhorn',
		description: 'Activer le son du klaxon',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcansetting ',
		description:
			'Définir des compteurs spécifiques pour les véhicules - #Nombre entre 0 et 5; #Valeur entre 0 et 0xFFFFFFFF',
		parameterNumber: 2,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanrefresh',
		description:
			"Cette commande force la connexion FMB1YX à FOTA WEB pour signaler l'état actuel de l'adaptateur CAN",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcanreset',
		description: "Cette commande force la réinitialisation de l'adaptateur CAN",
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	},
	{
		command: 'lvcancheck',
		description: 'Cette commande rapporte les informations de connexion des lignes CAN',
		parameterNumber: 0,
		parameterSeparator: ';',
		isIgnoredValue: false
	}
] as const;
