/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import {
	useGetSimOperatorCountryQuery,
	useGetSimStatusAndOperatorQuery,
	useGetGsmInformationsQuery
} from '../../../api/fleetApiSlice';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* TYPES */
import { SimOperatorCountry, SimInformations, OnceSimStatus } from '../../../types/Sim';

/* SVG */
import AntennaSvg from '../../../components/svg/Antenna';
import NetworkSvg from '../../../components/svg/Network';

const NetworkBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	font-size: 0.9rem;
	width: 90%;
	gap: 5px;
`;

const SimOperatorCountryBox = styled.div``;

const Operator = styled.p`
	font-size: 1rem;
`;

const Country = styled.p`
	color: grey;
`;

const NetworkInformationsBox = styled.div`
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
`;

const NetWorkSignalStrength = styled.div``;

const SignalStrength = styled.p``;

const NetworkType = styled.p`
	color: grey;
`;

type NetworkProps = {
	deviceId: string;
};

export default function Network({ deviceId }: NetworkProps): ReactElement {
	const { data: simOperatorCountry } = useGetSimOperatorCountryQuery({ deviceId });
	const { data: simStatusAndOperator } = useGetSimStatusAndOperatorQuery({ deviceId });
	const { data: gsmInformations } = useGetGsmInformationsQuery({ deviceId });

	function operatorCountry(simInformations: SimOperatorCountry | undefined) {
		return (
			<SimOperatorCountryBox>
				<Operator>{simInformations ? simInformations.operator : 'Opérateur indisponible'}</Operator>
				<Country>{simInformations ? simInformations.country : 'Pays indisponible'}</Country>
			</SimOperatorCountryBox>
		);
	}

	function networkInformation(
		simInformations: SimInformations[] | undefined,
		networkInformation: OnceSimStatus | undefined
	) {
		return (
			<NetworkInformationsBox>
				<NetworkSvg
					width={50}
					height={50}
					networkRodNumber={simInformations ? simInformations[0].signalStrength : 0}
				/>
				<NetWorkSignalStrength>
					<SignalStrength>
						{simInformations && simInformations[0].signalStrength
							? simInformations[0].signalStrength
							: 0}
						/5
					</SignalStrength>
					<NetworkType>
						{networkInformation?.pdp_context?.rat_type?.description
							? networkInformation.pdp_context.rat_type.description
							: 'Type de réseau indisponible'}
					</NetworkType>
				</NetWorkSignalStrength>
			</NetworkInformationsBox>
		);
	}

	return (
		<NetworkBox>
			<AntennaSvg
				width={30}
				height={30}
			/>
			{operatorCountry(simOperatorCountry)}
			{networkInformation(gsmInformations, simStatusAndOperator)}
		</NetworkBox>
	);
}
