/* REACT */
import { ReactElement } from 'react';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* APEXCHARTS */
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

/* REDUX */
import {
	useGetQuotaDataQuery,
	useGetQuotaSmsQuery,
	useGetSimBrandQuery
} from '../../../api/fleetApiSlice';

/* TYPES */
import { QuotaDataAndSms } from '../../../types/Sim';

/* SVG */
import LinkSvg from '../../../components/svg/LinkSvg';

/* TYPES */
/* TYPES */
type VolumeDataSmsProps = {
	deviceId: string;
	label: string;
};

/* VARIABLES STYLED COMPONENTS */
/* VARIABLES STYLED COMPONENTS */
const VolumeDataSmsSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 45%;
	background: #222b32;
	border-radius: 10px;
`;

const VolumeDataSmsTitle = styled.h5`
	color: white;
	text-align: center;
`;

const Point = styled.span`
	font-size: 3rem;
	color: ${(props) => props.color};
`;

const Volumes = styled.p`
	color: white;
	font-size: 0.7rem;
	line-height: 0px;
`;

const LinkButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 30px;
	border-radius: 12px;
	background-color: inherit;
	border: solid #f2c94c 1px;
	cursor: pointer;
	color: #f2c94c;
	&:hover {
		transition: 0.2s;
		transform: scale(1.1);
	}
	&:focus,
	&:active,
	&:visited,
	&:link {
		color: #f2c94c;
	}

	a {
		color: #f2c94c;
		text-decoration: none;
	}
`;

const TruphoneURL = styled.a`
	text-decoration: none;
	color: white;
`;

/* VARIABLES */
/* VARIABLES */
const COLOR = {
	red: '#EB5757',
	orange: '#F76519',
	yellow: '#F2C94C'
};

/* COMPONENT */
/* Component */
export default function VolumeDataSms({ deviceId, label }: VolumeDataSmsProps): ReactElement {
	/* RTK QUERY HOOK */
	const { data: quotaData } = useGetQuotaDataQuery({ deviceId });
	const { data: quotaSms } = useGetQuotaSmsQuery({ deviceId });
	const { data: simBrand } = useGetSimBrandQuery({ deviceId });

	/* VARIABLES */
	const volumeType = label === 'data' ? quotaData : quotaSms;

	const quotaPercent =
		volumeType instanceof Object
			? 100 - Math.floor((volumeType.volume / volumeType.total_volume) * 100)
			: 0;

	/* Fonction qui retourne une clour selon le quota */
	function seriesColor(pourcent: number = quotaPercent): string | undefined {
		if (pourcent > 90) {
			return COLOR.red;
		} else if (pourcent > 80) {
			return COLOR.orange;
		} else {
			return COLOR.yellow;
		}
	}

	/* VARIABLES APEXCHART */
	const radialBarOptions: ApexOptions = {
		series: [quotaPercent],
		colors: [seriesColor(quotaPercent)],
		plotOptions: {
			radialBar: {
				startAngle: -135,
				endAngle: 135,
				track: {
					background: '#333',
					startAngle: -135,
					endAngle: 135
				},
				dataLabels: {
					name: {
						show: false
					},
					value: {
						fontSize: '17px',
						show: true,
						color: seriesColor(quotaPercent),
						offsetY: 7
					}
				}
			}
		},
		stroke: {
			lineCap: 'butt'
		},
		labels: ['Progress']
	};

	function quotaInformations(quota: QuotaDataAndSms, volumeLabel: string): ReactElement {
		if (quota instanceof Object) {
			return (
				<>
					<Chart
						options={radialBarOptions}
						series={radialBarOptions.series}
						type='radialBar'
						width='150px'
						height='120px'
					/>
					<Volumes>
						<Point color={seriesColor(quotaPercent)}>.</Point>
						{Math.floor(quota.total_volume - quota?.volume)}/{quota.total_volume}{' '}
						{volumeLabel === 'data' ? 'MB' : 'SMS'}
					</Volumes>
				</>
			);
		} else {
			return simBrand !== '1nce' ? (
				<Volumes>Volume indisponible</Volumes>
			) : (
				<LinkButton>
					<LinkSvg
						height={15}
						width={15}
					/>
					<TruphoneURL
						href={quota}
						target='_blank'
						rel='noreferrer'
					>
						Afficher les volumes
					</TruphoneURL>
				</LinkButton>
			);
		}
	}

	/* RETURN */
	return (
		<VolumeDataSmsSection>
			<VolumeDataSmsTitle>Volume {label} utilisé</VolumeDataSmsTitle>
			{label === 'data'
				? quotaData && quotaInformations(quotaData, label)
				: quotaSms && quotaInformations(quotaSms, label)}
			{!quotaData && !quotaSms && <Volumes>Volume indisponible</Volumes>}
		</VolumeDataSmsSection>
	);
}
