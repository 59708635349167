type DisabledSimSvgProps = {
	width: number | string;
	height: number | string;
};

function DisabledSimSvg({ width, height }: DisabledSimSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
		>
			<path
				d='M15.63 17.0475L14.595 16.0125C14.325 16.3125 13.935 16.5 13.5 16.5H4.50002C3.66752 16.5 3.00002 15.8325 3.00002 15V5.99999L3.79502 5.21249L0.83252 2.24999L1.79252 1.29749L16.5825 16.095L15.63 17.0475ZM15 2.99999C15 2.17499 14.3325 1.49999 13.5 1.49999H7.50002L5.70002 3.29999L15 12.6V2.99999Z'
				fill='#E9696D'
			/>
		</svg>
	);
}

export default DisabledSimSvg;
