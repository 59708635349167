/* REACT */
import { ReactElement, useState, useEffect } from 'react';

/* REDUX */
import { useGetXLastTripsQuery } from '../../../api/fleetApiSlice';

/* TYPES */
import { OneTrip } from '../../../types/Trip';

/* SVG */
import CompletRoadSvg from '../../../components/svg/CompletRoadSvg';
import FalseSvg from '../../../components/svg/CrossSvg';
import ConfirmSvg from '../../../components/svg/ConfirmSvg';

/* CSS */
import styled from 'styled-components';

/* VARIABLES */
const LastCompletTripSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 28%;
  height: 75px;
  background: #222b32;
  font-size: 0.7rem;
  color: white;
  border-radius: 10px;
  padding 0 15px;
  text-align: center;
`;

function LastCompletTrip({ deviceId }: { deviceId: string }): ReactElement {
	const [endTime, setEndTime] = useState<OneTrip>();

	const { data: trips } = useGetXLastTripsQuery({ deviceId, tripCount: 100 });

	useEffect(() => {
		deviceId && trips && setEndTime(trips.find((trip) => trip.endTripDate));
	}, [deviceId, trips]);

	// Fonction qui prend en parametre une date et qui retourne l'heure de cette date
	function getHoursAndMinutesFromDate(date: Date): string {
		if (date) {
			const hour = date.getHours();
			const minutes = date.getMinutes();

			const finalHour = `${hour}h${minutes.toString().padStart(2, '0')}`;

			return finalHour;
		} else {
			return 'Heure indisponible';
		}
	}

	// Fonction qui donne la date, l'heure de début et de fin du dernier trajet complet
	function lastCompleteTripDate(lastTrip: OneTrip | undefined) {
		if (lastTrip && endTime && lastTrip.startTripDate && lastTrip.endTripDate) {
			const startTripDate = new Date(lastTrip.startTripDate);
			const endTripDate = new Date(lastTrip.endTripDate);

			// Obtenir la date, jour, mois et année de la semaine du dernier trajet complet le plus récent
			const dayOfWeek = new Intl.DateTimeFormat('fr-FR', {
				weekday: 'long',
				month: 'long',
				year: 'numeric',
				day: '2-digit'
			}).format(startTripDate);

			// // Obtenir l'heure de début
			const startTime = getHoursAndMinutesFromDate(startTripDate);

			// // Obtenir l'heure de fin
			const endTime = getHoursAndMinutesFromDate(endTripDate);

			return `${dayOfWeek.replace(
				dayOfWeek[0],
				dayOfWeek[0].toUpperCase()
			)} ${startTime} - ${endTime}`;
		} else {
			return <p>Date non disponible</p>;
		}
	}

	// Fonction qui affiche un SVG de confirmation si le dernier trajet complet est inférieur à 3jours (par rapport au jour acutel)
	function incompleteTripsSvg(lastTrip: OneTrip | undefined) {
		if (deviceId && lastTrip?.endTs && (Date.now() - lastTrip?.endTs) / (1000 * 3600 * 24) <= 3) {
			return (
				<ConfirmSvg
					width={35}
					height={35}
				/>
			);
		} else {
			return (
				<FalseSvg
					width={35}
					height={35}
				/>
			);
		}
	}

	// Le return du component
	return (
		<LastCompletTripSection>
			<CompletRoadSvg
				height={30}
				width={30}
			/>
			<div>
				<p style={{ fontWeight: 'bold' }}>Dernier trajet complet</p>
				{lastCompleteTripDate(endTime)}
			</div>
			{incompleteTripsSvg(endTime)}
		</LastCompletTripSection>
	);
}

export default LastCompletTrip;
