/* eslint-disable react/display-name */
/* REACT */
import { ReactElement, ChangeEvent, useMemo, ReactNode } from 'react';

/* COMPONENTS */

/* MUI */
import { yellow } from '@mui/material/colors';
import { TextField, Typography, Autocomplete, Checkbox, Paper } from '@mui/material';

/* STYLES */
import { Theme } from 'styles/Theme';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const SmsHeaderBox = styled.div`
	height: 300px;
	width: 95%;
	background-color: ${Theme.dark.background.secondary};
	border-radius: 10px;
	color: white;
`;

const AutocompleteStyled = styled(Autocomplete)({
	width: 400,
	color: 'white',
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&:hover fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&.Mui-focused fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		}
	},
	'& .MuiInputBase-input': {
		color: 'white',
		'&:focus': {
			outline: 'none',
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		}
	},
	'& .MuiInputLabel-root': {
		color: Theme.dark.text.secondary
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: Theme.dark.text.secondary
	}
});

const TextFieldStyled = styled(TextField)({
	width: 400,
	'& .MuiInputLabel-root': {
		color: Theme.dark.text.secondary
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: Theme.dark.text.secondary
	},
	'& .MuiInputBase-input': {
		color: Theme.dark.text.secondary
	},
	color: 'white',
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&:hover fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&.Mui-focused fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		}
	},
	'& .MuiAutocomplete-clearIndicator': {
		color: Theme.dark.text.secondary,
		'&:hover': {
			color: Theme.dark.text.secondary
		}
	}
});

const TpDevicesBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 50px;
`;

const CheckboxStyled = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	position: relative;
	left: 40px;
	top: 20px;
`;

/* TYPES */
interface SmsFormHeaderProps {
	alltpName: string[];
	setThirdParty: (value: string) => void;
	setSelectedValue: (value: string) => void;
	setIsChecked: (value: boolean) => void;
}

/* Composant qui permet de chercher une third party et un device */
export default function SmsFormHeader({
	alltpName,
	setThirdParty,
	setSelectedValue,
	setIsChecked
}: SmsFormHeaderProps): ReactElement {
	/* Functions */
	function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
		setIsChecked(event.target.checked);
	}

	const PaperList = useMemo(
		() =>
			({ children }: { children: ReactNode }) =>
				(
					<Paper
						sx={{
							'& .MuiAutocomplete-listbox': {
								backgroundColor: Theme.dark.background.secondary,
								color: Theme.dark.text.primary,
								maxHeight: '200px',
								overflow: 'auto',
								textAlign: 'left'
							}
						}}
					>
						{children}
					</Paper>
				),
		[]
	);

	/* Render */
	return (
		<SmsHeaderBox>
			<Typography
				component='h1'
				variant='body1'
				align='center'
				color='whitesmoke'
				style={{ marginBottom: '50px', marginTop: '20px' }}
			>
				{"Envoyer une commande SMS à un ou plusieurs devices d'une third party"}
			</Typography>

			<TpDevicesBox>
				<AutocompleteStyled
					id='thirdParties'
					disablePortal
					options={alltpName}
					onChange={(_, value) => {
						value ? setThirdParty(value as string) : '';
					}}
					// @ts-expect-error - Nécessaire car la prop PaperComponent n'est pas reconnue par Autocomplete mais ça fonctionne
					PaperComponent={PaperList}
					renderInput={(params) => (
						<TextFieldStyled
							{...params}
							label='Rechercher une entreprise'
						/>
					)}
				/>

				<TextFieldStyled
					id='tpDevices'
					required
					label='Rechercher un device'
					onChange={(event) => setSelectedValue(event.target.value)}
				/>
			</TpDevicesBox>

			<CheckboxStyled>
				<Checkbox
					sx={{
						color: yellow[800],
						'&.Mui-checked': {
							color: yellow[800]
						}
					}}
					onChange={handleCheckboxChange}
				/>
				<Typography
					component='p'
					variant='body1'
					align='center'
					color='whitesmoke'
					sx={{ fontSize: '0.8rem' }}
				>
					Sélectionner tous les devices
				</Typography>
			</CheckboxStyled>
		</SmsHeaderBox>
	);
}
