/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetUnplugHistoryQuery } from '../../../api/fleetApiSlice';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* SVG */
import PlugSvg from '../../../components/svg/PlugSvg';
import UnplugSvg from '../../../components/svg/UnplugSvg';
import LinkSvg from '../../../components/svg/LinkSvg';

/* TYPES */
import { DeviceUnplugHistory } from '../../../types/Devices';

type UnplugHistoryProps = {
	deviceId: string;
};

type GoogleMapsLinkProps = {
	href: string;
	target?: string;
	rel?: string;
};

type LinkButtonProps = {
	visibility: 'hidden' | 'visible';
};

const UnplugHistorySection = styled.section`
	dipslay: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50%;
	max-height: 200px;
	color: #f2f2f2;
	background-color: #222b32;
	border-radius: 10px;
	overflow: scroll;
	text-decoration: none;
`;

const UnplugHistoryTitle = styled.h3`
	text-align: center;
`;

const LinkButton = styled.button`
	visibility: ${(props: LinkButtonProps) => props.visibility};
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 25px;
	width: 150px;
	border-radius: 12px;
	background-color: inherit;
	border: solid #f2c94c 1px;
	cursor: pointer;
	color: #f2c94c;
	&:hover {
		transition: 0.2s;
		transform: scale(1.1);
	}
	&:focus,
	&:active,
	&:visited,
	&:link {
		color: #f2c94c;
	}

	a {
		color: #f2c94c;
	}
`;

const NoUnpluggedHistoryText = styled.p`
	text-align: center;
	font-size: 0.8rem;
`;

const UnpluggedInformationsBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 0.7rem;
	padding-left: 15px;
	padding-right: 15px;
	&:nth-child(2) {
		font-weight: bold;
	}
`;

const UnpluggedDateBox = styled.div`
	text-align: center;
`;

const UnpluggedDateText = styled.p``;

const UnpluggedAdressText = styled.p``;

const GoogleMapsLink = styled.a<GoogleMapsLinkProps>`
	text-decoration: none;
`;

function UnplugHistory({ deviceId }: UnplugHistoryProps): ReactElement {
	const { data: plugUnplugHistory } = useGetUnplugHistoryQuery({ deviceId });

	function showUnplugHistory(unpluggedInfos: DeviceUnplugHistory[] | undefined): ReactElement {
		if (unpluggedInfos && unpluggedInfos.length > 0) {
			return (
				<>
					{unpluggedInfos.map((element: DeviceUnplugHistory, index: number) => {
						const lon = element.lon ?? 0;
						const lat = element.lat ?? 0;

						return (
							<UnpluggedInformationsBox key={index}>
								{element.value === 1 ? (
									<UnplugSvg
										height={20}
										width={20}
									/>
								) : (
									<PlugSvg
										height={20}
										width={20}
									/>
								)}
								<UnpluggedDateBox>
									<UnpluggedDateText>{element.End}</UnpluggedDateText>
									{lon && lat ? (
										<UnpluggedAdressText>{`${element.adresse.address} ${element.adresse.zipcode} ${element.adresse.location}`}</UnpluggedAdressText>
									) : (
										<UnpluggedAdressText>Adresse indisponible</UnpluggedAdressText>
									)}
								</UnpluggedDateBox>
								<LinkButton visibility={lon === 0 && lat === 0 ? 'hidden' : 'visible'}>
									<LinkSvg
										height={15}
										width={15}
									/>
									<GoogleMapsLink
										href={`https://www.google.fr/maps/place/${lat * 0.0000001},${
											lon * 0.0000001
										}/@${lat * 0.0000001},${lon * 0.0000001},18z`}
										target='_blank'
										rel='noreferrer'
									>
										Google Maps
									</GoogleMapsLink>
								</LinkButton>
							</UnpluggedInformationsBox>
						);
					})}
				</>
			);
		} else {
			return <NoUnpluggedHistoryText>Aucune information disponible.</NoUnpluggedHistoryText>;
		}
	}

	return (
		<UnplugHistorySection>
			<UnplugHistoryTitle>Historique des (dé)branchements</UnplugHistoryTitle>
			{showUnplugHistory(plugUnplugHistory)}
		</UnplugHistorySection>
	);
}
export default UnplugHistory;
