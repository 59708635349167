type CarSvgProps = {
	width: number;
	height: number;
};

function CarSvg({ width, height }: CarSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
		>
			<path
				d='M15.1915 7.92666L14.0541 4.65589C13.7459 3.76642 12.9054 3.16406 11.9641 3.16406H6.0192C5.07787 3.16406 4.2374 3.76079 3.92923 4.65026L2.79179 7.92103C2.18105 8.32073 1.80005 9.01879 1.80005 9.77878V13.7251C1.80005 14.3387 2.29311 14.8397 2.90946 14.8397C3.5258 14.8397 4.01888 14.3443 4.01888 13.7251V13.0214C4.01888 13.0214 6.9437 13.1678 9.00004 13.1678C11.0564 13.1678 13.9812 13.0214 13.9812 13.0214V13.7251C13.9812 14.3387 14.4743 14.8397 15.0906 14.8397C15.707 14.8397 16.2 14.3443 16.2 13.7251V9.77878C16.2 9.01316 15.8134 8.32073 15.2083 7.92103L15.1915 7.92666ZM4.33826 6.84578L4.9714 5.02744C5.12829 4.58271 5.54853 4.28434 6.01359 4.28434H11.9585C12.4291 4.28434 12.8494 4.58271 13.0007 5.02744L13.6338 6.84578C13.7347 7.13289 13.4938 7.42 13.1968 7.37496C12.1098 7.20045 10.7146 7.06534 8.98323 7.06534C7.25187 7.06534 5.8511 7.20045 4.76969 7.37496C4.47273 7.42563 4.2318 7.13289 4.33265 6.84578H4.33826ZM3.73311 10.398C3.27366 10.398 2.90386 10.0265 2.90386 9.56485C2.90386 9.10323 3.27366 8.73168 3.73311 8.73168C4.19257 8.73168 4.56238 9.10323 4.56238 9.56485C4.56238 10.0265 4.19257 10.398 3.73311 10.398ZM11.2021 12.6217H6.7756C6.46743 12.6217 6.2209 12.0925 6.2209 11.7885C6.2209 11.4845 6.46743 10.9554 6.7756 10.9554H11.2021C11.5103 10.9554 11.7568 11.4845 11.7568 11.7885C11.7568 12.0925 11.5103 12.6217 11.2021 12.6217ZM14.2446 10.398C13.7851 10.398 13.4153 10.0265 13.4153 9.56485C13.4153 9.10323 13.7851 8.73168 14.2446 8.73168C14.704 8.73168 15.0738 9.10323 15.0738 9.56485C15.0738 10.0265 14.704 10.398 14.2446 10.398Z'
				fill='white'
				fillOpacity='0.8'
			/>
		</svg>
	);
}

export default CarSvg;
