/* REACT */
import { ReactElement } from 'react';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* REDUX */
import { useGetGsmInformationsQuery } from '../../../api/fleetApiSlice';

/* SVG */
import IccidSvg from '../../../components/svg/IccidSvg';

/* TYPES */
type IccidProps = {
	deviceId: string;
};

/* VARIABLES STYLED COMPONENTS */
const IccidBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	text-align: center;
`;

const IccidText = styled.p``;

const IccidNumber = styled.p`
	color: grey;
`;

/* Component */
export default function Iccid({ deviceId }: IccidProps): ReactElement {
	const { data: GsmInformations } = useGetGsmInformationsQuery({ deviceId });

	/* return */
	return (
		<IccidBox>
			<IccidSvg
				width={25}
				height={25}
			/>
			<IccidText>ICCID</IccidText>
			<IccidNumber>{GsmInformations ? GsmInformations[0].iccid : 'Indisponible'}</IccidNumber>
		</IccidBox>
	);
}
