type IdSvgProps = {
	width: number;
	height: number;
};

function IdSvg({ width, height }: IdSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 12 10'
			fill='none'
		>
			<path
				d='M1.84375 0.046875V10H0.12793V0.046875H1.84375ZM6.86816 10H4.74219L4.75586 8.63965H6.86816C7.4834 8.63965 7.99837 8.50521 8.41309 8.23633C8.83236 7.96745 9.14681 7.58236 9.35645 7.08105C9.57064 6.57975 9.67773 5.98275 9.67773 5.29004V4.75C9.67773 4.21224 9.61621 3.736 9.49316 3.32129C9.37467 2.90658 9.19694 2.55794 8.95996 2.27539C8.72754 1.99284 8.44043 1.77865 8.09863 1.63281C7.76139 1.48698 7.37174 1.41406 6.92969 1.41406H4.70117V0.046875H6.92969C7.59049 0.046875 8.19434 0.158529 8.74121 0.381836C9.28809 0.600586 9.75977 0.917318 10.1562 1.33203C10.5573 1.74674 10.8649 2.24349 11.0791 2.82227C11.2933 3.40104 11.4004 4.04818 11.4004 4.76367V5.29004C11.4004 6.00553 11.2933 6.65267 11.0791 7.23145C10.8649 7.81022 10.5573 8.30697 10.1562 8.72168C9.75521 9.13184 9.27669 9.44857 8.7207 9.67188C8.16927 9.89062 7.55176 10 6.86816 10ZM5.69238 0.046875V10H3.97656V0.046875H5.69238Z'
				fill='#F2C94C'
			/>
		</svg>
	);
}

export default IdSvg;
