/* REACT */
import type { ReactElement } from 'react';

/* CSS */
import { Theme } from '../../styles/Theme';

/* REACT TOSTIFY */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* STYLED COMPONENTS */
import styled from 'styled-components';
import MultiStepForm from './stepsForm/MultiStepForm';

const NewAccountSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	height: auto;
	width: 100%;
	position: absolute;
	right: 0;
	background-color: ${Theme.dark.background.primary};
`;

/* Section de formulaire de création de compte */
function NewAccount(): ReactElement {
	/* Render */
	return (
		<NewAccountSection>
			<MultiStepForm />
			<ToastContainer />
		</NewAccountSection>
	);
}

export default NewAccount;
