type AttachedSvgProps = {
	width: number;
	height: number;
};

function AttachedSvg({ width, height }: AttachedSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 19 18'
			fill='none'
		>
			<g clipPath='url(#clip0_1514_2634)'>
				<path
					d='M9.3001 2.25C6.1501 2.25 3.0751 3.3 0.600098 5.25C3.6001 9.075 6.2251 12.3 9.3001 16.125C11.1001 13.875 12.8251 11.775 14.5501 9.6V7.2L14.4001 7.425C13.2751 6.75 11.4751 6 9.3001 6C7.0501 6 5.3251 6.75 4.2001 7.35L2.7751 5.55C4.7251 4.35 6.9751 3.75 9.3001 3.75C11.6251 3.75 13.8751 4.425 15.8251 5.55L15.5251 6H17.4751C17.6251 5.775 17.9251 5.475 18.0751 5.25C15.5251 3.3 12.4501 2.25 9.3001 2.25ZM16.0501 7.5V12H17.5501V7.5M16.0501 13.5V15H17.5501V13.5'
					fill='#FF9947'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1514_2634'>
					<rect
						width='18'
						height='18'
						fill='white'
						transform='translate(0.600098)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default AttachedSvg;
