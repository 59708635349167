/* REACT */
import { useState, useEffect, ReactElement } from 'react';

/* REDUX */
import { useAllTpQuery, useAllTpDevicesQuery } from 'api/fleetApiSlice';

/* COMPONENTS */
import SmsFormHeader from './header/SmsFormHeader';
import SmsFormDevices from './tp-devices/SmsFormDevices';
import SmsFormFooter from './footer/SmsFormFooter';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const SmsSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	width: 1000px;
	background-color: #1d212a;
	gap: 10px;
`;

/** COMPONENT qui permet d'envoyer une commande sms à un ou plusieurs devices d'une thirdParty */
export default function SmsForm(): ReactElement {
	/* State */
	const [thirdParty, setThirdParty] = useState<string>('');
	const [devices, setDevices] = useState<string[]>([]);
	const [selectedValue, setSelectedValue] = useState<string>('');
	const [isChecked, setIsChecked] = useState<boolean>(false);

	/* Hooks */
	const { data: thirdParties, isSuccess: tpSuccess } = useAllTpQuery();
	const { data: tpDevices, isSuccess: tpDevicesSuccess } = useAllTpDevicesQuery({
		thirdPartyId: parseInt(thirdParty.split(' ')[0], 10)
	});

	/* Variables */
	const alltpName = tpSuccess
		? thirdParties?.data.map((thirdParty) => thirdParty.id + ' ' + thirdParty.name)
		: [];

	/* Function */
	function handleSelectAllDevices() {
		if (isChecked && tpDevicesSuccess) {
			setDevices(tpDevices.map((device) => device.deviceId));
		} else {
			setDevices([]);
		}
	}

	useEffect(() => {
		handleSelectAllDevices();
	}, [isChecked, tpDevicesSuccess, tpDevices]);

	/* Render */
	return (
		<SmsSection>
			<SmsFormHeader
				alltpName={alltpName}
				setThirdParty={setThirdParty}
				setSelectedValue={setSelectedValue}
				setIsChecked={setIsChecked}
			/>
			<SmsFormDevices
				devices={devices}
				setDevices={setDevices}
				selectedValue={selectedValue}
				tpDevices={tpDevicesSuccess ? tpDevices : []}
				isChecked={isChecked}
			/>
			<SmsFormFooter
				thirdParty={thirdParty}
				setIsChecked={setIsChecked}
				devices={devices}
				tpDevices={tpDevicesSuccess ? tpDevices : []}
			/>
		</SmsSection>
	);
}
