/* REACT */
import { type ChangeEvent, type ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* MUI */
import { Grid, Divider, Typography, TextField, Button } from '@mui/material';

/* COMPONENT */
export default function AlertMovementForm(): ReactElement {
	/* Hooks */
	const { control, errors, setClientInfos, clientInfos } = useNewClientContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'alertMovementInfos'
	});

	function changeNewClientInfos(event: ChangeEvent<HTMLInputElement>, index: number) {
		const { value, name } = event.target;

		setClientInfos((prevClientInfos) => {
			const updatedAlertMovementInfos = prevClientInfos.alertMovementInfos.map((movement, idx) => {
				if (idx === index) {
					return {
						...movement,
						[name]: value
					};
				}
				return movement;
			});

			return {
				...prevClientInfos,
				alertMovementInfos: updatedAlertMovementInfos
			};
		});
	}

	function addAlertMovement() {
		append({ movementAlertBefore: '', movementAlertAfter: '', movementAlertDays: '' });

		setClientInfos((prevClientInfos) => {
			const updatedAlertMovementInfos = prevClientInfos.alertMovementInfos.concat({
				movementAlertBefore: '',
				movementAlertAfter: '',
				movementAlertDays: ''
			});

			return {
				...prevClientInfos,
				alertMovementInfos: updatedAlertMovementInfos
			};
		});
	}

	function removeAlertMovement(index: number) {
		setClientInfos((prevClientInfos) => {
			const updatedAlertMovementInfos = prevClientInfos.alertMovementInfos.filter(
				(movement, idx) => idx !== index
			);

			return {
				...prevClientInfos,
				alertMovementInfos: updatedAlertMovementInfos
			};
		});

		remove(index);
	}

	return (
		<Grid
			container
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
		>
			<Divider sx={{ width: '700px', marginBottom: 3 }}>
				<Typography variant='h6'> Alerte Movement Form</Typography>
			</Divider>
			{fields.map((item, index) => {
				return (
					<Grid
						container
						justifyContent='space-around'
						key={item.id}
						sx={{ marginBottom: 4, marginTop: 2 }}
					>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<TextField
								required
								fullWidth
								error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
								helperText={
									errors.alertMovementInfos && errors.alertMovementInfos[index]
										? errors.alertMovementInfos[index]?.message
										: ''
								}
								name={`movementAlertBefore`}
								id='movementAlertBefore'
								label="Alerte mouvement avant l'heure indiquée"
								autoComplete='movementAlertBefore'
								placeholder='09:00 (soit 9h00)'
								variant='standard'
								autoFocus={false}
								value={clientInfos.alertMovementInfos[index].movementAlertBefore}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>
							<TextField
								required
								fullWidth
								error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
								helperText={
									errors.alertMovementInfos && errors.alertMovementInfos[index]
										? errors.alertMovementInfos[index]?.message
										: ''
								}
								name={`movementAlertAfter`}
								id='movementAlertAfter'
								label="Alerte mouvement après l'heure indiquée"
								placeholder='18:00 (soit 18h00)'
								autoComplete='movementAlertAfter'
								variant='standard'
								autoFocus={false}
								value={clientInfos.alertMovementInfos[index].movementAlertAfter}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>
						</Grid>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<TextField
								required
								fullWidth
								error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
								helperText={
									errors.alertMovementInfos && errors.alertMovementInfos[index]
										? errors.alertMovementInfos[index]?.message
										: ''
								}
								name={`movementAlertDays`}
								id='movementAlertDays'
								label='Alerte mouvement jours'
								autoComplete='movementAlertDays'
								placeholder='"1-5" pour des alertes du lundi au vendredi.'
								variant='standard'
								autoFocus={false}
								value={clientInfos.alertMovementInfos[index].movementAlertDays}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>

							{index !== 0 && (
								<TextField
									required
									fullWidth
									error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
									helperText={
										errors.alertMovementInfos && errors.alertMovementInfos[index]
											? errors.alertMovementInfos[index]?.message
											: ''
									}
									name={`name`}
									id='name'
									label='Nom (nom de l’entreprise)'
									autoComplete='name'
									variant='standard'
									autoFocus={false}
									value={clientInfos.alertMovementInfos[index].name}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										changeNewClientInfos(event, index)
									}
								/>
							)}
						</Grid>

						<Button
							variant='contained'
							color='warning'
							onClick={() => {
								removeAlertMovement(index);
							}}
						>
							Supprimer
						</Button>
					</Grid>
				);
			})}

			<Button
				variant='contained'
				color='primary'
				onClick={() => {
					addAlertMovement();
				}}
			>
				Ajouter une alerte de mouvement
			</Button>
		</Grid>
	);
}
