/* REACT */
import type { ReactElement } from 'react';

/* REACT TOSTIFY */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* COMPONENTS */
import DevicesLinkForm from './devices-link-form/DevicesLinkForm';

/* CSS */
import { Theme } from '../../styles/Theme';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const DevicesLinkSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	height: auto;
	width: 100%;
	position: absolute;
	right: 0;
	background-color: ${Theme.dark.background.primary};
`;

/* COMPONENT qui est une section qui contient le formulaire de liaison d'un device à une ou plusieurs third parties */
export default function DevicesLink(): ReactElement {
	/* Render */
	return (
		<DevicesLinkSection>
			<DevicesLinkForm />
			<ToastContainer />
		</DevicesLinkSection>
	);
}
