/* REACT */
import { type ChangeEvent, type ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* MUI */
import {
	Grid,
	Divider,
	Typography,
	TextField,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent
} from '@mui/material';

/* TYPES */
import { BeaconType } from 'types/Form';

/* COMPONENT */
export default function BeaconForm(): ReactElement {
	/* Hooks */
	const { control, errors, clientInfos, setClientInfos } = useNewClientContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'beaconInfos'
	});

	function changeNewClientInfos(
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<BeaconType>,
		index: number
	) {
		const { value, name } = event.target;

		setClientInfos((prevClientInfos) => {
			const beaconInfos = prevClientInfos.beaconInfos.map((beacon, idx) => {
				if (idx === index) {
					return {
						...beacon,
						[name]: value
					};
				}
				return beacon;
			});

			return {
				...prevClientInfos,
				beaconInfos: beaconInfos
			};
		});
	}

	function addBeacon() {
		append({
			id: '',
			name: '',
			mac: '',
			beaconType: 'driver',
			attributedTo: '',
			thirdPartyId: ''
		});

		setClientInfos((prevClientInfos) => {
			const updatedBeaconInfos = prevClientInfos.beaconInfos.concat({
				id: '',
				name: '',
				mac: '',
				beaconType: 'driver',
				attributedTo: '',
				thirdPartyId: ''
			});

			return {
				...prevClientInfos,
				beaconInfos: updatedBeaconInfos
			};
		});
	}

	function removeBeacon(index: number) {
		setClientInfos((prevClientInfos) => {
			const updatedBeaconInfos = prevClientInfos.beaconInfos.filter((beacon, idx) => idx !== index);

			return {
				...prevClientInfos,
				beaconInfos: updatedBeaconInfos
			};
		});

		remove(index);
	}

	function placeHolderIdBeacon(typeBeacon: string): string {
		switch (typeBeacon) {
			case 'asset':
				return '0102030405060708090a0b0c0d0e0f1000271816';
			case 'driver':
				return '0102030405060708090a0b0c0d0e0f10000002';
			case 'rfid':
				return '140055005D02CB01';
			case 'dumpster':
				return '0102030405060708090a0b0c0d0e0f100000089B';
			case 'sos-button-0':
				return '0102030405060708090a0b0c0d0e0f1000042220';
			case 'sos-button-1':
				return '0102030405060708090a0b0c0d0e0f1000042224';
			default:
				return '';
		}
	}

	function heigthIdBeacon(typeBeacon: string): number {
		switch (typeBeacon) {
			case 'asset':
				return 40;
			case 'driver':
				return 38;
			case 'rfid':
				return 16;
			case 'dumpster':
				return 40;
			case 'sos-button-0':
				return 40;
			case 'sos-button-1':
				return 40;
			default:
				return 0;
		}
	}

	return (
		<Grid
			container
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
		>
			<Divider sx={{ width: '700px', marginBottom: 3 }}>
				<Typography variant='h6'> Beacon</Typography>
			</Divider>
			{fields.map((item, index) => {
				return (
					<Grid
						container
						justifyContent='space-around'
						key={item.id}
						sx={{ marginBottom: 4, marginTop: 2 }}
					>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<FormControl
								variant='standard'
								fullWidth
							>
								<InputLabel id='beaconType'>{'Choisir un type de beacon'}</InputLabel>
								<Select
									labelId='beaconType'
									label="Type d'utilisateur"
									name='beaconType'
									value={clientInfos.beaconInfos[index].beaconType}
									onChange={(event) => {
										changeNewClientInfos(event as SelectChangeEvent<BeaconType>, index);
									}}
								>
									<MenuItem value={'driver'}>Driver</MenuItem>
									<MenuItem value={'rfid'}>Rfid</MenuItem>
									<MenuItem value={'dumpster'}>Dumpster</MenuItem>
									<MenuItem value={'asset'}>Asset</MenuItem>
									<MenuItem value={'sos-button-0'}>SOS-button-0</MenuItem>
									<MenuItem value={'sos-button-1'}>SOS-button-1</MenuItem>
								</Select>
							</FormControl>

							<TextField
								required
								fullWidth
								error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
								helperText={
									errors.alertMovementInfos && errors.alertMovementInfos[index]
										? errors.alertMovementInfos[index]?.message
										: ''
								}
								name='id'
								id='id'
								label='id'
								autoComplete='id'
								placeholder={
									'Ex: ' + placeHolderIdBeacon(clientInfos.beaconInfos[index].beaconType)
								}
								variant='standard'
								autoFocus={false}
								value={clientInfos.beaconInfos[index].id}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
								inputProps={{
									maxLength: heigthIdBeacon(clientInfos.beaconInfos[index].beaconType)
								}}
							/>
							<TextField
								required
								fullWidth
								error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
								helperText={
									errors.alertMovementInfos && errors.alertMovementInfos[index]
										? errors.alertMovementInfos[index]?.message
										: ''
								}
								name='name'
								id='name'
								label='name'
								autoComplete='name'
								placeholder='commencer par le type de modèle (KB, S ID, L ID, C ID) (DOC--)'
								variant='standard'
								autoFocus={false}
								value={clientInfos.beaconInfos[index].name}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>
						</Grid>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<TextField
								required
								fullWidth
								error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
								helperText={
									errors.alertMovementInfos && errors.alertMovementInfos[index]
										? errors.alertMovementInfos[index]?.message
										: ''
								}
								name='mac'
								id='mac'
								label='mac'
								autoComplete='mac'
								placeholder='la MAC Address du beacon, voir DOC Notion.'
								variant='standard'
								autoFocus={false}
								value={clientInfos.beaconInfos[index].mac}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
								inputProps={{
									maxLength: 12
								}}
							/>

							<TextField
								required
								fullWidth
								error={Boolean(errors.alertMovementInfos && errors.alertMovementInfos[index])}
								helperText={
									errors.alertMovementInfos && errors.alertMovementInfos[index]
										? errors.alertMovementInfos[index]?.message
										: ''
								}
								name='attributedTo'
								id='attributedTo'
								label='attributedTo'
								autoComplete='attributedTo'
								placeholder='Le prénom et nom du collaborateur. Demandez le client de fournir la liste des salaraiés.'
								variant='standard'
								autoFocus={false}
								value={clientInfos.beaconInfos[index].attributedTo}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>
						</Grid>

						<Button
							variant='contained'
							color='warning'
							onClick={() => {
								removeBeacon(index);
							}}
						>
							Delete
						</Button>
					</Grid>
				);
			})}
			<Button
				variant='contained'
				color='primary'
				onClick={() => {
					addBeacon();
				}}
			>
				Add Beacon
			</Button>
		</Grid>
	);
}
