export const Theme = {
	primary: '#F9D121',
	secondary: '#7A7C88',
	primary60: '#F9D12199',
	primary38: '#F9D12161',
	common: {
		black: '#000000',
		white: '#FFFFFF',
		yellow: '#F9D121',
		red: '#E9696D',
		orange: '#FF9947',
		orangeRGB: [255, 153, 71],
		blue: '#83D3F4',
		green: '#72CD94',
		turquoise: '#68d9c6'
	},
	light: {
		text: {
			primary: '#000000DE', //87% emphasis
			secondary: '#00000099', //60% emphasis
			tertiary: '#00000061' //38% emphasis
		},
		background: {
			primary: '#FFFFFF',
			secondary: '#F5F5F5'
		}
	},
	dark: {
		text: {
			primary: '#FFFFFFDE', //87% emphasis
			secondary: '#FFFFFF99', //60% emphasis
			tertiary: '#FFFFFF61' //38% emphasis
		},
		background: {
			primary: '#1D212A', // Bleu foncé : couleur de fond de l'app Awaken Fleet
			secondary: '#222B32'
		}
	}
};
