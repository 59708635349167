/*  REACT */
import { useState, createContext, useContext, type ReactNode } from 'react';

/* REACT-HOOK-FORM */
import {
	useForm,
	UseFormRegister,
	UseFormSetError,
	UseFormHandleSubmit,
	FieldErrorsImpl,
	UseFormClearErrors,
	Control
} from 'react-hook-form';

/* TYPES */
import type { ClientInfos } from 'types/User';

type ClientContextProvider = {
	children: ReactNode;
};
export type StepsType = {
	id: string;
	label: string;
};

// type ClientInfosKey =
// 	| 'lastname'
// 	| 'firstname'
// 	| 'name'
// 	| 'location'
// 	| 'phone'
// 	| 'email'
// 	| 'password'
// 	| 'userType'
// 	| 'replugMail'
// 	| 'unplugMail'
// 	| 'positionOption'
// 	| 'maintenanceOption'
// 	| 'fahrtenbuchOption'
// 	| 'ecoDrivingOption'
// 	| 'tripsOption'
// 	| 'toursOption'
// 	| 'beaconOption'
// 	| 'sensorOption'
// 	| 'antiBootOption'
// 	| 'privateMode'
// 	| 'rapportAutomatique'
// 	| 'rapportAutoInfos'
// 	| 'fuelCapOption'
// 	| 'fuelCapInfos'
// 	| 'alertMovement'
// 	| 'alertMovementInfos'
// 	| 'emailAlert'
// 	| 'emailCC'
// 	| 'beaconInfos'
// 	| 'privateModeInfos'
// 	| 'hourMeterOption'
// 	| 'sensorInfos';

// type ClientInfosTyped = Record<ClientInfosKey, string | boolean>;

/* VARIABLES */
const STEPS = [
	{ id: 'ClientInformations', label: 'Informations du client' },
	{ id: 'ClientOptions', label: 'Configuration des options du compte' },
	{ id: 'Review', label: 'Confirmation des données' }
];

/* CONTEXT */
export const NewClientContext = createContext<{
	clientInfos: ClientInfos;
	setClientInfos: React.Dispatch<React.SetStateAction<ClientInfos>>;
	register: UseFormRegister<ClientInfos> | (() => void);
	setError: UseFormSetError<ClientInfos> | (() => void);
	handleSubmit: UseFormHandleSubmit<ClientInfos> | (() => void);
	errors: Partial<FieldErrorsImpl<ClientInfos>>;
	isValid: boolean;
	clearErrors: UseFormClearErrors<ClientInfos>;
	control: Control<ClientInfos>;
	steps: typeof STEPS;
}>({
	clientInfos: {
		lastname: '',
		firstname: '',
		name: '',
		location: '',
		phone: '',
		email: '',
		password: '',
		userType: 'member,fleet',
		replugMail: false,
		unplugMail: false,
		positionOption: false,
		maintenanceOption: false,
		fahrtenbuchOption: false,
		ecoDrivingOption: false,
		tripsOption: false,
		toursOption: false,
		beaconOption: false,
		sensorOption: false,
		antiBootOption: false,
		privateMode: false,
		rapportAutomatique: false,
		rapportAutoInfos: {
			exportType: 'xls',
			exportFrequency: '',
			exportRange: 'today',
			exportCustomIds: ''
		},
		emailAlert: '',
		emailCC: '',
		fuelCapOption: false,
		fuelCapInfos: [],
		alertMovement: false,
		alertMovementInfos: [],
		beaconInfos: [],
		privateModeInfos: {
			distanceOption: false,
			privateStart: '',
			privateEnd: ''
		},
		hourMeterOption: false,
		sensorInfos: [],
		speedOption: false
	},
	setClientInfos: () => {
		return;
	},
	register: () => {
		return;
	},
	setError: () => {
		return;
	},
	handleSubmit: () => {
		return;
	},
	errors: {},
	isValid: false,
	clearErrors: () => {
		return;
	},
	control: {} as Control<ClientInfos>,
	steps: STEPS
});

/* PROVIDER */
export function NewClientContextProvider({ children }: ClientContextProvider) {
	/* States */
	const [clientInfos, setClientInfos] = useState<ClientInfos>({
		lastname: '',
		firstname: '',
		name: '',
		location: '',
		phone: '',
		email: '',
		password: '',
		userType: 'member,fleet',
		replugMail: false,
		unplugMail: false,
		positionOption: false,
		maintenanceOption: false,
		fahrtenbuchOption: false,
		ecoDrivingOption: false,
		tripsOption: false,
		toursOption: false,
		beaconOption: false,
		sensorOption: false,
		antiBootOption: false,
		privateMode: false,
		rapportAutomatique: false,
		rapportAutoInfos: {
			exportType: 'xls',
			exportFrequency: '',
			exportRange: 'today',
			exportCustomIds: ''
		},
		fuelCapOption: false,
		fuelCapInfos: [],
		alertMovement: false,
		alertMovementInfos: [],
		emailAlert: '',
		emailCC: '',
		beaconInfos: [],
		privateModeInfos: {
			distanceOption: false,
			privateStart: '',
			privateEnd: ''
		},
		hourMeterOption: false,
		sensorInfos: [],
		speedOption: false
	});

	/* Hooks */
	const {
		register,
		setError,
		clearErrors,
		formState: { errors, isValid },
		handleSubmit,
		control
	} = useForm<ClientInfos>({
		defaultValues: clientInfos
	});

	// useEffect(() => {
	// 	for (const key in clientInfos) {
	// 		const typedKey = key as ClientInfosKey;
	// 		const value = clientInfos[typedKey] as ClientInfosTyped[typeof typedKey];
	// 		setValue(typedKey, value);
	// 	}
	// }, [clientInfos, setValue]);

	/* Render */
	return (
		<NewClientContext.Provider
			value={{
				clientInfos,
				setClientInfos,
				register,
				setError,
				handleSubmit,
				errors,
				isValid,
				clearErrors,
				control,
				steps: STEPS
			}}
		>
			{children}
		</NewClientContext.Provider>
	);
}

export function useNewClientContext() {
	return useContext(NewClientContext);
}
