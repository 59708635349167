/* REACT */
import { ReactElement } from 'react';

/* CONTEXTS */
import { useToast } from '../../contexts/ToastContext';

/* REDUX */
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
	useSendSmsCommandMutation,
	useLazyResetConnectionQuery,
	useGetSimBrandQuery
} from '../../api/fleetApiSlice';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  width: 170px;
  border-radius: 12px;
  background-color: inherit;
  border: solid #f2c94c 1px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: #f2c94c;
  &:hover {
    transition: 0.2s;
    transform: scale(1.1)
`;

/* TYPE */
type ResetButtonProps = {
	label: string;
	svg: ReactElement;
	smsCommand: string;
};

/* Composant qui envoie une commande SMS à un device */
export default function ResetButton({ label, svg, smsCommand }: ResetButtonProps): ReactElement {
	/* Hooks */
	const deviceId = useSelector((state: RootState) => state.selectedDeviceId.deviceId) ?? '';
	const { showToast } = useToast();
	const { data: simBrand } = useGetSimBrandQuery({ deviceId });
	const [sendSms] = useSendSmsCommandMutation();
	const [resetConnection] = useLazyResetConnectionQuery();

	/* Functions */
	async function sendSmsToClick() {
		if (deviceId) {
			if (label === 'Reset Connection') {
				if (simBrand === 'Truephone') {
					showToast('Reset Connection is not available for Truephone sim card', 'error');
				} else {
					try {
						/*
								Unwrap permet de récupérer le retour de la fonction qui permet de trigger le hook useQuery().
							*/
						const resetConnectResult = await resetConnection({ deviceId }).unwrap();

						showToast(resetConnectResult.message, 'success');
					} catch {
						showToast('Failed to reset Connection', 'error');
					}
				}
			} else if (label === 'Calibrate') {
				try {
					await sendSms({ deviceId, smsCommand: 'auto_calibrate:clear' }).unwrap();
					await sendSms({ deviceId, smsCommand: 'auto_calibrate:set' }).unwrap();
					await sendSms({ deviceId, smsCommand: 'auto_calibrate:get' }).unwrap();

					showToast('calibrate has been successfuly completed !', 'success');
				} catch {
					showToast('Failed to reset Connection', 'error');
				}
			} else {
				try {
					/*
						La méthode unwrap permet de récupérer le résultat de la fonction sendSms qui sert de fonction trigger.
						On ne peut pas utiliser un useQuery hook dans une fonction.
						Dans le cas des mutation et dans cette fonction, on est obligé d'utiliser cette fonction
						pour récupérer la réponse du point de terminaison useSendSmsCommandMutation().
						*/
					const sendSmsresult = await sendSms({ deviceId, smsCommand }).unwrap();

					showToast(sendSmsresult.message, 'success');
				} catch {
					showToast('Failed to send message', 'error');
				}
			}
		} else {
			showToast('Please choose a deviceId', 'info');
		}
	}

	/* Render */
	return (
		<StyledButton
			onClick={sendSmsToClick}
			disabled={deviceId ? false : true}
		>
			{svg}
			<p>{label}</p>
		</StyledButton>
	);
}
