import React from 'react';

/* HOOKS */
import { useNewClientContext } from 'contexts/NewClientContext';

/* STYLES COMPONENTS */
import { StyledTableCell } from './StylesTableReview';

/* MATERIAL UI */
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography
} from '@mui/material';

/* STYLES */
function ReviewRapportForm(): React.ReactElement {
	/* Hooks */
	const rapportAutoInfos = useNewClientContext().clientInfos.rapportAutoInfos;

	return (
		<TableContainer
			component={Paper}
			sx={{
				marginTop: 4
			}}
		>
			<Typography
				variant='h6'
				id='tableTitle'
			>
				Récapitulatif des rapports automatiques
			</Typography>
			<Table
				sx={{ minWidth: 650 }}
				aria-label='simple table'
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align='right'>Type d&apos;export</StyledTableCell>
						<StyledTableCell align='right'>Fréquence d&apos;export</StyledTableCell>
						<StyledTableCell align='right'>Plage d&apos;exportation </StyledTableCell>
						<StyledTableCell align='right'>Exporter des identifiants personnalisés</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow
						key={rapportAutoInfos.exportType}
						sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
					>
						<TableCell align='right'>{rapportAutoInfos.exportType}</TableCell>
						<TableCell align='right'>{rapportAutoInfos.exportFrequency}</TableCell>
						<TableCell align='right'>{rapportAutoInfos.exportRange}</TableCell>
						<TableCell align='right'>{rapportAutoInfos.exportCustomIds}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ReviewRapportForm;
