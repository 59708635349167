/* REACT */
import { type ReactElement } from 'react';

/* MUI */
import { Typography } from '@mui/material';

/* COMPONENT */
export default function FormValidate(): ReactElement {
	/* render */
	return (
		<Typography
			variant='h4'
			gutterBottom
			textAlign='center'
		>
			Le compte du client a été crée avec succès !
		</Typography>
	);
}
