type CalendarSvgProps = {
	width: number;
	height: number;
};

function CalendarSvg({ width, height }: CalendarSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 23 25'
			fill='none'
		>
			<path
				d='M20 2.5H18.75V0H16.25V2.5H6.25V0H3.75V2.5H2.5C1.1125 2.5 0.0125 3.625 0.0125 5L0 22.5C0 23.875 1.1125 25 2.5 25H20C21.375 25 22.5 23.875 22.5 22.5V5C22.5 3.625 21.375 2.5 20 2.5ZM20 22.5H2.5V10H20V22.5ZM20 7.5H2.5V5H20V7.5ZM7.5 15H5V12.5H7.5V15ZM12.5 15H10V12.5H12.5V15ZM17.5 15H15V12.5H17.5V15ZM7.5 20H5V17.5H7.5V20ZM12.5 20H10V17.5H12.5V20ZM17.5 20H15V17.5H17.5V20Z'
				fill='#F2C94C'
			/>
		</svg>
	);
}

export default CalendarSvg;
