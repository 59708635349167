/* AXIOS */
import axios from 'axios';

/* TYPES */
import { Authentication } from '../types/User';

async function LoginAPI(email: string, password: string): Promise<Authentication> {
	const config = {
		url: process.env.REACT_APP_API_URL + '/api/v3/fleet/authenticate',
		method: 'post',
		headers: {
			'content-type': 'application/json'
		},
		data: { email: email, password: password }
	};

	const result = await axios.request(config);

	return result.data;
}

export default LoginAPI;
