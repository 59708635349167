type CompletRoadSvgProps = {
	width: number;
	height: number;
};

function CompletRoadSvg({ width, height }: CompletRoadSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 38 27'
			fill='none'
		>
			<path
				d='M22.1527 15.3781V15.295L21.6516 8.645C21.6377 8.4649 21.5612 8.30904 21.422 8.17742C21.2828 8.04581 21.1227 7.98 20.9418 7.98H17.0582C16.8773 7.98 16.7172 8.04581 16.578 8.17742C16.4388 8.30904 16.3623 8.4649 16.3484 8.645L15.8473 15.295V15.3781C15.8333 15.5444 15.889 15.6829 16.0143 15.7938C16.1396 15.9046 16.2857 15.96 16.4527 15.96H21.5473C21.7143 15.96 21.8604 15.9046 21.9857 15.7938C22.111 15.6829 22.1667 15.5444 22.1527 15.3781ZM38 25.083C38 26.0943 37.6799 26.6 37.0396 26.6H22.3407C22.5216 26.6 22.6747 26.5342 22.8 26.4026C22.9253 26.271 22.981 26.1151 22.967 25.935L22.5494 20.615C22.5355 20.4349 22.459 20.279 22.3198 20.1474C22.1806 20.0158 22.0205 19.95 21.8396 19.95H16.1604C15.9795 19.95 15.8194 20.0158 15.6802 20.1474C15.541 20.279 15.4645 20.4349 15.4505 20.615L15.033 25.935C15.019 26.1151 15.0747 26.271 15.2 26.4026C15.3253 26.5342 15.4784 26.6 15.6593 26.6H0.96044C0.320147 26.6 0 26.0943 0 25.083C0 24.3348 0.180952 23.5313 0.542857 22.6723L9.24945 0.976719C9.36081 0.71349 9.54176 0.484896 9.79231 0.290938C10.0429 0.0969792 10.3073 0 10.5857 0H17.6637C17.4828 0 17.3227 0.0658073 17.1835 0.197422C17.0443 0.329036 16.9678 0.484896 16.9538 0.665L16.6407 4.655C16.6267 4.84896 16.6824 5.00828 16.8077 5.13297C16.933 5.25766 17.0861 5.32 17.267 5.32H20.733C20.9139 5.32 21.067 5.25766 21.1923 5.13297C21.3176 5.00828 21.3733 4.84896 21.3593 4.655L21.0462 0.665C21.0322 0.484896 20.9557 0.329036 20.8165 0.197422C20.6773 0.0658073 20.5172 0 20.3363 0H27.4143C27.6927 0 27.9571 0.0969792 28.2077 0.290938C28.4582 0.484896 28.6392 0.71349 28.7506 0.976719L37.4571 22.6723C37.819 23.5313 38 24.3348 38 25.083Z'
				fill='#F2C94C'
			/>
		</svg>
	);
}

export default CompletRoadSvg;
