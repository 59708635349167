/* REACT */
import { ReactElement } from 'react';

/* TYPES */
import { APIFotaDeviceAndSimInformations } from '../../../types/Devices';

type FirmwareInformationsProps = {
	deviseSimInfos: APIFotaDeviceAndSimInformations;
};

/* STYLED COMPONENTS */
import styled from 'styled-components';

const FirmwareVersion = styled.p``;

/* COMPONENTS */
function FirmwareInformations({ deviseSimInfos }: FirmwareInformationsProps): ReactElement {
	return (
		<FirmwareVersion>
			{(deviseSimInfos && deviseSimInfos?.current_firmware) ?? 'Firmware inconnu'}
		</FirmwareVersion>
	);
}

export default FirmwareInformations;
