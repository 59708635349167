/* REACT */
import { ReactElement } from 'react';

/* REACT ROUTER DOM */
import { BrowserRouter, Route, Routes } from 'react-router-dom';

/* REDUX */
import { useSelector } from 'react-redux';
import { RootState } from './store/store';

/* COMPONENTS */
import Navbar from './components/navbar/Navbar.index';

/* PAGES */
import Login from './pages/login/Login.index';
import Dashboard from './pages/dashboard/Dashboard.index';
import NewAccount from './pages/new-account/NewAccount.index';
import Anomalies from './pages/anomalies/Anomalies.index';
import Help from './pages/help/Help.index';
import DevicesLink from 'pages/devices-link/DevicesLink.index';
import DeleteData from 'pages/delete-data/DeleteData.index';
import SendSms from 'pages/send-sms/SendSms.index';

/* MUI */
import { Box } from '@mui/material';

/* CSS */
import './styles/App.css';

function App(): ReactElement {
	const isAuthenticated = useSelector((state: RootState) => state.authenticate.token);

	return (
		<Box className='App'>
			{isAuthenticated ? (
				<BrowserRouter>
					<Navbar />
					<Routes>
						<Route
							path='/'
							element={<Dashboard />}
						/>
						<Route
							path='/new-account'
							element={<NewAccount />}
						/>
						<Route
							path='/devices-link'
							element={<DevicesLink />}
						/>
						<Route
							path='/delete-data'
							element={<DeleteData />}
						/>
						<Route
							path='/anomalies'
							element={<Anomalies />}
						/>
						<Route
							path='/send-sms'
							element={<SendSms />}
						/>
						<Route
							path='/help'
							element={<Help />}
						/>
						<Route
							path='/*'
							element={<Dashboard />}
						/>
					</Routes>
					<Navbar />
				</BrowserRouter>
			) : (
				<BrowserRouter>
					<Routes>
						<Route
							path='/*'
							element={<Login />}
						/>
					</Routes>
				</BrowserRouter>
			)}
		</Box>
	);
}

export default App;
