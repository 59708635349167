type EnabledSimSvgProps = {
	width: number | string;
	height: number | string;
};

function EnabledSimSvg({ width, height }: EnabledSimSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 19 18'
			fill='none'
		>
			<path
				d='M14.2998 1.5H8.2998L3.7998 6V15C3.7998 15.825 4.4748 16.5 5.2998 16.5H14.2998C15.1248 16.5 15.7998 15.825 15.7998 15V3C15.7998 2.175 15.1248 1.5 14.2998 1.5Z'
				fill='#72CD94'
			/>
		</svg>
	);
}

export default EnabledSimSvg;
