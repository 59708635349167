/* REACT */
import { type ChangeEvent, type ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* MUI */
import {
	Grid,
	Divider,
	Typography,
	TextField,
	Button,
	SelectChangeEvent,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';

/* TYPES */
import { BleCustomType } from 'types/Form';

/* COMPONENT */
export default function FuelCapForm(): ReactElement {
	/* Hooks */
	const { control, errors, setClientInfos, clientInfos } = useNewClientContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'fuelCapInfos'
	});

	function changeNewClientInfos(
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
		index: number
	) {
		const { value, name } = event.target;

		setClientInfos((prevClientInfos) => {
			const fuelCapInfos = prevClientInfos.fuelCapInfos.map((fuelCap, idx) => {
				if (idx === index) {
					return {
						...fuelCap,
						[name]: value
					};
				}
				return fuelCap;
			});

			return {
				...prevClientInfos,
				fuelCapInfos: fuelCapInfos
			};
		});
	}

	function addFuelCap() {
		append({ name: '', macAddress: '', deviceId: '', bleCustom: 'bleCustom1' });

		setClientInfos((prevClientInfos) => {
			const updatedFuelCapInfos = prevClientInfos.fuelCapInfos.concat({
				name: '',
				macAddress: '',
				deviceId: '',
				bleCustom: 'bleCustom1'
			});

			return {
				...prevClientInfos,
				fuelCapInfos: updatedFuelCapInfos
			};
		});
	}

	function removeFuelCap(index: number) {
		setClientInfos((prevClientInfos) => {
			const updatedFuelCapInfos = prevClientInfos.fuelCapInfos.filter(
				(fuelCap, idx) => idx !== index
			);

			return {
				...prevClientInfos,
				fuelCapInfos: updatedFuelCapInfos
			};
		});

		remove(index);
	}

	return (
		<Grid
			container
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
		>
			<Divider sx={{ width: '700px', marginBottom: 3 }}>
				<Typography variant='h6'> Fuel Cap</Typography>
			</Divider>
			{fields.map((item, index) => {
				return (
					<Grid
						container
						justifyContent='space-around'
						key={item.id}
						sx={{ marginBottom: 4, marginTop: 2 }}
					>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<TextField
								required
								fullWidth
								error={Boolean(errors.fuelCapInfos && errors.fuelCapInfos[index])}
								helperText={
									errors.fuelCapInfos && errors.fuelCapInfos[index]
										? errors.fuelCapInfos[index]?.message
										: ''
								}
								name={`name`}
								id='name'
								label='Name'
								autoComplete='name'
								variant='standard'
								autoFocus={false}
								value={clientInfos.fuelCapInfos[index].name}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>

							<TextField
								required
								fullWidth
								error={Boolean(errors.fuelCapInfos && errors.fuelCapInfos[index])}
								helperText={
									errors.fuelCapInfos && errors.fuelCapInfos[index]
										? errors.fuelCapInfos[index]?.message
										: ''
								}
								name='deviceId'
								id='deviceId'
								label='DeviceId'
								autoComplete='deviceId'
								variant='standard'
								autoFocus={false}
								value={clientInfos.fuelCapInfos[index].deviceId}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>
						</Grid>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<TextField
								required
								fullWidth
								error={Boolean(errors.fuelCapInfos && errors.fuelCapInfos[index])}
								helperText={
									errors.fuelCapInfos && errors.fuelCapInfos[index]
										? errors.fuelCapInfos[index]?.message
										: ''
								}
								name='macAddress'
								id='macAddress'
								label='MacAddress (sans les :) '
								autoComplete='macAddress'
								variant='standard'
								autoFocus={false}
								value={clientInfos.fuelCapInfos[index].macAddress}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
								inputProps={{
									maxLength: 17
								}}
							/>

							<FormControl
								variant='standard'
								fullWidth
							>
								<InputLabel id='type'>{'Choisir un type de bleCustom'}</InputLabel>
								<Select
									labelId='bleCustom'
									label='Type du bleCustom'
									name='bleCustom'
									value={clientInfos.fuelCapInfos[index].bleCustom}
									onChange={(event) => {
										changeNewClientInfos(event as SelectChangeEvent<BleCustomType>, index);
									}}
								>
									<MenuItem value='bleCustom1'>BleCustom1</MenuItem>
									<MenuItem value='bleCustom2'>BleCustom2</MenuItem>
									<MenuItem value='bleCustom3'>BleCustom3</MenuItem>
									<MenuItem value='bleCustom4'>BleCustom4</MenuItem>
								</Select>
							</FormControl>
						</Grid>

						<Button
							variant='contained'
							color='warning'
							onClick={() => {
								removeFuelCap(index);
							}}
						>
							Delete
						</Button>
					</Grid>
				);
			})}
			<Button
				variant='contained'
				color='primary'
				onClick={() => {
					addFuelCap();
				}}
			>
				Add another FuelCap
			</Button>
		</Grid>
	);
}
