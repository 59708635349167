/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetSimSessionQuery } from '../../../api/fleetApiSlice';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* SVG */
import CrossSvg from '../../../components/svg/CrossSvg';
import ConfirmSvg from '../../../components/svg/ConfirmSvg';
import CalendarSvg from '../../../components/svg/CalendarSvg';

/* TYPES */
import { SimSession } from '../../../types/Sim';

const SimExpirationBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 0.9rem;
	width: 90%;
	text-align: center;
`;

type SimExpirationProps = {
	deviceId: string;
};

export default function SimExpiration({ deviceId }: SimExpirationProps): ReactElement {
	const { data: SimSession } = useGetSimSessionQuery({ deviceId });

	function expirateDate(date: SimSession | undefined) {
		if (date && date.activation_date) {
			const simActivationDate = new Date(date.activation_date);

			const dateOptions: Intl.DateTimeFormatOptions = {
				weekday: 'long',
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			};

			simActivationDate.setFullYear(simActivationDate.getFullYear() + 10);

			const simExpirationLocaleDate = simActivationDate.toLocaleDateString('fr-FR', dateOptions);

			return (
				<>
					<p>{simExpirationLocaleDate}</p>
					<ConfirmSvg
						width={25}
						height={25}
					/>
				</>
			);
		} else {
			return (
				<>
					<p>{"Date d'expiration indisponible"}</p>
					<CrossSvg
						width={25}
						height={25}
					/>
				</>
			);
		}
	}

	return (
		<SimExpirationBox>
			<CalendarSvg
				width={25}
				height={25}
			/>
			{expirateDate(SimSession)}
		</SimExpirationBox>
	);
}
