type ImmatriculationSvgProps = {
	width: number;
	height: number;
};

function ImmatriculationSvg({ width, height }: ImmatriculationSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 18 14'
			fill='none'
		>
			<path
				d='M15.72 2.04999V11.95H2.27998V2.04999H8.99997H15.72ZM15.72 0.399994H2.27998C1.34758 0.399994 0.599976 1.13424 0.599976 2.04999V11.95C0.599976 12.8657 1.34758 13.6 2.27998 13.6H15.72C16.6524 13.6 17.4 12.8657 17.4 11.95V2.04999C17.4 1.13424 16.6524 0.399994 15.72 0.399994ZM8.69998 5.34999H3.59998V8.64999H8.69998V5.34999Z'
				fill='white'
				fillOpacity='0.8'
			/>
		</svg>
	);
}

export default ImmatriculationSvg;
