/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetSimStatusAndOperatorQuery, useGetSimSessionQuery } from 'api/fleetApiSlice';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const SimBox = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	position: relative;
	top: -30px;
	left: 15px;
	gap: 5px;
`;

const StatusSessionText = styled.p`
	color: ${(props) => props.color};
`;

/* SVG */
import EnabledSimSvg from 'components/svg/EnabledSimSvg';
import DisabledSimSvg from 'components/svg/DisabledSimSvg';
import OnlineSvg from 'components/svg/OnlineSvg';
import OfflineSvg from 'components/svg/OfflineSvg';
import AttachedSvg from 'components/svg/AttachedSvg';

/* TYPES */
import { OnceSimStatus } from 'types/Sim';

interface DeviceSimProps {
	deviceId: string;
}

/* Component qui permet de de récupérer la couleur en fonction du status de la SIM */
function GetSimSession({ simStatus }: { simStatus: OnceSimStatus | undefined }): ReactElement {
	if (simStatus) {
		if (simStatus.status?.toLowerCase() === 'online') {
			return (
				<>
					<OnlineSvg
						width={15}
						height={15}
					/>
					<StatusSessionText color='#6FCF97'>En ligne</StatusSessionText>
				</>
			);
		} else if (simStatus.status?.toLowerCase() === 'attached') {
			return (
				<>
					<AttachedSvg
						width={15}
						height={15}
					/>
					<StatusSessionText color='#FF9947'>Attached</StatusSessionText>
				</>
			);
		} else {
			return (
				<>
					<OfflineSvg
						width={15}
						height={15}
					/>
					<StatusSessionText color='#EB5757'>Hors ligne</StatusSessionText>
				</>
			);
		}
	} else {
		return (
			<>
				<OfflineSvg
					width={15}
					height={15}
				/>
				<StatusSessionText color='#EB5757'>Inconnu</StatusSessionText>
			</>
		);
	}
}

/* Component qui permet de vérifier le statut et la session d'une SIM */
export default function DeviceSim({ deviceId }: DeviceSimProps): ReactElement {
	/* State */

	/* Hooks */
	const { data: simStatus } = useGetSimStatusAndOperatorQuery({ deviceId });
	const { data: simSession } = useGetSimSessionQuery({ deviceId });

	/* Render */
	return (
		<SimBox>
			{simSession && simSession.status && simSession.status === 'Enabled' ? (
				<>
					<EnabledSimSvg
						width={15}
						height={15}
					/>
					<StatusSessionText color='#6FCF97'>Activée</StatusSessionText>
				</>
			) : (
				<>
					<DisabledSimSvg
						width={15}
						height={15}
					/>
					<StatusSessionText color='#EB5757'>Désactivée</StatusSessionText>
				</>
			)}
			<GetSimSession simStatus={simStatus} />
		</SimBox>
	);
}
