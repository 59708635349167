/* REACT */
import { type ChangeEvent, type ReactElement, useState } from 'react';

/* CONTEXT */
import { StepsType, useNewClientContext } from 'contexts/NewClientContext';

/* MUI */
import {
	Grid,
	FormControlLabel,
	Divider,
	Typography,
	Switch,
	alpha,
	styled,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	SelectChangeEvent,
	TextField
} from '@mui/material';

/* TYPES */
import type { UserType } from 'types/User';
interface UpdateStep {
	name: string;
	id: string;
	label: string;
	steps: StepsType[];
	event: ChangeEvent<HTMLInputElement>;
}
/* COMPONENT MUI */
export const YellowSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: '#F9D12199',
		'&:hover': {
			backgroundColor: alpha('#F9D12199', theme.palette.action.hoverOpacity)
		}
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: '#F9D12199'
	}
}));

/**
 * Used to update the steps array in the context when a switch is toggled
 */
function updateStep({ name, id, label, steps, event }: UpdateStep) {
	if (event.target.name === name) {
		if (event.target.checked) {
			steps.splice(steps.length - 1, 0, { id: id, label: label });
		} else {
			const removeIndex = steps.map((item) => item.id).indexOf(id);
			~removeIndex && steps.splice(removeIndex, 1);
		}
	}
}

/* COMPONENT */
export default function ClientOptions(): ReactElement {
	/* States */
	const [tripOrTourOption, setTripOrTourOption] = useState('None');

	/* Hooks */
	const clientInfos = useNewClientContext().clientInfos;
	const setClientInfos = useNewClientContext().setClientInfos;
	const steps = useNewClientContext().steps;
	const { register, errors } = useNewClientContext();

	/* Functions */
	function handleSwitchChange(event: ChangeEvent<HTMLInputElement>): void {
		setClientInfos({
			...clientInfos,
			[event.target.name]: event.target.checked
		});

		if (event.target.name === 'rapportAutomatique') {
			updateStep({
				name: 'rapportAutomatique',
				id: 'Rapport',
				label: 'Rapport Automatique',
				steps,
				event
			});
		} else if (event.target.name === 'fuelCapOption') {
			updateStep({ name: 'fuelCapOption', id: 'FuelCap', label: 'Fuel Cap', steps, event });
		} else if (event.target.name === 'alertMovement') {
			updateStep({
				name: 'alertMovement',
				id: 'AlertMovement',
				label: 'Alert Movement',
				steps,
				event
			});
		} else if (event.target.name === 'beaconOption') {
			updateStep({ name: 'beaconOption', id: 'Beacon', label: 'Beacon', steps, event });
		} else if (event.target.name === 'privateMode') {
			updateStep({ name: 'privateMode', id: 'Private', label: 'Private Mode', steps, event });
		} else if (event.target.name === 'sensorOption') {
			updateStep({ name: 'sensorOption', id: 'Sensor', label: 'Sensor', steps, event });
		}
	}

	function handleSelectchange(event: SelectChangeEvent): void {
		const selectValue = event.target.value as 'Tour' | 'Trip' | 'None';

		setTripOrTourOption(selectValue);

		if (selectValue === 'Trip') {
			setClientInfos({
				...clientInfos,
				tripsOption: true,
				toursOption: false
			});
		} else if (selectValue === 'Tour') {
			setClientInfos({
				...clientInfos,
				tripsOption: false,
				toursOption: true
			});
		} else {
			setClientInfos({
				...clientInfos,
				tripsOption: false,
				toursOption: false
			});
		}
	}

	function changeNewClientInfos(event: ChangeEvent<HTMLInputElement>): void {
		setClientInfos({
			...clientInfos,
			[event.target.name]: event.target.value
		});
	}

	/* Render */
	return (
		<Grid
			container
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
		>
			<Divider sx={{ width: '700px', marginBottom: 3 }}>
				<Typography variant='h6'>Options sans configuration</Typography>
			</Divider>
			<Grid
				container
				justifyContent='space-around'
			>
				<Grid
					container
					flexDirection='column'
					alignContent='center'
					xs={5}
				>
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.replugMail}
									onChange={handleSwitchChange}
									value={clientInfos.replugMail}
									name='replugMail'
								/>
							}
							label='Alerte de branchement'
						/>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.unplugMail}
									onChange={handleSwitchChange}
									value={clientInfos.unplugMail}
									name='unplugMail'
								/>
							}
							label='Alerte de débranchement'
						/>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.positionOption}
									onChange={handleSwitchChange}
									value={clientInfos.positionOption}
									name='positionOption'
								/>
							}
							label='Position temps réel'
						/>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.maintenanceOption}
									onChange={handleSwitchChange}
									value={clientInfos.maintenanceOption}
									name='maintenanceOption'
								/>
							}
							label='Maintenance'
						/>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.fahrtenbuchOption}
									onChange={handleSwitchChange}
									value={clientInfos.fahrtenbuchOption}
									name='fahrtenbuchOption'
								/>
							}
							label='Fahrtenbuch'
						/>
					</Grid>

					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.ecoDrivingOption}
									onChange={handleSwitchChange}
									value={clientInfos.ecoDrivingOption}
									name='ecoDrivingOption'
								/>
							}
							label='Éco-conduite'
						/>
					</Grid>

					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.rapportAutomatique}
									onChange={handleSwitchChange}
									value={clientInfos.rapportAutomatique}
									name='rapportAutomatique'
								/>
							}
							label='Rapport automatique'
						/>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.fuelCapOption}
									onChange={handleSwitchChange}
									value={clientInfos.fuelCapOption}
									name='fuelCapOption'
								/>
							}
							label="Bouchon d'essence connecté"
						/>
					</Grid>

					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.alertMovement}
									onChange={handleSwitchChange}
									value={clientInfos.alertMovement}
									name='alertMovement'
								/>
							}
							label='Alerte mouvement'
						/>
					</Grid>
				</Grid>
				<Grid
					container
					flexDirection='column'
					alignContent='center'
					xs={5}
				>
					{/* temperature Option  */}
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.sensorOption}
									onChange={handleSwitchChange}
									value={clientInfos.sensorOption}
									name='sensorOption'
								/>
							}
							label='Capteur température'
						/>
					</Grid>

					{/* Driver Option  */}
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.beaconOption}
									onChange={handleSwitchChange}
									value={clientInfos.beaconOption}
									name='beaconOption'
								/>
							}
							label='Badge beacon'
						/>
					</Grid>

					{/* AntiBoot  Option  */}
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.antiBootOption}
									onChange={handleSwitchChange}
									value={clientInfos.antiBootOption}
									name='antiBootOption'
								/>
							}
							label='Anti-démarrage'
						/>
					</Grid>

					{/* Private  Mode  */}
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.privateMode}
									onChange={handleSwitchChange}
									value={clientInfos.privateMode}
									name='privateMode'
								/>
							}
							label='Mode privé'
						/>
					</Grid>

					{/* HOURMETER OPTION */}
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.hourMeterOption}
									onChange={handleSwitchChange}
									value={clientInfos.hourMeterOption}
									name='hourMeterOption'
								/>
							}
							label='Horamètre'
						/>
					</Grid>

					{/* SPEED OPTION */}
					<Grid item>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.speedOption}
									onChange={handleSwitchChange}
									value={clientInfos.speedOption}
									name='speedOption'
								/>
							}
							label='Option de vitesse '
						/>
					</Grid>

					<FormControl variant='standard'>
						<InputLabel id='trip-tour-label'>Choisir une option</InputLabel>
						<Select
							labelId='trip-tour-label'
							value={tripOrTourOption}
							onChange={handleSelectchange}
							label='tripOrTourOption'
						>
							<MenuItem value={'None'}>None</MenuItem>
							<MenuItem value={'Trip'}>Trajets</MenuItem>
							<MenuItem value={'Tour'}>Tournées</MenuItem>
						</Select>
					</FormControl>
					<FormControl variant='standard'>
						<InputLabel id='userType'>{"Choisir un type d'utilisateur"}</InputLabel>
						<Select
							labelId='userType'
							label="Type d'utilisateur"
							value={clientInfos.userType}
							onChange={(event) => {
								setClientInfos({
									...clientInfos,
									userType: event.target.value as UserType
								});
							}}
						>
							<MenuItem value={'member'}>MEMBER</MenuItem>
							<MenuItem value={'fleet'}>FLEET</MenuItem>
							<MenuItem value={'api'}>API</MenuItem>
							<MenuItem value={'member,fleet'}>APP (member, fleet)</MenuItem>
							<MenuItem value={'member,fleet,api'}>APP + API (member, fleet, api) </MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			{clientInfos.rapportAutomatique || clientInfos.alertMovement ? (
				<Grid
					justifyContent='space-between'
					container
				>
					<Grid
						item
						xs={5}
					>
						<TextField
							required
							error={Boolean(errors.emailAlert)}
							helperText={errors.emailAlert ? errors.emailAlert.message : ''}
							fullWidth
							id='emailAlert'
							label='emailAlert'
							autoComplete='emailAlert'
							variant='standard'
							value={clientInfos.emailAlert}
							{...register('emailAlert', {
								required: true,
								maxLength: 45,
								pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/
							})}
							onChange={changeNewClientInfos}
						/>
					</Grid>
					<Grid
						item
						xs={5}
					>
						<TextField
							error={Boolean(errors.emailCC)}
							helperText={errors.emailCC ? errors.emailCC.message : ''}
							fullWidth
							id='emailCC'
							label='emailCC (optionnel)'
							autoComplete='emailCC'
							variant='standard'
							value={clientInfos.emailCC}
							{...register('emailCC', {
								maxLength: 45,
								pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/
							})}
							onChange={changeNewClientInfos}
						/>
					</Grid>
				</Grid>
			) : null}
		</Grid>
	);
}
