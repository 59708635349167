/* REACT */
import { type ReactElement, useState, FormEventHandler } from 'react';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';
import { useToast } from 'contexts/ToastContext';

/* REDUX */
import { useCreateClientAccountMutation } from '../../../api/fleetApiSlice';

/* MUI */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

/* COMPONENTS */
import ClientInformations from './ClientInformations';
import ClientOptions from './ClientOptions';
import Review from './Review';
import FormValidate from './FormValidate';
import RapportForm from './RapportForm';
import FuelCapForm from './FuelCapForm';
import AlertMovementForm from './AlertMovementForm';
import BeaconForm from './BeaconForm';
import PrivateForm from './PrivateForm';
import SensorForm from './SensorForm';

type CurrentStepProps = {
	step: number;
	handleNext: () => void;
	handleBack: () => void;
};

/* COMPONENTS */
function GetStepContent({ step }: { step: number }): ReactElement {
	const { steps } = useNewClientContext();

	switch (steps[step].id) {
		case 'ClientInformations':
			return <ClientInformations />;
		case 'ClientOptions':
			return <ClientOptions />;
		case 'Private':
			return <PrivateForm />;
		case 'Beacon':
			return <BeaconForm />;
		case 'Rapport':
			return <RapportForm />;
		case 'FuelCap':
			return <FuelCapForm />;
		case 'Sensor':
			return <SensorForm />;
		case 'AlertMovement':
			return <AlertMovementForm />;
		case 'Review':
			return <Review />;
		default:
			return <ClientInformations />;
	}
}

function CurrentStep({ step, handleNext, handleBack }: CurrentStepProps): ReactElement {
	/* States */
	const [activeButtonLabel, setActiveButtonLabel] = useState<string>('');

	/* Hooks */
	const [createAccount] = useCreateClientAccountMutation();
	const { lastname, firstname, name, location, phone, email, password } =
		useNewClientContext().clientInfos;
	const { handleSubmit, clientInfos } = useNewClientContext();
	const { showToast } = useToast();
	const steps = useNewClientContext().steps;

	/* Variables */
	const isFieldsEmpty =
		!lastname || !firstname || !name || !location || !phone || !email || !password;

	/* Functions */
	async function handleSubmitForm() {
		if (activeButtonLabel === 'Envoi') {
			try {
				await createAccount({ data: clientInfos });

				showToast('Le compte client a été crée avec succès !', 'success');
			} catch (error) {
				showToast('Erreur lors de la création de compte', 'error');
			} finally {
				setActiveButtonLabel('');
			}
		}
	}

	/* Render */
	return step === steps.length ? (
		<FormValidate />
	) : (
		<form onSubmit={handleSubmit(handleSubmitForm) as FormEventHandler<HTMLFormElement>}>
			<GetStepContent step={step} />
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				{step !== 0 && (
					<Button
						onClick={handleBack}
						sx={{ mt: 3, marginLeft: 1 }}
					>
						Précédent
					</Button>
				)}
				{step === steps.length - 1 ? (
					<Button
						type='submit'
						variant='contained'
						sx={{ mt: 3, marginLeft: 1 }}
						onClick={() => setActiveButtonLabel('Envoi')}
					>
						Envoi
					</Button>
				) : (
					<Button
						variant='contained'
						onClick={handleNext}
						sx={{ mt: 3, marginLeft: 1 }}
						disabled={isFieldsEmpty}
					>
						Suivant
					</Button>
				)}
			</Box>
		</form>
	);
}

export default function MultiStepForm(): ReactElement {
	/* States */
	const [activeStep, setActiveStep] = useState(0);

	const steps = useNewClientContext().steps;

	/* Functions */
	/* Next page */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	/* Previous page */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	/* Render */
	return (
		<Container
			component='main'
			maxWidth='lg'
		>
			<Paper
				variant='outlined'
				sx={{
					my: { xs: 3, md: 6 },
					p: { xs: 2, md: 3 }
				}}
			>
				<Typography
					component='h1'
					variant='h4'
					align='center'
				>
					Création de compte
				</Typography>
				<Stepper
					activeStep={activeStep}
					sx={{ paddingTop: 3, paddingBottom: 5 }}
				>
					{steps.map((label) => (
						<Step key={label.id}>
							<StepLabel>{label.label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<CurrentStep
					step={activeStep}
					handleNext={handleNext}
					handleBack={handleBack}
				/>
			</Paper>
		</Container>
	);
}
