import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
	token: sessionStorage.getItem('token') ?? ''
};

export const authSlice = createSlice({
	name: 'authentication',
	initialState: initialAuthState,
	reducers: {
		login: (state, action) => {
			state.token = action.payload;
		},
		logout: (state) => {
			state.token = '';
			sessionStorage.removeItem('token');
		}
	}
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
