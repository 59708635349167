/* REACT */
import { useState, ReactElement } from 'react';

/* REDUX */
import { useDispatch } from 'react-redux';
import { logout } from '../../store/reducers/authSlice';

/* REACT ROUTER DOM */
import { useNavigate } from 'react-router-dom';

/* COMPONENTS */
import NavbarListCategories from './NavbarListCategories';
import NavbarLiensUtiles from './NavbarLiensUtiles';

/* SVG */
import SvgLogo from '../svg/AwakenLogoSvg';

/* MUI */
import { Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

/* CSS */
import styled from 'styled-components';
import { Theme } from '../../styles/Theme';
import './Navbar.css';

const NavigationBar = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: left;
	height: 100vh;
	width: 270px;
	background-color: ${Theme.dark.background.secondary};
	color: white;
	position: fixed;
	left: 0;
`;

const NavigationHeader = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
`;

const LogOutButton = styled.button`
	position: absolute;
	bottom: 30px;
	left: 50px;
	height: 40px;
	width: 170px;
	border-radius: 12px;
	background-color: inherit;
	border: solid #f2c94c 1px;
	cursor: pointer;
	color: #f2c94c;
  	&:hover {
    transition: 0.2s;
    background-color: #f2c94c;
	color: #333;
`;

function Navbar(): ReactElement {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	function changeWidthNavbar(): void {
		setIsOpen(!isOpen);
	}

	function logoutSession(): void {
		dispatch(logout());
		navigate('/');
	}

	return isOpen ? (
		<NavigationBar key='NavBar'>
			<NavigationHeader>
				<SvgLogo
					height={45}
					width={45}
				/>
				<Typography sx={{ marginLeft: 2, color: 'white' }}>AWAKEN Support</Typography>
				<CloseIcon
					onClick={changeWidthNavbar}
					sx={{ color: `#f2c94c` }}
					fontSize='large'
				/>
			</NavigationHeader>
			<NavbarListCategories />
			<hr style={{ width: '70%' }} />
			<NavbarLiensUtiles />
			<LogOutButton onClick={logoutSession}>Deconnexion</LogOutButton>
		</NavigationBar>
	) : (
		<MenuIcon
			onClick={changeWidthNavbar}
			sx={{ color: '#f2c94c', margin: '15px', width: 30, position: 'absolute' }}
			fontSize='large'
		/>
	);
}

export default Navbar;
