/* REACT */
import { ReactElement } from 'react';

/* SVG */
import ObdSvg from '../../../components/svg/ObdSvg';
import FmtSvg from '../../../components/svg/FmtSvg';
import GhSvg from '../../../components/svg/GhSvg';

/* TYPES */
import { APIFotaDeviceAndSimInformations } from '../../../types/Devices';

type DeviceTypeProps = {
	deviseSimInfos: APIFotaDeviceAndSimInformations;
};

/* COMPONENT */
function DeviceType({ deviseSimInfos }: DeviceTypeProps): ReactElement {
	if (deviseSimInfos && deviseSimInfos?.model) {
		if (deviseSimInfos.model.includes('FMT')) {
			return (
				<FmtSvg
					width={45}
					height={45}
				/>
			);
		} else if (deviseSimInfos.model.includes('GH')) {
			return (
				<GhSvg
					width={35}
					height={35}
				/>
			);
		} else {
			return (
				<ObdSvg
					width={30}
					height={30}
				/>
			);
		}
	} else {
		return (
			<ObdSvg
				width={30}
				height={30}
			/>
		);
	}
}

export default DeviceType;
