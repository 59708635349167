/* REACT */
import { useState, useEffect, ReactElement } from 'react';

/* REDUX */
import {
	useGetThreeDaysIncompleteTripsQuery,
	useGetXLastTripsQuery
} from '../../../api/fleetApiSlice';

/* SVG */
import IncompleteRoadSvg from '../../../components/svg/IncompleteRoadSvg';
import FalseSvg from '../../../components/svg/CrossSvg';
import ConfirmSvg from '../../../components/svg/ConfirmSvg';

/* CSS */
import styled from 'styled-components';

/* MUI */
import WarningIcon from '@mui/icons-material/Warning';
import { Trip } from 'types/Trip';

const LastIncompleteTripSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	gap: 10%;
	min-width: 28%;
	height: auto;
	background: #222b32;
	font-size: 0.7rem;
	color: white;
	border-radius: 10px;
	padding: 0 15px;
	text-align: center;
`;

/* TYPES */
type IncompleteTripCount = {
	noStartTs: number;
	noEndTs: number;
};

type IncompleteTripContentProps = {
	threeDaysTripsInfos: Trip | undefined;
	trips: Trip | undefined;
	incompleteTrips: IncompleteTripCount;
};

/* Composant qui affiche le nombre du dernier trajet incomplet */
export function IncompleteTripContent({
	threeDaysTripsInfos,
	trips,
	incompleteTrips
}: IncompleteTripContentProps) {
	if (threeDaysTripsInfos) {
		if (threeDaysTripsInfos.length > 0) {
			return (
				<>
					<div>
						<p style={{ fontWeight: 'bold' }}>Trajets incomplet J-3</p>
						<p>{`${incompleteTrips.noStartTs} trajets incomplets sans start of trip`}</p>
						<p>{`${incompleteTrips.noEndTs} trajets incomplets sans end of trip`}</p>
					</div>
					<FalseSvg
						width={35}
						height={35}
					/>
				</>
			);
		} else if (
			trips &&
			trips[0] &&
			trips[0].endTs &&
			(Date.now() - trips[0].endTs) / (1000 * 3600 * 24) >= 3
		) {
			return (
				<>
					<div>
						<p style={{ fontWeight: 'bold' }}>Trajet incomplet J-3</p>
						<p>Aucun trajets</p>
					</div>
					<WarningIcon sx={{ fontSize: 35 }} />
				</>
			);
		} else {
			return (
				<>
					<div>
						<p style={{ fontWeight: 'bold' }}>Trajet incomplet J-3</p>
						<p>Aucun trajets incomplet</p>
					</div>
					<ConfirmSvg
						width={35}
						height={35}
					/>
				</>
			);
		}
	} else {
		return (
			<>
				<div>
					<p style={{ fontWeight: 'bold' }}>Trajets incomplet J-3</p>
					<p>{'Nombre inconnu de trajets incomplets sans start of trip'}</p>
					<p>{'Nombre inconnu de trajets incomplets sans end of trip'}</p>
				</div>
				<FalseSvg
					width={35}
					height={35}
				/>
			</>
		);
	}
}

/* Composant qui affiche les informations sur le dernier trajet incomplet */
export default function LastIncompleteTrip({ deviceId }: { deviceId: string }): ReactElement {
	/* Hooks */
	const { data: threeDaysTripsInfos } = useGetThreeDaysIncompleteTripsQuery({ deviceId });
	const { data: trips } = useGetXLastTripsQuery({ deviceId, tripCount: 100 });

	/* States */
	const [incompleteTrips, setIncompleteTrips] = useState<IncompleteTripCount>({
		noStartTs: 0,
		noEndTs: 0
	});

	useEffect(() => {
		if (threeDaysTripsInfos) {
			const newIncompleteTrips: IncompleteTripCount = { noStartTs: 0, noEndTs: 0 };
			threeDaysTripsInfos.forEach((trip, index) => {
				if (index === 0 && trip.endTs) {
					newIncompleteTrips.noEndTs += 1;
				} else {
					if (!trip.endTs) {
						newIncompleteTrips.noEndTs += 1;
					}
					if (!trip.startTs) {
						newIncompleteTrips.noStartTs += 1;
					}
				}
			});
			setIncompleteTrips(newIncompleteTrips);
		}
	}, [threeDaysTripsInfos]);

	/* Render */
	return (
		<LastIncompleteTripSection>
			<IncompleteRoadSvg
				height={27}
				width={38}
			/>
			<IncompleteTripContent
				threeDaysTripsInfos={threeDaysTripsInfos}
				trips={trips}
				incompleteTrips={incompleteTrips}
			/>
		</LastIncompleteTripSection>
	);
}
