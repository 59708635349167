type AwakenLogoSvgProps = {
	width: number;
	height: number;
};

function AwakenLogoSvg({ width, height }: AwakenLogoSvgProps): JSX.Element {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 60 60'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				opacity='0.5'
				d='M50.7775 8.40989C50.4947 8.93993 50.106 9.39929 49.682 9.78798C54.9469 14.9117 58.2333 22.0847 58.2333 30C58.2333 45.5831 45.5831 58.2333 30 58.2333C14.417 58.2333 1.76678 45.5831 1.76678 30C1.76678 14.417 14.417 1.76679 30 1.76679C34.8764 1.76679 39.4345 3.00353 43.4276 5.19435C43.3216 5.54771 43.2509 5.90105 43.2509 6.28975C43.2509 8.19787 44.8056 9.75265 46.7138 9.75265C48.6218 9.75265 50.1767 8.19787 50.1767 6.28975C50.1767 4.38162 48.6218 2.82685 46.7138 2.82685C45.8304 2.82685 45.0176 3.18022 44.3816 3.71025C40.106 1.34276 35.1944 0 30 0C13.4629 0 0 13.4629 0 30C0 46.5371 13.4629 60 30 60C46.5371 60 60 46.5371 60 30C60 21.5195 56.4664 13.8516 50.7775 8.40989ZM46.7138 4.55831C47.6678 4.55831 48.4098 5.33569 48.4098 6.25442C48.4098 7.20847 47.6325 7.95053 46.7138 7.95053C45.7596 7.95053 45.0176 7.17315 45.0176 6.25442C45.0176 5.33569 45.7951 4.55831 46.7138 4.55831Z'
				fill='#F9D121'
			/>
			<g opacity='0.8'>
				<path
					opacity='0.8'
					d='M52.1909 37.4911C52.9682 35.1236 53.4276 32.6149 53.4276 30C53.4276 17.0671 42.8976 6.57244 30 6.57244C25.1944 6.57244 20.742 8.0212 17.0318 10.4947C17.4912 10.8834 17.8799 11.3427 18.1272 11.8728C21.5547 9.61131 25.6184 8.30387 30 8.30387C41.9435 8.30387 51.6607 18.0212 51.6607 29.9647C51.6607 32.3322 51.2722 34.6289 50.5655 36.7844C50.424 36.7844 50.2827 36.7491 50.1413 36.7491C48.2333 36.7491 46.6785 38.3038 46.6785 40.212C46.6785 42.1202 48.2333 43.6749 50.1413 43.6749C52.0495 43.6749 53.6042 42.1202 53.6042 40.212C53.6042 39.1165 53.0742 38.1273 52.1909 37.4911ZM50.1413 41.9787C49.1873 41.9787 48.4453 41.2015 48.4453 40.2827C48.4453 39.364 49.2225 38.5865 50.1413 38.5865C51.0955 38.5865 51.8375 39.364 51.8375 40.2827C51.8375 41.2015 51.0955 41.9787 50.1413 41.9787Z'
					fill='#F9D121'
				/>
				<path
					opacity='0.8'
					d='M30 51.6607C18.0566 51.6607 8.33924 41.9435 8.33924 30C8.33924 25.1944 9.92935 20.742 12.5795 17.1378C13.0389 17.3498 13.5336 17.4911 14.0636 17.4911C15.9717 17.4911 17.5265 15.9364 17.5265 14.0283C17.5265 12.1201 15.9717 10.5653 14.0636 10.5653C12.1555 10.5653 10.6007 12.1201 10.6007 14.0283C10.6007 14.7703 10.8481 15.4417 11.2368 16.0071C8.30391 19.9293 6.57245 24.7349 6.57245 30C6.57245 42.9329 17.1025 53.4276 30 53.4276C37.4558 53.4276 44.0989 49.9293 48.3745 44.4876C47.8093 44.2402 47.3145 43.9222 46.8905 43.4982C42.9329 48.4805 36.8198 51.6607 30 51.6607ZM14.0636 12.2968C15.0177 12.2968 15.7597 13.0742 15.7597 13.9929C15.7597 14.947 14.9823 15.689 14.0636 15.689C13.1449 15.689 12.3675 14.9117 12.3675 13.9929C12.3675 13.0742 13.1096 12.2968 14.0636 12.2968Z'
					fill='#F9D121'
				/>
			</g>
			<path
				d='M30.106 24.6644L28.0918 30.3887H32.1555L30.1767 24.6644H30.106Z'
				fill='#F9D121'
			/>
			<path
				d='M30 13.2862C20.7775 13.2862 13.2863 20.7775 13.2863 30C13.2863 39.2225 20.7775 46.7138 30 46.7138C39.2227 46.7138 46.7138 39.2225 46.7138 30C46.7138 20.7775 39.2227 13.2862 30 13.2862ZM34.0284 35.6891L32.7916 32.1555H27.4558L26.2191 35.6891H23.8164L28.94 22.3675H31.4135L36.5371 35.6891H34.0284Z'
				fill='#F9D121'
			/>
		</svg>
	);
}

export default AwakenLogoSvg;
