/* REACT */
import { useState, ReactElement, MouseEvent } from 'react';

/* CONTEXT */
import { useToast } from 'contexts/ToastContext';

/* REDUX */
import { useDeleteDataMutation, useGetAllDevicesQuery } from 'api/fleetApiSlice';

/* TYPES */
import { Devices } from 'types/Devices';

/* CSS */
import { Theme } from '../../styles/Theme';

/* MUI */
import { TextField, Autocomplete, Typography, Modal, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* STYLED COMPONENTS VARIABLES */
const DeleteDataFormSection = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50vh;
	width: 50%;
	gap: 20px;
`;

const ModalBox = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 20px;
	transform: translate(-50%, -50%);
	width: 600;
	background: ${Theme.dark.background.primary};
	border: 1px solid #000;
	color: ${Theme.dark.text.primary};
	p: 4;
	text-align: center;
`;

const ModalTitle = styled.span`
	font-weight: bold;
	color: ${Theme.primary};
`;

/* Formulaire de suppression de données */
export default function DeleteDataForm(): ReactElement {
	/* States */
	const [selectedValue, setSelectedValue] = useState<Devices | null>(null);
	const [imei, setImei] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);

	/* Queries */
	const { data: devices, isSuccess } = useGetAllDevicesQuery();
	const [deleteData, { isLoading }] = useDeleteDataMutation();

	/* Hooks */
	const { showToast } = useToast();

	/* Functions */
	async function handleClickDelete(event: MouseEvent<HTMLButtonElement>): Promise<void> {
		event.preventDefault();

		if (selectedValue && selectedValue.deviceId) {
			const deviceId = selectedValue.deviceId;

			try {
				await deleteData({ deviceId });

				showToast(
					`Les données du device ${selectedValue.deviceId} ont bien été supprimées`,
					'success'
				);
			} catch (error) {
				showToast(
					`Une erreur est survenue lors de la suppression des données du device ${selectedValue.deviceId}`,
					'error'
				);
			}
		}

		setOpen(false);
		setImei('');
	}

	function getErrorMsg(imei: string): string | undefined {
		if (!imei) {
			return "Veuillez saisir l'IMEI du device";
		}

		if (imei.length !== 15) {
			return "L'IMEI doit être composé de 15 chiffres";
		}

		if (selectedValue?.deviceId !== imei) {
			return "L'IMEI ne correspond pas à l'IMEI du device sélectionné";
		}
	}

	function handleOpen(): void {
		setOpen(true);
	}

	function handleClose(): void {
		setOpen(false);
		setImei('');
	}

	/* render */
	return (
		<DeleteDataFormSection>
			<Autocomplete
				id='device-autocomplete'
				value={selectedValue}
				options={isSuccess ? devices : []}
				getOptionLabel={(option: Devices) =>
					`${option.deviceId} / ${option.thirdPartyDeviceName} (${option.thirdPartyName}) / ${option.immatriculation}`
				}
				onChange={(_event, newValue) => setSelectedValue(newValue)}
				sx={{ width: 420, backgroundColor: '#f2c94c' }}
				renderInput={(params) => (
					<TextField
						{...params}
						label='Recherche par IMEI, entreprise ou immatriculation'
					/>
				)}
				renderOption={(props, option, state) => (
					<li
						{...props}
						key={state.index}
					>
						{option.label}
					</li>
				)}
			/>
			{selectedValue && (
				<>
					<Button
						onClick={handleOpen}
						variant='contained'
						color='error'
						disabled={isLoading}
						startIcon={<DeleteIcon />}
					>
						Supprimer les données
					</Button>
					<Modal
						open={open}
						onClose={handleClose}
					>
						<ModalBox>
							<Typography variant='h6'>
								⚠️ Etes-vous sur de vouloir supprimer les trips liés au device{' '}
								<ModalTitle>{selectedValue.deviceId}</ModalTitle> et à remettre toutes les valeurs
								par défaut concernant les informations de ce device ?
							</Typography>
							<Typography sx={{ mt: 3, color: Theme.common.red }}>
								⛔ Veuillez saisir l&apos;IMEI du device dans le champ ci-dessous pour confirmer la
								suppression.
							</Typography>
							<TextField
								label="Saisissez l'IMEI du device"
								onChange={(event) => setImei(event.target.value)}
								value={imei}
								helperText={<span style={{ color: Theme.common.red }}>{getErrorMsg(imei)}</span>}
								variant='standard'
								sx={{
									mt: 3,
									width: 350
								}}
								InputProps={{
									style: { color: Theme.dark.text.primary, fontSize: '1rem' }
								}}
								InputLabelProps={{
									style: { color: Theme.dark.text.secondary, fontSize: '1rem' }
								}}
							/>
							{imei === selectedValue.deviceId && (
								<Button
									onClick={handleClickDelete}
									type='submit'
									variant='contained'
									color='error'
									sx={{ marginTop: 3 }}
									startIcon={<DeleteIcon />}
								>
									Je confirme la suppression du device numéro {selectedValue.deviceId}
								</Button>
							)}
						</ModalBox>
					</Modal>
				</>
			)}
		</DeleteDataFormSection>
	);
}
