/* REACT */
import { type ChangeEvent, type ReactElement } from 'react';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* MUI */
import { Grid, Divider, Typography, TextField, FormControlLabel, Link } from '@mui/material';

/* COMPONENT */
import { YellowSwitch } from './ClientOptions';

/* COMPONENT */
export default function PrivateForm(): ReactElement {
	/* States */

	/* Hooks */
	const clientInfos = useNewClientContext().clientInfos;
	const setClientInfos = useNewClientContext().setClientInfos;

	/* FUNCTIONS */
	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target;
		setClientInfos({
			...clientInfos,
			privateModeInfos: {
				...clientInfos.privateModeInfos,
				[id]: value
			}
		});
	}

	/* Render */
	return (
		<Grid
			container
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
		>
			<Divider sx={{ width: '700px', marginBottom: 3 }}>
				<Typography variant='h6'> Private Mode</Typography>
			</Divider>
			<Grid
				container
				justifyContent='space-around'
			>
				<Grid
					container
					flexDirection='column'
					alignContent='center'
					xs={5}
				>
					<TextField
						fullWidth
						id='privateStart'
						label='Jour et heure d&lsquo;activation du mode privé'
						autoComplete='privateStart'
						variant='standard'
						value={clientInfos.privateModeInfos.privateStart}
						placeholder='* * * * * (format cron job)'
						onChange={handleChange}
					/>

					<Grid mt={4}>
						<FormControlLabel
							control={
								<YellowSwitch
									defaultChecked={clientInfos.privateModeInfos.distanceOption}
									onChange={(event) => {
										setClientInfos({
											...clientInfos,
											privateModeInfos: {
												...clientInfos.privateModeInfos,
												distanceOption: event.target.checked
											}
										});
									}}
									value={clientInfos.fahrtenbuchOption}
									name='distanceOption'
								/>
							}
							label='Distance Option'
						/>
					</Grid>
				</Grid>
				<Grid
					container
					flexDirection='column'
					alignContent='center'
					xs={5}
				>
					<TextField
						fullWidth
						id='privateEnd'
						label='Jour et heure de désactivation du mode privé'
						autoComplete='privateEnd'
						variant='standard'
						value={clientInfos.privateModeInfos.privateEnd}
						placeholder='* * * * * (format cron job)'
						onChange={handleChange}
					/>
				</Grid>
			</Grid>

			<Typography mt={5}>
				<Link
					href='https://crontab.guru'
					underline='none'
					target='_blank'
				>
					LIEN VERS LE FORMAT CRON
				</Link>
			</Typography>
		</Grid>
	);
}
