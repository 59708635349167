/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetDeviceStateQuery, useGetDeviceSimInfosQuery } from '../../../api/fleetApiSlice';

/* COMPONENTS */
import DeviceType from './DeviceType';
import DeviceModel from './DeviceModel';
import DeviceStateStatus from './DeviceStateStatus';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const DeviceStateSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 28%;
  height: 75px;
  background: #222b32;
  font-size: 0.7rem;
  color: white;
  border-radius: 10px;
  padding 0 15px;
  text-align: center;
`;

/* COMPONENT */
function DeviceState({ deviceId }: { deviceId: string }): ReactElement {
	/* RTK QUERIES */
	const { data: deviseSimInfos } = useGetDeviceSimInfosQuery({ deviceId });
	const { data: stateDevice } = useGetDeviceStateQuery({ deviceId });

	/* Render du component */
	return (
		<DeviceStateSection>
			{deviseSimInfos && <DeviceType deviseSimInfos={deviseSimInfos} />}
			{deviseSimInfos && <DeviceModel deviseSimInfos={deviseSimInfos} />}
			{stateDevice && deviseSimInfos && (
				<DeviceStateStatus
					stateDevice={stateDevice}
					deviseSimInfos={deviseSimInfos}
				/>
			)}
		</DeviceStateSection>
	);
}

export default DeviceState;
