/* REDUX */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/* TYPES */
import {
	Device,
	Devices,
	DeviceBeaconOption,
	DeviceStateType,
	DeviceUnplugHistory,
	APIFotaDeviceAndSimInformations,
	linkDeviceTp
} from '../types/Devices';
import { GpsPointPreviousHourCount, GpsPointPreviousDayCount } from '../types/GpsPoint';
import { Trip } from '../types/Trip';
import {
	SimCommand,
	SmsList,
	OnceSimStatus,
	SimSession,
	QuotaDataAndSms,
	SimInformations,
	SimOperatorCountry,
	SmsCommand
} from '../types/Sim';
import { ClientInfos, newAccountData, ClientAccount, ConnectFleetData } from 'types/User';
import { AllThirdParties, AllThirdPartiesDevices } from 'types/ThirdParty';
import { LinkDeviceData } from 'pages/devices-link/devices-link-form/DevicesLinkForm';

/* SLICE REDUX */
export const fleetApiSlice = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/api/v3/fleet`,
		headers: {
			Authorization: 'Bearer ' + sessionStorage.getItem('token')
		}
	}),
	tagTypes: [],
	endpoints: (builder) => ({
		getAllDevices: builder.query<Devices[], void>({
			query: () => '/devices/search'
		}),

		getDeviceState: builder.query<DeviceStateType, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/state`
		}),

		getDeviceSimInfos: builder.query<APIFotaDeviceAndSimInformations, { deviceId: string }>({
			query: ({ deviceId }) => `/device/${deviceId}/sim-informations`
		}),

		getXLastTrips: builder.query<Trip, { deviceId: string; tripCount: number }>({
			query: ({ deviceId, tripCount }) => `/trips/${deviceId}/last/${tripCount}`
		}),

		getPreviousHoursGpsPoints: builder.query<GpsPointPreviousHourCount, { deviceId: string }>({
			query: ({ deviceId }) => `/gpspositions/${deviceId}/count/last-hour`
		}),

		getThreeDaysIncompleteTrips: builder.query<Trip, { deviceId: string }>({
			query: ({ deviceId }) => `/trips/${deviceId}/previous-three-days-incomplete-trip`
		}),

		getPreviousDayGpsPoints: builder.query<GpsPointPreviousDayCount, { deviceId: string }>({
			query: ({ deviceId }) => `/gpspositions/${deviceId}/count/last-day`
		}),

		sendSmsCommand: builder.mutation<SimCommand, { deviceId: string; smsCommand: string }>({
			query: ({ deviceId, smsCommand }) => ({
				url: `/devices/${deviceId}/sim/${smsCommand}`,
				method: 'POST',
				body: {}
			})
		}),

		getSmsSimLists: builder.query<SmsList, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/sim`
		}),

		getBeaconOption: builder.query<DeviceBeaconOption[], { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/beaconOption`
		}),

		getSimStatusAndOperator: builder.query<OnceSimStatus, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/sim/status`
		}),

		getSimSession: builder.query<SimSession, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/sim/session`
		}),

		getSimBrand: builder.query<string, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/sim/brand`
		}),

		resetConnection: builder.query<SimCommand, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/reset-connection`
		}),

		getUnplugHistory: builder.query<DeviceUnplugHistory[], { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/unplug-history`
		}),

		getQuotaData: builder.query<QuotaDataAndSms, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/quota/data`
		}),

		getQuotaSms: builder.query<QuotaDataAndSms, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/quota/sms`
		}),

		getGsmInformations: builder.query<SimInformations[], { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/gsm-informations`
		}),

		getSimOperatorCountry: builder.query<SimOperatorCountry, { deviceId: string }>({
			query: ({ deviceId }) => `/devices/${deviceId}/operator`
		}),

		createClientAccount: builder.mutation<newAccountData, { data: ClientInfos }>({
			query: ({ data }) => ({
				url: `/new-account`,
				method: 'POST',
				body: data
			})
		}),

		deviceExisting: builder.query<Device, { deviceId: string }>({
			query: ({ deviceId }) => `/support/devices/${deviceId}`
		}),

		thirdPartiesDevices: builder.query<AllThirdPartiesDevices, { deviceId: string }>({
			query: ({ deviceId }) => `/support/devices/${deviceId}/third-parties`
		}),

		allTp: builder.query<AllThirdParties, void>({
			query: () => `/support/third-parties`
		}),

		linkDeviceTp: builder.mutation<linkDeviceTp, { data: LinkDeviceData }>({
			query: ({ data }) => ({
				url: `/support/devices/assign/third-parties`,
				method: 'POST',
				body: data
			})
		}),

		deleteData: builder.mutation<void, { deviceId: string }>({
			query: ({ deviceId }) => ({
				url: `/support/device/${deviceId}/delete-data`,
				method: 'DELETE'
			})
		}),

		allTpAccounts: builder.query<ClientAccount, { thirdPartyId: number | null }>({
			query: ({ thirdPartyId }) => `/support/third-party/${thirdPartyId}/users`
		}),

		authenticate: builder.mutation<ConnectFleetData, { email: string }>({
			query: ({ email }) => ({
				url: `/support/connect-fleet`,
				method: 'POST',
				body: {
					email
				}
			})
		}),

		allTpDevices: builder.query<Devices[], { thirdPartyId: number }>({
			query: ({ thirdPartyId }) => `/support/thirdParty/${thirdPartyId}/devices`
		}),

		sendSms: builder.mutation<SmsCommand[], { devices: string[]; finalCommand: string }>({
			query: ({ devices, finalCommand }) => ({
				url: `/support/devices/sms`,
				method: 'POST',
				body: {
					devices,
					smsCommand: finalCommand
				}
			})
		})
	})
});

export const {
	useGetAllDevicesQuery,
	useGetDeviceStateQuery,
	useGetDeviceSimInfosQuery,
	useGetXLastTripsQuery,
	useGetPreviousHoursGpsPointsQuery,
	useGetThreeDaysIncompleteTripsQuery,
	useGetPreviousDayGpsPointsQuery,
	useSendSmsCommandMutation,
	useGetSmsSimListsQuery,
	useGetBeaconOptionQuery,
	useGetSimStatusAndOperatorQuery,
	useGetSimSessionQuery,
	useGetSimBrandQuery,
	useLazyResetConnectionQuery,
	useGetUnplugHistoryQuery,
	useGetQuotaDataQuery,
	useGetQuotaSmsQuery,
	useGetGsmInformationsQuery,
	useGetSimOperatorCountryQuery,
	useCreateClientAccountMutation,
	useDeviceExistingQuery,
	useAllTpQuery,
	useThirdPartiesDevicesQuery,
	useLinkDeviceTpMutation,
	useDeleteDataMutation,
	useAllTpAccountsQuery,
	useAuthenticateMutation,
	useAllTpDevicesQuery,
	useSendSmsMutation
} = fleetApiSlice;
