/* REACT */
import type { ReactElement } from 'react';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* COMPONENTS */
import ReviewListItem from './ReviewListItem';

/* MUI */
import { List, Divider, Typography, Grid } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import BadgeIcon from '@mui/icons-material/Badge';
import RoomIcon from '@mui/icons-material/Room';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import KeyIcon from '@mui/icons-material/Key';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import NearMeIcon from '@mui/icons-material/NearMe';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import CarRentalIcon from '@mui/icons-material/CarRental';
import ShieldIcon from '@mui/icons-material/Shield';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import Person2Icon from '@mui/icons-material/Person2';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EvStationIcon from '@mui/icons-material/EvStation';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import SpeedIcon from '@mui/icons-material/Speed';

/* REVIEW COMPONENT */
import ReviewAlertMovementForm from '../reviewForm/ReviewAlertMovementForm';
import ReviewRapportForm from '../reviewForm/ReviewRapportForm';
import ReviewBeaconForm from '../reviewForm/ReviewBeaconForm';
import ReviewSensorForm from '../reviewForm/ReviewSensorForm';
import ReviewFuelCapForm from '../reviewForm/ReviewFuelCapForm';
import ReviewPrivateOptionForm from '../reviewForm/ReviewPrivateOptionForm';

/* COMPONENT */
export default function Review(): ReactElement {
	/* Hooks */
	const clientInfos = useNewClientContext().clientInfos;

	/* Functions */
	function booleanToString(boolean: true | false) {
		if (boolean) {
			return 'Oui';
		} else {
			return 'Non';
		}
	}

	/* Render */
	return (
		<Grid>
			<Divider sx={{ width: '800px', marginBottom: 3 }}>
				<Typography variant='h6'>Résumé des informations clients</Typography>
			</Divider>
			<List>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<ReviewListItem
							Icon={PermIdentityIcon}
							label='Nom - Prénom'
							data={`${clientInfos.firstname} ${clientInfos.firstname}`}
						/>
						<ReviewListItem
							Icon={BadgeIcon}
							label="Nom de l'entreprise"
							data={clientInfos.name}
						/>
						<ReviewListItem
							Icon={RoomIcon}
							label="Adresse de l'entreprise"
							data={clientInfos.location}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<ReviewListItem
							Icon={PhoneIphoneIcon}
							label='Numéro de téléphone'
							data={clientInfos.phone}
						/>
						<ReviewListItem
							Icon={AlternateEmailIcon}
							label='Adresse email'
							data={clientInfos.email}
						/>
						<ReviewListItem
							Icon={KeyIcon}
							label='Mot de passe'
							data={clientInfos.password}
						/>
					</Grid>
				</Grid>
			</List>
			<Divider sx={{ width: '800px', marginBottom: 3, marginTop: 3 }}>
				<Typography variant='h6'>Résumé des options sans configuration</Typography>
			</Divider>
			<List>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<ReviewListItem
							Icon={PowerIcon}
							label='Alerte de branchement'
							data={booleanToString(clientInfos.replugMail)}
							color={clientInfos.replugMail ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={PowerOffIcon}
							label='Alerte de débranchement'
							data={booleanToString(clientInfos.unplugMail)}
							color={clientInfos.unplugMail ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={NearMeIcon}
							label='Position temps réel'
							data={booleanToString(clientInfos.positionOption)}
							color={clientInfos.positionOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={ElectricCarIcon}
							label='Maintenance'
							data={booleanToString(clientInfos.maintenanceOption)}
							color={clientInfos.maintenanceOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={ReceiptLongIcon}
							label='fahrtenbuchOption'
							data={booleanToString(clientInfos.fahrtenbuchOption)}
							color={clientInfos.fahrtenbuchOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={QueryStatsIcon}
							label='Éco conduite'
							data={booleanToString(clientInfos.ecoDrivingOption)}
							color={clientInfos.ecoDrivingOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={EngineeringIcon}
							label='Horamètre'
							data={booleanToString(clientInfos.hourMeterOption)}
							color={clientInfos.hourMeterOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={AssessmentIcon}
							label='Rapport automatique'
							data={booleanToString(clientInfos.rapportAutomatique)}
							color={clientInfos.rapportAutomatique ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={PowerInputIcon}
							label='Afficher la distance pour les trajets privés'
							data={booleanToString(clientInfos.privateModeInfos.distanceOption)}
							color={clientInfos.privateModeInfos.distanceOption ? 'green' : 'info'}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<ReviewListItem
							Icon={ModeOfTravelIcon}
							label='Trajets'
							data={booleanToString(clientInfos.tripsOption)}
							color={clientInfos.tripsOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={ModeOfTravelIcon}
							label='Tournées'
							data={booleanToString(clientInfos.toursOption)}
							color={clientInfos.toursOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={Person2Icon}
							label='Badge beacon'
							data={booleanToString(clientInfos.beaconOption)}
							color={clientInfos.beaconOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={DeviceThermostatIcon}
							label='Capteur de température'
							data={booleanToString(clientInfos.sensorOption)}
							color={clientInfos.sensorOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={CarRentalIcon}
							label='Antidémarrage'
							data={booleanToString(clientInfos.antiBootOption)}
							color={clientInfos.antiBootOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={ShieldIcon}
							label='Mode privé'
							data={booleanToString(clientInfos.privateMode)}
							color={clientInfos.privateMode ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={EvStationIcon}
							label='Capacité du réservoir'
							data={booleanToString(clientInfos.fuelCapOption)}
							color={clientInfos.fuelCapOption ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={RoomIcon}
							label='Alerte de mouvement'
							data={booleanToString(clientInfos.alertMovement)}
							color={clientInfos.alertMovement ? 'green' : 'info'}
						/>
						<ReviewListItem
							Icon={SpeedIcon}
							label='Option de vitesse'
							data={booleanToString(clientInfos.speedOption)}
							color={clientInfos.speedOption ? 'green' : 'info'}
						/>
					</Grid>
				</Grid>

				<Divider sx={{ width: '800px', marginBottom: 3, marginTop: 3 }}>
					<Typography variant='h6'>Résumé des autres configurations</Typography>
				</Divider>

				{clientInfos.privateMode && <ReviewPrivateOptionForm />}
				{clientInfos.rapportAutomatique && <ReviewRapportForm />}
				{clientInfos.alertMovement && <ReviewAlertMovementForm />}
				{clientInfos.beaconOption && <ReviewBeaconForm />}
				{clientInfos.sensorOption && <ReviewSensorForm />}
				{clientInfos.fuelCapOption && <ReviewFuelCapForm />}
			</List>
		</Grid>
	);
}
