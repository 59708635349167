/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetUnplugHistoryQuery } from 'api/fleetApiSlice';

/* MUI */

/* SVG */
import PlugSvg from 'components/svg/PlugSvg';
import UnplugSvg from 'components/svg/UnplugSvg';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const StateBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	font-size: 1rem;
`;

/* TYPES */
interface PluggedStateProps {
	deviceId: string;
}

/* Component qui permet de vérifier si un device est branché ou non */
export default function DeviceState({ deviceId }: PluggedStateProps): ReactElement {
	/* Hooks */
	const { data: plugUnplugHistory, isSuccess } = useGetUnplugHistoryQuery({ deviceId });

	/* Render */
	return (
		<StateBox>
			{isSuccess &&
			plugUnplugHistory &&
			plugUnplugHistory[0] &&
			'value' in plugUnplugHistory[0] &&
			plugUnplugHistory[0].value === 0 ? (
				<PlugSvg
					width={15}
					height={15}
				/>
			) : (
				<UnplugSvg
					width={15}
					height={15}
				/>
			)}
			{deviceId}
		</StateBox>
	);
}
