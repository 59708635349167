type SendSvgProps = {
	width: number;
	height: number;
};

function SendSvg({ width, height }: SendSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 17 17'
			fill='none'
		>
			<path
				d='M10.038 17.0001C9.9191 17 9.80257 16.9665 9.70177 16.9034C9.60096 16.8403 9.51992 16.7501 9.46791 16.6431L6.48616 10.5134L0.35644 7.53209C0.246018 7.47827 0.153597 7.39355 0.0903991 7.28821C0.0272008 7.18287 -0.00405675 7.06145 0.000421906 6.93869C0.00490056 6.81593 0.0449226 6.69711 0.115629 6.59666C0.186335 6.49621 0.284684 6.41844 0.398735 6.37281L16.1284 0.0459998C16.2438 -0.000522101 16.3704 -0.0120718 16.4923 0.0127886C16.6143 0.037649 16.7262 0.0978214 16.8142 0.185816C16.9022 0.273811 16.9624 0.385741 16.9872 0.507676C17.0121 0.629611 17.0005 0.756165 16.954 0.871585L10.6268 16.6025C10.5809 16.7165 10.5029 16.8147 10.4023 16.8852C10.3017 16.9557 10.1828 16.9955 10.06 16.9997L10.038 17.0001ZM2.19751 7.01864L7.23815 9.47171C7.36591 9.53384 7.46912 9.63705 7.53125 9.76481L9.98432 14.8054L15.2199 1.77668L2.19751 7.01864Z'
				fill='#F2C94C'
			/>
		</svg>
	);
}

export default SendSvg;
