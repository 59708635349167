/* REACT */
import React from 'react';
import ReactDOM from 'react-dom/client';

/* REDUX */
import { Provider } from 'react-redux';
import store from './store/store';

/* CONTEXT */
import { ToastContextProvider } from './contexts/ToastContext';
import { NewClientContextProvider } from 'contexts/NewClientContext';

/* COMPONENTS */
import App from './App';

/* CSS */
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<NewClientContextProvider>
				<ToastContextProvider>
					<App />
				</ToastContextProvider>
			</NewClientContextProvider>
		</Provider>
	</React.StrictMode>
);
