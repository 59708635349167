/* REDUX */
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

/* REACT TOSTIFY */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* COMPONENTS */
import KeyPerformanceIndicator from './KPI/KeyPerformanceIndicator';
import DashboardHeader from './DashboardHeader';
import Logs from './GCP/Logs';
import Advice from './advice/Advice';
import UnplugHistory from './unplug-history/UnplugHistory';
import VolumeDataSms from './quota-volume/VolumeDataSms';
import SmsCommand from './sms-command/SmsCommand';

/* CSS */
import styled from 'styled-components';
import { Theme } from '../../styles/Theme';
import Gsm from './SIM/Sim';

const DashboardSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 120vh;
	width: 100%;
	position: absolute;
	right: 0;
	background-color: ${Theme.dark.background.primary};
`;

const DashboardMain = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
	with: 100%;
`;

const DashboardMainLeftSection = styled.section`
	display: flex;
	flex-direction: column;
	max-width: 70%;
	gap: 25px;
`;

const DashboardMainRightSection = styled.section`
	display: flex;
	flex-direction: column;
	max-width: 25%;
	height: 700px;
	gap: 3%;
`;

const UnplugHistoryAndLogsBox = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px;
	width: 100%;
`;

const Volumes = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

function Dashboard() {
	const deviceId = useSelector((state: RootState) => state.selectedDeviceId.deviceId);

	return (
		<DashboardSection>
			<DashboardHeader />
			{deviceId && (
				<DashboardMain>
					<DashboardMainLeftSection>
						<KeyPerformanceIndicator deviceId={deviceId} />
						<Advice />
						<UnplugHistoryAndLogsBox>
							<UnplugHistory deviceId={deviceId} />
							<Logs deviceId={deviceId} />
						</UnplugHistoryAndLogsBox>
					</DashboardMainLeftSection>
					<DashboardMainRightSection>
						<Volumes>
							<VolumeDataSms
								deviceId={deviceId}
								label='data'
							/>
							<VolumeDataSms
								deviceId={deviceId}
								label='sms'
							/>
						</Volumes>
						<Gsm deviceId={deviceId} />
						<SmsCommand deviceId={deviceId} />
					</DashboardMainRightSection>
				</DashboardMain>
			)}
			<ToastContainer />
		</DashboardSection>
	);
}

export default Dashboard;
