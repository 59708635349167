type ResetConnectionSvgProps = {
	height: number;
	width: number;
	color: string;
};

function ResetConnectionSvg({ height, width, color }: ResetConnectionSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height={height}
			width={width}
			viewBox='0 0 15 17'
			fill='none'
		>
			<path
				d='M1.84482 9.6853C1.84482 8.16332 2.46283 6.77971 3.46826 5.77428L2.15844 4.46446C0.830168 5.80195 0 7.64677 0 9.6853C0 13.4487 2.81335 16.548 6.45686 17V15.1367C3.84645 14.694 1.84482 12.4249 1.84482 9.6853ZM14.7585 9.6853C14.7585 5.60825 11.4563 2.30602 7.37927 2.30602C7.32393 2.30602 7.26858 2.31525 7.21324 2.31525L8.21866 1.30982L6.91807 0L3.68964 3.22843L6.91807 6.45686L8.21866 5.15627L7.22246 4.16007C7.27781 4.16007 7.33315 4.15084 7.37927 4.15084C10.4324 4.15084 12.9137 6.63212 12.9137 9.6853C12.9137 12.4249 10.9121 14.694 8.30168 15.1367V17C11.9452 16.548 14.7585 13.4487 14.7585 9.6853Z'
				fill={color}
			/>
		</svg>
	);
}

export default ResetConnectionSvg;
