/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetDeviceSimInfosQuery } from '../../../api/fleetApiSlice';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* COMPONENTS */
import LinkButton from '../../../components/button/LinkButton';
import SendSmsForm from './SendSmsForm';

/* SVG */
import InfosSvg from '../../../components/svg/InfosSvg';

/* STYLED COMPONENTS VARIABLES */
const SmsCommandSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	background: #222b32;
	border-radius: 10px;
	color: white;
	gap: 15px;
	padding: 5%;
`;

const Link = styled.a`
	color: #f2c94c;
	text-decoration: none;
`;

const Separator = styled.hr`
	width: 70%;
	color: grey;
`;

/* VARIABLES */
import {
	DEVICES_TYPE_CATEGORIES,
	SMS_COMMANDS,
	DEVICES_TYPES_PARAMETER_LIST_URL
} from './SmsVariables';

/* TYPES */
import { FMBAndGHCommands } from '../../../types/Devices';

type SmsCommandProps = {
	deviceId: string;
};

/* Component */
export default function SmsCommand({ deviceId }: SmsCommandProps): ReactElement {
	/* Queries */
	const { data: simInfos } = useGetDeviceSimInfosQuery({ deviceId });

	/* Variables */
	const deviceType = simInfos?.model ? simInfos?.model : '';
	let smsCommandsList: FMBAndGHCommands[] | [] = [];

	//
	function deviceTypeCommandsUrl(deviceModel: string | null): string {
		if (deviceModel) {
			if (DEVICES_TYPE_CATEGORIES['FMB SMS/GPRS Commands'].includes(deviceModel)) {
				smsCommandsList = SMS_COMMANDS.FMBSmsCommands;

				return 'https://wiki.teltonika-gps.com/view/FMB_SMS/GPRS_Commands';
			} else if (DEVICES_TYPE_CATEGORIES['GH SMS/GPRS Commands'].includes(deviceModel)) {
				smsCommandsList = SMS_COMMANDS.GHSmsCommands;

				return 'https://wiki.teltonika-mobility.com/view/GH5200_SMS/GPRS_Commands';
			} else {
				return 'https://wiki.teltonika-mobility.com/view/Main_Page';
			}
		} else {
			return '/';
		}
	}

	// Fonction qui retourne un lien vers la page de la documentation du device (liste des paramètres)
	function deviceTypeParameterListUrl(deviceModel: string | null): string {
		if (deviceModel) {
			for (const parameterListUrl in DEVICES_TYPES_PARAMETER_LIST_URL) {
				if (deviceModel.startsWith(parameterListUrl)) {
					return DEVICES_TYPES_PARAMETER_LIST_URL[parameterListUrl];
				}
			}
			return '/';
		} else {
			return '/';
		}
	}

	// Fonction qui retourne un lien vers la page de la documentation du device (commandes SMS)
	function deviceTypeTag(deviceModel: string | null, label: string): ReactElement {
		if (deviceModel) {
			return (
				<Link
					href={
						label === 'SMS/GPRS COMMANDS'
							? deviceTypeCommandsUrl(deviceType)
							: deviceTypeParameterListUrl(deviceType)
					}
					target='_blank'
					rel='noreferrer'
				>
					{`${deviceType} ${label}`}
				</Link>
			);
		} else {
			return <p>{`${label}`}</p>;
		}
	}

	/* Render */
	return (
		<SmsCommandSection>
			<LinkButton
				width={250}
				disabled={deviceType ? false : true}
			>
				<InfosSvg
					height={15}
					width={15}
				/>
				{deviceTypeTag(deviceType, 'SMS/GPRS COMMANDS')}
			</LinkButton>
			<LinkButton
				width={250}
				disabled={deviceType ? false : true}
			>
				<InfosSvg
					height={15}
					width={15}
				/>
				{deviceTypeTag(deviceType, 'PARAMETER LIST')}
			</LinkButton>
			<Separator />
			<SendSmsForm
				smsCommandsList={smsCommandsList}
				deviceId={deviceId}
			/>
		</SmsCommandSection>
	);
}
