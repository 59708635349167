type GpsPointSvgProps = {
	width: number;
	height: number;
};

function GpsPointSvg({ width, height }: GpsPointSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 30 30'
			fill='none'
		>
			<path
				d='M16.5346 26.9007V29.9398C19.5587 29.6389 22.3119 28.4353 24.5386 26.6149L22.4022 24.4634C20.7322 25.7573 18.7312 26.6299 16.5346 26.9007ZM3.00903 14.9699C3.00903 8.87663 7.5677 3.82147 13.4654 3.03912V0C5.89769 0.797392 0 7.20662 0 14.9699C0 22.7332 5.89769 29.1424 13.4654 29.9398V26.9007C7.5677 26.1184 3.00903 21.0632 3.00903 14.9699ZM26.9609 13.4654H30C29.6991 10.4413 28.4955 7.68807 26.675 5.46139L24.5236 7.61284C25.8175 9.26781 26.6901 11.2688 26.9609 13.4654ZM24.5386 3.32498C22.3119 1.50451 19.5436 0.300903 16.5346 0V3.03912C18.7312 3.30993 20.7322 4.18255 22.4022 5.47643L24.5386 3.32498ZM24.5236 22.342L26.675 24.4784C28.4955 22.2518 29.6991 19.4985 30 16.4744H26.9609C26.6901 18.671 25.8175 20.672 24.5236 22.342Z'
				fill='#F2C94C'
			/>
			<path
				d='M21.0127 13.6163C21.0127 9.87002 18.1541 7.44775 14.9946 7.44775C11.8351 7.44775 8.97656 9.87002 8.97656 13.6163C8.97656 16.1138 10.9776 19.0776 14.9946 22.4929C19.0117 19.0776 21.0127 16.1138 21.0127 13.6163ZM14.9946 14.9703C14.107 14.9703 13.3848 14.2482 13.3848 13.3605C13.3848 12.4728 14.107 11.7507 14.9946 11.7507C15.8823 11.7507 16.6044 12.4728 16.6044 13.3605C16.6044 14.2482 15.8823 14.9703 14.9946 14.9703Z'
				fill='#F2C94C'
			/>
		</svg>
	);
}

export default GpsPointSvg;
