type WebConnectProps = {
	height: number;
	width: number;
	color: string;
};

function WebConnectSvg({ height, width, color }: WebConnectProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 19 17'
			fill='none'
		>
			<path
				d='M9.11528 7.29223C8.1126 7.29223 7.29223 8.1126 7.29223 9.11528C7.29223 10.118 8.1126 10.9383 9.11528 10.9383C10.118 10.9383 10.9383 10.118 10.9383 9.11528C10.9383 8.1126 10.118 7.29223 9.11528 7.29223ZM14.5845 9.11528C14.5845 6.09812 12.1324 3.64611 9.11528 3.64611C6.09812 3.64611 3.64611 6.09812 3.64611 9.11528C3.64611 11.1389 4.74906 12.8981 6.3807 13.8461L7.29223 12.2601C6.20751 11.622 5.46917 10.4643 5.46917 9.11528C5.46917 7.1008 7.1008 5.46917 9.11528 5.46917C11.1298 5.46917 12.7614 7.1008 12.7614 9.11528C12.7614 10.4643 12.0231 11.622 10.9383 12.2601L11.8499 13.8461C13.4815 12.8981 14.5845 11.1389 14.5845 9.11528ZM9.11528 0C4.08365 0 0 4.08365 0 9.11528C0 12.4879 1.83217 15.4231 4.54853 17L5.46005 15.4231C3.29062 14.156 1.82306 11.8134 1.82306 9.11528C1.82306 5.08633 5.08633 1.82306 9.11528 1.82306C13.1442 1.82306 16.4075 5.08633 16.4075 9.11528C16.4075 11.8134 14.9399 14.156 12.7614 15.4231L13.6729 17C16.3984 15.4231 18.2306 12.4879 18.2306 9.11528C18.2306 4.08365 14.1469 0 9.11528 0Z'
				fill={color}
			/>
		</svg>
	);
}

export default WebConnectSvg;
