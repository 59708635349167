/* REACT */
import { useState, ReactElement } from 'react';

/* CONTEXTS */
import { useToast } from '../../contexts/ToastContext';

/* REDUX */
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useAllTpAccountsQuery, useAuthenticateMutation } from 'api/fleetApiSlice';

/* CSS */
import { Theme } from '../../styles/Theme';

/* MUI */
import { Typography, Modal } from '@mui/material';

/* LIBRARIES */
import Cookies from 'universal-cookie';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const StyledButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 40px;
	width: 170px;
	border-radius: 12px;
	background-color: #f2c94c;
	border: solid #f2c94c 1px;
	font-weight: bold;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	color: #242B31;
	&:hover {
	transition: 0.2s;
	transform: scale(1.1)
`;

const ModalBox = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 20px;
	transform: translate(-50%, -50%);
	width: 600;
	background: ${Theme.dark.background.primary};
	border: 1px solid #000;
	height: 300px;
	color: ${Theme.dark.text.primary};
	text-align: center;
`;

const ModalTitle = styled.span`
	font-weight: bold;
	color: ${Theme.primary};
`;

const UserAccountList = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	gap: 10px;
`;

/* TYPE */
import { UserLogin, ConnectFleetData } from 'types/User';

type ConnectButtonProps = {
	label: string;
	svg: ReactElement;
};

/* Composant qui permet de se connecter à un compte client */
export default function ConnectButton({ label, svg }: ConnectButtonProps): ReactElement {
	/* States */
	const [open, setOpen] = useState<boolean>(false);

	/* Hooks */
	const deviceId = useSelector((state: RootState) => state.selectedDeviceId.deviceId) ?? null;
	const thirdPartyId =
		useSelector((state: RootState) => state.selectedDeviceId.thirdPartyId) ?? null;
	const thirdPartyName =
		useSelector((state: RootState) => state.selectedDeviceId.thirdPartyName) ?? null;
	const { data: user, isSuccess } = useAllTpAccountsQuery({ thirdPartyId });
	const [authenticate] = useAuthenticateMutation({});
	const { showToast } = useToast();

	/* Functions */
	async function connectClientAccount(user: string) {
		try {
			if (!user) {
				throw new Error("Erreur lors de la récupération du login de l'utilisateur");
			}

			const token = (await authenticate({ email: user })) as unknown as ConnectFleetData;

			if (!token.data.refreshToken) {
				throw new Error(
					'Erreur de la connection à un compte client : récupération du refreshToken'
				);
			}

			const cookies = new Cookies(null, { path: '/' });

			cookies.set('refreshToken', token.data.refreshToken, { path: '/', domain: '.awaken.fr' });

			window.open('https://app.awaken.fr/', '_blank');
		} catch (error) {
			console.error(error);

			showToast('Erreur lors de la connection au compte client', 'error');
		}
	}

	function handleOpen(): void {
		setOpen(true);
	}

	function handleClose(): void {
		setOpen(false);
	}

	/* Render */
	return (
		<>
			<StyledButton
				onClick={handleOpen}
				disabled={deviceId ? false : true}
			>
				{svg}
				<p>{label}</p>
			</StyledButton>
			<Modal
				open={open}
				onClose={handleClose}
			>
				<ModalBox>
					<Typography variant='h6'>
						Cliquez sur un compte utilisateur de la third party{' '}
						<ModalTitle>{thirdPartyName}</ModalTitle> pour vous connecter à son compte.
					</Typography>
					{isSuccess && user && user.data.length > 0 ? (
						user.data.map((user: UserLogin, index: number) => {
							return (
								<UserAccountList key={index}>
									<Typography
										variant='body1'
										onClick={() => connectClientAccount(user.login)}
										style={{ cursor: 'pointer' }}
									>
										{user.login}
									</Typography>
								</UserAccountList>
							);
						})
					) : (
						<Typography variant='body1'>
							{"Aucun compte utilisateur n'est disponible pour cette third party"}
						</Typography>
					)}
				</ModalBox>
			</Modal>
		</>
	);
}
