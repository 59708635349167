type NetworkProps = {
	width: number;
	height: number;
	networkRodNumber: number | undefined;
};

function NetworkSvg({ width, height, networkRodNumber }: NetworkProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 44 30'
			fill='none'
		>
			<rect
				y='25'
				width='7'
				height='5'
				rx='2'
				fill={networkRodNumber && networkRodNumber > 0 ? '#F2C94C' : 'rgba(242, 201, 76, 0.1)'}
			/>
			<path
				d='M13.4304 19.2534H11.6496C10.1864 19.2534 9 20.3857 9 21.7824V27.4682C9 28.8663 10.1864 29.9977 11.6496 29.9977H13.4304C14.8936 29.9977 16.08 28.8663 16.08 27.4682V21.7824C16.0797 20.3857 14.8933 19.2534 13.4304 19.2534Z'
				fill={networkRodNumber && networkRodNumber > 1 ? '#F2C94C' : 'rgba(242, 201, 76, 0.1)'}
			/>
			<path
				d='M22.5025 12.8369H20.4943C19.0933 12.8369 17.957 13.9692 17.957 15.3659V15.767V27.0689V27.4698C17.957 28.8676 19.0933 29.999 20.4943 29.999H22.5025C23.904 29.999 25.0403 28.8676 25.0403 27.4698V27.0689V15.767V15.3659C25.0403 13.9692 23.904 12.8369 22.5025 12.8369Z'
				fill={networkRodNumber && networkRodNumber > 2 ? '#F2C94C' : 'rgba(242, 201, 76, 0.1)'}
			/>
			<path
				d='M31.6122 6.41797H29.6034C28.203 6.41797 27.0664 7.55056 27.0664 8.94752V9.75635V26.6618V27.4706C27.0664 28.8687 28.2027 30.0002 29.6034 30.0002H31.6122C33.0137 30.0002 34.1497 28.8687 34.1497 27.4706V26.6618V9.75635V8.94752C34.1497 7.55084 33.0137 6.41797 31.6122 6.41797Z'
				fill={networkRodNumber && networkRodNumber > 3 ? '#F2C94C' : 'rgba(242, 201, 76, 0.1)'}
			/>
			<path
				d='M40.7215 0H38.7128C37.3121 0 36.1758 1.13259 36.1758 2.52955V9.75618V20.2435V27.4705C36.1758 28.8686 37.3118 30 38.7128 30H40.7215C42.1231 30 43.2585 28.8686 43.2585 27.4705V20.2435V9.75618V2.52955C43.2585 1.13259 42.1231 0 40.7215 0Z'
				fill={networkRodNumber && networkRodNumber > 4 ? '#F2C94C' : 'rgba(242, 201, 76, 0.1)'}
			/>
		</svg>
	);
}

export default NetworkSvg;
