/* REACT */
import { type ChangeEvent, type ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* SVG */
import MaxTempSVG from 'components/svg/MaxTempSvg';
import MinTempSVG from 'components/svg/MinTempSvg';

/* MUI */
import {
	Grid,
	Divider,
	Typography,
	TextField,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Box,
	FormControlLabel,
	Switch
} from '@mui/material';

/* TYPES */
import { PreSensorType, SensorType } from 'types/Form';

/* COMPONENT */
export default function SensorForm(): ReactElement {
	/* Hooks */
	const { control, errors, setClientInfos, clientInfos } = useNewClientContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'sensorInfos'
	});

	function changeNewClientInfos(
		event:
			| ChangeEvent<HTMLInputElement>
			| SelectChangeEvent<SensorType>
			| SelectChangeEvent<PreSensorType>,
		index: number
	) {
		const { value, name } = event.target;

		setClientInfos((prevClientInfos) => {
			const updatedSensorInfos = prevClientInfos.sensorInfos.map((sensor, idx) => {
				if (idx === index) {
					return {
						...sensor,
						[name]: value
					};
				}
				return sensor;
			});

			return {
				...prevClientInfos,
				sensorInfos: updatedSensorInfos
			};
		});
	}

	function addSensor() {
		append({
			id: '',
			name: '',
			type: 'temperature',
			macAddress: '',
			deviceId: '',
			orderId: null,
			preType: 'P T EN ',
			maxThreshold: null,
			minThreshold: null,
			statusMinThreshold: false,
			statusMaxThreshold: false
		});

		setClientInfos((prevClientInfos) => {
			const updatedSensorInfos = prevClientInfos.sensorInfos.concat({
				id: '',
				name: '',
				type: 'temperature',
				macAddress: '',
				deviceId: '',
				orderId: null,
				preType: 'P T EN ',
				maxThreshold: null,
				minThreshold: null,
				statusMinThreshold: false,
				statusMaxThreshold: false
			});

			return {
				...prevClientInfos,
				sensorInfos: updatedSensorInfos
			};
		});
	}

	function removeSensor(index: number) {
		setClientInfos((prevClientInfos) => {
			const updatedSensorInfos = prevClientInfos.sensorInfos.filter((sensor, idx) => idx !== index);

			return {
				...prevClientInfos,
				sensorInfos: updatedSensorInfos
			};
		});

		remove(index);
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>, index: number): void {
		const { checked, name } = event.target;

		setClientInfos((prevClientInfos) => {
			const updatedSensorInfos = prevClientInfos.sensorInfos.map((sensor, idx) => {
				if (idx === index) {
					return {
						...sensor,
						[name]: checked,
						[name === 'statusMinThreshold' ? 'minThreshold' : 'maxThreshold']: null
					};
				}
				return sensor;
			});

			return {
				...prevClientInfos,
				sensorInfos: updatedSensorInfos
			};
		});
	}

	return (
		<Grid
			container
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
		>
			<Divider sx={{ width: '700px', marginBottom: 3 }}>
				<Typography variant='h6'> SENSOR FORM</Typography>
			</Divider>
			{fields.map((item, index) => {
				return (
					<Grid
						container
						justifyContent='space-around'
						key={item.id}
						sx={{ marginBottom: 4, marginTop: 2 }}
					>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<Grid>
								<FormControl variant='standard'>
									<InputLabel id='type'>{'Type id du sensor'}</InputLabel>
									<Select
										labelId='sensorType'
										label='PreType du sensor'
										name='preType'
										value={clientInfos.sensorInfos[index].preType}
										onChange={(event) => {
											changeNewClientInfos(event as SelectChangeEvent<PreSensorType>, index);
										}}
									>
										<MenuItem value={'P T EN '}> P T EN </MenuItem>
										<MenuItem value={'C T '}>C T </MenuItem>
										<MenuItem value={'EYE S '}>EYE S </MenuItem>
										<MenuItem value={'MP1_'}>MP1_ </MenuItem>
									</Select>
								</FormControl>
								<TextField
									required
									error={Boolean(errors.sensorInfos && errors.sensorInfos[index])}
									helperText={
										errors.sensorInfos && errors.sensorInfos[index]
											? errors.sensorInfos[index]?.message
											: ''
									}
									name='id'
									id='id'
									label='Id du sensor'
									autoComplete='id'
									variant='standard'
									autoFocus={false}
									value={clientInfos.sensorInfos[index].id}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										changeNewClientInfos(event, index)
									}
								/>
							</Grid>

							<TextField
								required
								fullWidth
								error={Boolean(errors.sensorInfos && errors.sensorInfos[index])}
								helperText={
									errors.sensorInfos && errors.sensorInfos[index]
										? errors.sensorInfos[index]?.message
										: ''
								}
								name='name'
								id='name'
								label='Nom du Sensor'
								autoComplete='name'
								variant='standard'
								autoFocus={false}
								value={clientInfos.sensorInfos[index].name}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>

							<TextField
								required
								fullWidth
								error={Boolean(errors.sensorInfos && errors.sensorInfos[index])}
								helperText={
									errors.sensorInfos && errors.sensorInfos[index]
										? errors.sensorInfos[index]?.message
										: ''
								}
								name='orderId'
								id='orderId'
								label='Order Id du Sensor device'
								type='number'
								autoComplete='orderId'
								variant='standard'
								autoFocus={false}
								value={clientInfos.sensorInfos[index].orderId}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>

							<FormControlLabel
								control={
									<Switch
										checked={clientInfos.sensorInfos[index].statusMinThreshold}
										onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, index)}
										color='primary'
										name='statusMinThreshold'
									/>
								}
								label={
									<Box
										display='flex'
										alignItems='center'
									>
										<MinTempSVG
											width={30}
											height={30}
										/>

										<TextField
											disabled={!clientInfos.sensorInfos[index].statusMinThreshold}
											fullWidth
											error={Boolean(errors.sensorInfos && errors.sensorInfos[index])}
											helperText={
												errors.sensorInfos && errors.sensorInfos[index]
													? errors.sensorInfos[index]?.message
													: ''
											}
											name='minThreshold'
											id='minThreshold'
											label='Seuil Min'
											autoComplete='minThreshold'
											variant='standard'
											autoFocus={false}
											value={clientInfos.sensorInfos[index].minThreshold || ''}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
												changeNewClientInfos(event, index)
											}
											placeholder='min: -128 , max: 127'
											inputProps={{
												maxLength: 4
											}}
										/>
									</Box>
								}
							/>
						</Grid>
						<Grid
							container
							flexDirection='column'
							alignContent='center'
							xs={5}
						>
							<TextField
								required
								fullWidth
								error={Boolean(errors.sensorInfos && errors.sensorInfos[index])}
								helperText={
									errors.sensorInfos && errors.sensorInfos[index]
										? errors.sensorInfos[index]?.message
										: ''
								}
								name='macAddress'
								id='macAddress'
								label='MacAddress du device'
								autoComplete='macAddress'
								variant='standard'
								placeholder='00:00:00:00:00:00'
								autoFocus={false}
								value={clientInfos.sensorInfos[index].macAddress}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
								inputProps={{
									pattern: '^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$',
									title: 'Please enter a valid MacAddress',
									maxLength: 17
								}}
							/>

							<TextField
								required
								fullWidth
								error={Boolean(errors.sensorInfos && errors.sensorInfos[index])}
								helperText={
									errors.sensorInfos && errors.sensorInfos[index]
										? errors.sensorInfos[index]?.message
										: ''
								}
								name='deviceId'
								id='deviceId'
								label='Device ID of the Sensor'
								autoComplete='deviceId'
								variant='standard'
								autoFocus={false}
								value={clientInfos.sensorInfos[index].deviceId}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									changeNewClientInfos(event, index)
								}
							/>

							<FormControl
								variant='standard'
								fullWidth
							>
								<InputLabel id='type'>{'Choisir un type du sensor'}</InputLabel>
								<Select
									labelId='sensorType'
									label='Type du sensor'
									name='type'
									value={clientInfos.sensorInfos[index].type}
									onChange={(event) => {
										changeNewClientInfos(event as SelectChangeEvent<SensorType>, index);
									}}
								>
									<MenuItem value={'temperature'}>Temperature</MenuItem>
									<MenuItem value={'move'}>Move</MenuItem>
								</Select>
							</FormControl>

							<FormControlLabel
								sx={{ width: '100%' }}
								control={
									<Switch
										checked={clientInfos.sensorInfos[index].statusMaxThreshold}
										onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, index)}
										color='primary'
										name='statusMaxThreshold'
									/>
								}
								label={
									<Box
										display='flex'
										alignItems='center'
									>
										<MaxTempSVG
											width={30}
											height={30}
										/>

										<TextField
											disabled={!clientInfos.sensorInfos[index].statusMaxThreshold}
											fullWidth
											error={Boolean(errors.sensorInfos && errors.sensorInfos[index])}
											helperText={
												errors.sensorInfos && errors.sensorInfos[index]
													? errors.sensorInfos[index]?.message
													: ''
											}
											name='maxThreshold'
											id='maxThreshold'
											label='Seuil Max'
											autoComplete='Max Threshold'
											variant='standard'
											autoFocus={false}
											placeholder='min: -128 , max: 127'
											value={clientInfos.sensorInfos[index].maxThreshold || ''}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
												changeNewClientInfos(event, index)
											}
										/>
									</Box>
								}
							/>
						</Grid>

						<Button
							variant='contained'
							color='warning'
							onClick={() => {
								removeSensor(index);
							}}
						>
							Delete
						</Button>
					</Grid>
				);
			})}

			<Button
				variant='contained'
				color='primary'
				onClick={() => {
					addSensor();
				}}
			>
				Add Sensor
			</Button>
		</Grid>
	);
}
