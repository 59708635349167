/* REACT */
import { ReactElement } from 'react';

/* SVG */
import AwakenLogoSvg from '../../components/svg/AwakenLogoSvg';

/* MUI */
import { Typography, Toolbar } from '@mui/material';

/* CSS */
import { Theme } from '../../styles/Theme';

function LoginHeader(): ReactElement {
	return (
		<Toolbar style={{ marginTop: 40 }}>
			<AwakenLogoSvg
				height={60}
				width={60}
			/>
			<Typography
				variant='h4'
				sx={{ marginLeft: 5 }}
				color={Theme.dark.text.primary}
			>
				AWAKEN Dashboard Support
			</Typography>
		</Toolbar>
	);
}

export default LoginHeader;
