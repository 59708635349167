type ConfirmSvgProps = {
	width: number;
	height: number;
};

function ConfirmSvg({ width, height }: ConfirmSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 18 14'
			fill='none'
		>
			<path
				d='M6.00016 11.1698L1.83016 6.99984L0.410156 8.40984L6.00016 13.9998L18.0002 1.99984L16.5902 0.589844L6.00016 11.1698Z'
				fill='#6FCF97'
			/>
		</svg>
	);
}

export default ConfirmSvg;
