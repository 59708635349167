type ConnectSvgProps = {
	width: number;
	height: number;
};

export default function ConnectSvg({ width, height }: ConnectSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 17 17'
			fill='none'
		>
			<path
				d='M7.74878 10.936C7.64967 11.0346 7.57099 11.1517 7.51724 11.2807C7.46348 11.4098 7.43571 11.5481 7.43552 11.6879C7.43532 11.8276 7.46271 11.9661 7.5161 12.0952C7.5695 12.2244 7.64786 12.3417 7.74668 12.4406C7.84551 12.5394 7.96287 12.6178 8.09203 12.6712C8.22119 12.7245 8.35962 12.7519 8.49939 12.7517C8.63915 12.7515 8.7775 12.7238 8.90652 12.67C9.03553 12.6163 9.15267 12.5376 9.25122 12.4385L12.4387 9.25098C12.5374 9.15234 12.6157 9.03523 12.6691 8.90634C12.7225 8.77744 12.75 8.63928 12.75 8.49976C12.75 8.36023 12.7225 8.22207 12.6691 8.09318C12.6157 7.96428 12.5374 7.84717 12.4387 7.74853L9.25122 4.56103C9.05179 4.36271 8.78186 4.25156 8.50061 4.25195C8.21936 4.25235 7.94974 4.36425 7.75086 4.56312C7.55199 4.762 7.44009 5.03162 7.4397 5.31287C7.43931 5.59412 7.55046 5.86405 7.74878 6.06348L9.12255 7.43726H1.0625C0.780707 7.43726 0.510456 7.5492 0.311199 7.74846C0.111942 7.94771 0 8.21796 0 8.49976C0 8.78155 0.111942 9.0518 0.311199 9.25106C0.510456 9.45031 0.780707 9.56226 1.0625 9.56226H9.12255L7.74878 10.936Z'
				fill='#242B31'
			/>
			<path
				d='M15.9375 1.708e-07H5.3125C5.17295 -7.89758e-05 5.03475 0.0273494 4.90581 0.080717C4.77686 0.134085 4.6597 0.212345 4.56102 0.311023C4.46234 0.409701 4.38408 0.526861 4.33072 0.655805C4.27735 0.78475 4.24992 0.922948 4.25 1.0625V3.1875C4.25 3.46929 4.36194 3.73954 4.5612 3.9388C4.76046 4.13806 5.03071 4.25 5.3125 4.25C5.59429 4.25 5.86454 4.13806 6.0638 3.9388C6.26306 3.73954 6.375 3.46929 6.375 3.1875V2.125H14.875V14.875H6.375V13.8125C6.375 13.5307 6.26306 13.2605 6.0638 13.0612C5.86454 12.8619 5.59429 12.75 5.3125 12.75C5.03071 12.75 4.76046 12.8619 4.5612 13.0612C4.36194 13.2605 4.25 13.5307 4.25 13.8125V15.9375C4.24992 16.0771 4.27735 16.2153 4.33072 16.3442C4.38408 16.4731 4.46234 16.5903 4.56102 16.689C4.6597 16.7877 4.77686 16.8659 4.90581 16.9193C5.03475 16.9727 5.17295 17.0001 5.3125 17H15.9375C16.0771 17.0001 16.2153 16.9727 16.3442 16.9193C16.4731 16.8659 16.5903 16.7877 16.689 16.689C16.7877 16.5903 16.8659 16.4731 16.9193 16.3442C16.9727 16.2153 17.0001 16.0771 17 15.9375V1.0625C17.0001 0.922948 16.9727 0.78475 16.9193 0.655805C16.8659 0.526861 16.7877 0.409701 16.689 0.311023C16.5903 0.212345 16.4731 0.134085 16.3442 0.080717C16.2153 0.0273494 16.0771 -7.89758e-05 15.9375 1.708e-07Z'
				fill='#242B31'
			/>
		</svg>
	);
}
