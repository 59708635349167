/* TYPES */
import { SmsCommands } from '../../../types/Devices';

export const DEVICES_TYPE_CATEGORIES = {
	'FMB SMS/GPRS Commands': [
		'FMB001',
		'FMB010',
		'FMB002',
		'FMB020',
		'FMB003',
		'FMB110',
		'FMB120',
		'FMB122',
		'FMB125',
		'FMB130',
		'FMB202',
		'FMB204',
		'FMB208',
		'FMB900',
		'FMB920',
		'FMB962',
		'FMB964',
		'FM3001',
		'FMT100',
		'FMC001',
		'FMC003',
		'FMC00A',
		'FMM001',
		'FMM003',
		'FMM00A',
		'FMC125',
		'FMC130',
		'FMM125',
		'FMM130',
		'FMU125',
		'FMU126',
		'FMU130',
		'MTB100',
		'FMB140'
	],
	'GH SMS/GPRS Commands': ['TMT250', 'GH5200', 'TST100', 'TFT100', 'TAT100']
};

export const SMS_COMMANDS: SmsCommands = {
	FMBSmsCommands: [
		{ command: 'getinfo', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getver', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getstatus', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getgps', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getio', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'ggps', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'readio ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'cpureset', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'fwstats', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getrecord', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getimeiccid ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'getimsi', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'allver', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getparam ', parameterNumber: 2, parameterSeparator: ';' },
		{ command: 'setparam ', parameterNumber: 10, parameterSeparator: ';' },
		{ command: 'flush ', parameterNumber: 7, parameterSeparator: ',' },
		{ command: 'countrecs', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'web_connect', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'deleterecords', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'battery', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'wdlog', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'defaultcfg', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'setkey ', parameterNumber: 2, parameterSeparator: ' ' },
		{ command: 'delkey ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'bbread ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'bbinfo ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'sdformat', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'btgetlist ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'btscan', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'btvisible ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'btrelease ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'btunpair ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'fc_reset', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'towingreact', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'auto_calibrate:get', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'odoset:', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'odoget', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'on_demand_tracking0', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'on_demand_tracking1', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'on_demand_tracking2', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'obdinfo', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'faultcodes', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'cleardtc', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getvin', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcansetprog ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'lvcangetprog', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'vcangetinfo', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcansimpletacho ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'lvcanclear ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'allcanmode', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanmode', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanfaultcodes', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanopenalldoors', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'vcanclosealldoors', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanopentrunk', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanblockengine', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanunblockengine', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanturninglights', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanhorn', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcansetting ', parameterNumber: 2, parameterSeparator: ';' },
		{ command: 'lvcanrefresh', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcanreset', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'lvcancheck', parameterNumber: 0, parameterSeparator: ';' }
	],
	GHSmsCommands: [
		{ command: 'getinfo', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getver', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getstatus', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'cpureset', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'fwstats', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getrecord', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getimeiccid ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'getimsi', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'allver', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'getparam ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'setparam ', parameterNumber: 10, parameterSeparator: ';' },
		{ command: 'flush ', parameterNumber: 7, parameterSeparator: ',' },
		{ command: 'countrecs', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'web_connect', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'deleterecords', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'wdlog', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'defaultcfg', parameterNumber: 0, parameterSeparator: ';' },
		{ command: 'setkey ', parameterNumber: 2, parameterSeparator: ' ' },
		{ command: 'delkey ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'bbread ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'bbinfo ', parameterNumber: 1, parameterSeparator: ';' },
		{ command: 'sdformat', parameterNumber: 0, parameterSeparator: ';' }
	]
};

export const DEVICES_TYPES_PARAMETER_LIST_URL: Record<string, string> = {
	FMB: 'https://wiki.teltonika-gps.com/view/Template:FMB_Device_Family_Parameter_list',
	FMT100: 'https://wiki.teltonika-gps.com/view/FMT100_Parameter_list',
	FMC: 'https://wiki.teltonika-gps.com/wikibase/index.php?title=FMC_Device_Family_Parameter_list&redirect=no',
	FMM: 'https://wiki.teltonika-gps.com/view/Template:FMM_Device_Family_Parameter_list',
	FMU130: 'https://wiki.teltonika-gps.com/view/FMU130_Parameter_list',
	GH5200: 'https://wiki.teltonika-mobility.com/view/GH5200_Parameter_list',
	TAT100: 'https://wiki.teltonika-mobility.com/view/TAT100_Parameter_list',
	TMT250: 'https://wiki.teltonika-mobility.com/view/TMT250_Parameter_list',
	TFT100: 'https://wiki.teltonika-mobility.com/view/TFT100_Parameter_list'
};
