/*  REACT */
import { createContext, useContext, ReactNode } from 'react';

/* REACT TOASTIFY */
import { toast, ToastOptions } from 'react-toastify';

type ToastContextType = {
	showToast: (message: string, type: string) => void;
};

type ToastContextProviderProps = {
	children: ReactNode;
};

function showToast(message: string, type: string): void {
	const toastOptions: ToastOptions = {
		position: 'bottom-right',
		autoClose: false,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		theme: 'light'
	};

	if (type === 'success') {
		toast.success(message, toastOptions);
	} else if (type === 'error') {
		toast.error(message, toastOptions);
	} else if (type === 'info') {
		toast.info(message, toastOptions);
	} else if (type === 'warning') {
		toast.warn(message, toastOptions);
	} else {
		toast(message, toastOptions);
	}
}

export const ToastContext = createContext<ToastContextType>({
	showToast: () => {
		return;
	}
});

export function ToastContextProvider({ children }: ToastContextProviderProps) {
	return <ToastContext.Provider value={{ showToast }}>{children}</ToastContext.Provider>;
}

export const useToast = () => {
	return useContext(ToastContext);
};
