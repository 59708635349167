/* eslint-disable react/jsx-key */
/* MUI */
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import { ReactElement, useEffect } from 'react';

/* TYPES */
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormValues } from './DevicesLinkForm';
import { ThirdParty, AllThirdParties, AllThirdPartiesDevices } from 'types/ThirdParty';

interface ThirdPartiesAutocomplete {
	register: UseFormRegister<FormValues>;
	setValue: UseFormSetValue<FormValues>;
	thirdParties: AllThirdParties;
	thirdPartiesDevices: AllThirdPartiesDevices;
}

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
	label: string;
}

/* Component qui affiche la label et l'icone de fermeture de l'autocomplete */
function Tag({ label, onDelete, ...other }: TagProps) {
	/* Render */
	return (
		<div {...other}>
			<span>{label}</span>
			<CloseIcon onClick={onDelete} />
		</div>
	);
}

/* MUI STYLES */
const Root = styled('div')(
	({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
  font-size: 14px;
`
);

const Label = styled('label')`
	padding: 0 0 4px;
	line-height: 1.5;
	display: block;
`;

const InputWrapper = styled('div')(
	({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

const StyledTag = styled(Tag)<TagProps>(
	({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled('ul')(
	({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

/* Autocomplete qui affiche toutes les thirdParties et permet d'en choisir une ou plusieurs afin de les lier à un device */
export default function ThirdPartiesAutocomplete({
	thirdParties,
	thirdPartiesDevices,
	register,
	setValue
}: ThirdPartiesAutocomplete): ReactElement {
	/* Variables */
	// Tableau qui contient toutes les thirdParties qui sont liées à un device
	const deviceTp = thirdParties.data.filter((thirdParties) => {
		return thirdPartiesDevices.data.some((tpDevices) => thirdParties.id === tpDevices.thirdPartyId);
	});

	/* Hooks */
	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getTagProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
		value,
		focused,
		setAnchorEl
	} = useAutocomplete({
		id: 'thirdParties',
		defaultValue: deviceTp,
		multiple: true,
		options: thirdParties.data,
		getOptionLabel: (option) => option.name
	});

	// groupedOptions est mal typé par MUI, ce cast ne pose pas de problèmes tant que l'option groupBy n'est pas activée
	// https://mui.com/base-ui/react-autocomplete/hooks-api/#use-autocomplete-parameters-groupBy
	const allOptions = groupedOptions as ThirdParty[];

	useEffect(() => {
		setValue('thirdParties', value);
	}, [value]);

	/* Render */
	return (
		<Root>
			<div {...getRootProps()}>
				<Label {...getInputLabelProps()}>Sélectionner une ou pluseurs third parties *</Label>
				<InputWrapper
					{...register('thirdParties')}
					ref={setAnchorEl}
					className={focused ? 'focused' : ''}
				>
					{value.map((option: ThirdParty, index: number) => (
						<StyledTag
							label={option.name}
							{...getTagProps({ index })}
						/>
					))}
					<input {...getInputProps()} />
				</InputWrapper>
			</div>
			{allOptions.length > 0 ? (
				<Listbox {...getListboxProps()}>
					{allOptions.map((option, index) => (
						<li
							{...getOptionProps({ option, index })}
							key={index}
						>
							<span>{`${option.id} ${option.name} ${option.email ?? ''}`}</span>
							<CheckIcon fontSize='small' />
						</li>
					))}
				</Listbox>
			) : null}
		</Root>
	);
}
