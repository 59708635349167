import React from 'react';

/* HOOKS */
import { useNewClientContext } from 'contexts/NewClientContext';

/* MATERIAL UI */
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography
} from '@mui/material';

/* STYLES COMPONENTS */
import { StyledTableCell } from './StylesTableReview';

/* STYLES */
function ReviewSensorForm(): React.ReactElement {
	/* Hooks */
	const clientInfos = useNewClientContext().clientInfos;

	return (
		<TableContainer
			component={Paper}
			sx={{
				marginTop: 4
			}}
		>
			<Typography
				variant='h6'
				id='tableTitle'
			>
				Récapitulatif des capteurs de température
			</Typography>
			<Table
				sx={{ minWidth: 650 }}
				aria-label='simple table'
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align='right'>Id</StyledTableCell>
						<StyledTableCell align='right'>Nom</StyledTableCell>
						<StyledTableCell align='right'>Type</StyledTableCell>
						<StyledTableCell align='right'>Seuil Min</StyledTableCell>
						<StyledTableCell align='right'>Seuil Max</StyledTableCell>
						<StyledTableCell align='right'>Mac Address</StyledTableCell>
						<StyledTableCell align='right'>Device Id</StyledTableCell>
						<StyledTableCell align='right'>Order Id</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{clientInfos.sensorInfos.map((row) => (
						<TableRow
							key={row.name}
							sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
						>
							<TableCell align='right'>
								{row.preType}
								{row.id}
							</TableCell>
							<TableCell align='right'>{row.name}</TableCell>
							<TableCell align='right'>{row.type}</TableCell>
							<TableCell align='right'>{row.minThreshold ? row.minThreshold : 'N/A'} °C</TableCell>
							<TableCell align='right'>{row.maxThreshold ? row.maxThreshold : 'N/A'} °C</TableCell>
							<TableCell align='right'>{row.macAddress}</TableCell>
							<TableCell align='right'>{row.deviceId}</TableCell>
							<TableCell align='right'>{row.orderId}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ReviewSensorForm;
