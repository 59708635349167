/* eslint-disable react/display-name */
/* REACT */
import { ReactElement, ChangeEvent, useState, useMemo, ReactNode } from 'react';

/* CONTEXT */
import { useToast } from 'contexts/ToastContext';

/* REDUX */
import { useSendSmsMutation } from 'api/fleetApiSlice';

/* VARIABLES */
import { smsCommands } from '../smsCommands';

/* MUI */
import { TextField, Typography, Autocomplete, Checkbox, Modal, Paper } from '@mui/material';
import { yellow } from '@mui/material/colors';

/* CSS */
import { Theme } from 'styles/Theme';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const SmsFooterBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 400px;
	width: 95%;
	background-color: ${Theme.dark.background.secondary};
	border-radius: 10px;
	color: white;
`;

const SmsCommandBox = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	position: relative;
	left: 23%;
	gap: 20px;
`;

const ParamsValuesBox = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	gap: 20px;
`;

const CheckboxStyled = styled.div`
	display: flex;
	align-items: center;
`;

const VerifyFormButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 40px;
	width: 170px;
	border-radius: 12px;
	background-color: #f2c94c;
	border: solid #f2c94c 1px;
	font-weight: bold;
	color: #242b31;
	cursor: pointer;
`;

const NewParamButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 170px;
	border-radius: 12px;
	background-color: #758694;
	border: solid #758694 1px;
	font-weight: bold;
	color: #242b31;
	cursor: pointer;
`;

const RemoveParamButton = styled.button`
	display: flex;
	justify-content: center;
	position: relative;
	top: 15px;
	right: 20px;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: #e57373;
	border: solid #e57373 1px;
	font-weight: bold;
	color: #242b31;
	cursor: pointer;
`;

const ModalBox = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	overflow: auto;
	max-height: 80vh;
	top: 50%;
	left: 50%;
	padding: 20px;
	transform: translate(-50%, -50%);
	width: 800px;
	background: ${Theme.dark.background.primary};
	border: 1px solid #000;
	color: ${Theme.dark.text.primary};
	text-align: center;
`;

const ModalTitle = styled.span`
	font-weight: bold;
	color: ${Theme.primary};
`;

const ButtonBox = styled.div`
	display: flex;
	justify-content: right;
	gap: 20px;
	align-items: center;
	width: 100%;
	margin-top: 35px;
`;

const CancelButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 40px;
	width: 120px;
	border-radius: 12px;
	background-color: #f2c94c;
	border: solid #f2c94c 1px;
	font-weight: bold;
	color: #242b31;
	cursor: pointer;
`;
const CancelSmsButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	position: relative;
	left: 550px;
	margin-top: 20px;
	align-items: center;
	height: 40px;
	width: 120px;
	border-radius: 12px;
	background-color: #f2c94c;
	border: solid #f2c94c 1px;
	font-weight: bold;
	color: #242b31;
	cursor: pointer;
`;

const SendButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 40px;
	width: 120px;
	border-radius: 12px;
	background-color: #242b31;
	border: solid #f2c94c 1px;
	font-weight: bold;
	color: #f2c94c;
	cursor: pointer;
`;

const CommandsResultBox = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	overflow: auto;
	max-height: 80vh;
	width: 800px;
	gap: 30px;
	top: 50%;
	left: 50%;
	padding: 20px;
	transform: translate(-50%, -50%);
	width: 700px;
	background: ${Theme.dark.background.primary};
	border: 1px solid #000;
	p: 4;
	text-align: center;
`;

const SuccessfulCommandsBox = styled.div`
	font-size: 0.8rem;
	color: #6fcf97;
`;

const FailedCommandsBox = styled.div`
	font-size: 0.8rem;
	color: #e57373;
`;

const SuccessfulCommands = styled.p`
	font-size: 0.8rem;
	color: #6fcf97;
`;

const FailedCommands = styled.p`
	font-size: 0.8rem;
	color: #e57373;
`;

const AutocompleteStyled = styled(Autocomplete)({
	width: 500,
	color: 'white',
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&:hover fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&.Mui-focused fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		}
	},
	'& .MuiInputBase-input': {
		color: 'white',
		'&:focus': {
			outline: 'none',
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		}
	},
	'& .MuiInputLabel-root': {
		color: Theme.dark.text.secondary
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: Theme.dark.text.secondary
	}
});

const TextFieldStyled = styled(TextField)({
	width: 200,
	'& .MuiInputLabel-root': {
		color: Theme.dark.text.secondary
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: Theme.dark.text.secondary
	},
	'& .MuiInputBase-input': {
		color: Theme.dark.text.secondary
	},
	color: 'white',
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&:hover fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		},
		'&.Mui-focused fieldset': {
			borderColor: Theme.dark.text.secondary,
			color: Theme.dark.text.secondary
		}
	},
	'& .MuiAutocomplete-clearIndicator': {
		color: Theme.dark.text.secondary,
		'&:hover': {
			color: Theme.dark.text.secondary
		}
	}
});

/* TYPES */
import { Devices } from 'types/Devices';
import { SmsCommand } from 'types/Sim';

interface SmsFormFooterProps {
	thirdParty: string;
	setIsChecked: (value: boolean) => void;
	devices: string[] | [];
	tpDevices: Devices[];
}

interface Params {
	param: string;
	value: string;
}

export default function SmsFormFooter({
	setIsChecked,
	devices,
	thirdParty,
	tpDevices
}: SmsFormFooterProps): ReactElement {
	/* States */
	const [smsCommand, setSmsCommand] = useState<string>('');
	const [paramsList, setParamsList] = useState<Params[]>([{ param: '', value: '' }]);
	const [smsResult, setSmsResult] = useState([
		{
			success: false,
			message: '',
			simType: '',
			deviceId: ''
		}
	]);
	const [sureSendSms, setSureSendSms] = useState<boolean>(false);
	const [openDevicesModal, setOpenDevicesModal] = useState<boolean>(false);
	const [openSmsModal, setOpenSmsModal] = useState<boolean>(false);

	/* Hooks */
	const [sendSms] = useSendSmsMutation();
	const { showToast } = useToast();

	/* Variables */
	const smsCommandLabels = smsCommands.map(
		(smsCommand) => `${smsCommand.command} (${smsCommand.description})`
	);

	const tpDevicesLabel = tpDevices.filter((tpDevice) =>
		devices.some((device) => device === tpDevice.deviceId)
	);

	const filteredDevices = tpDevices
		.filter((tpDevice) => (devices as string[]).includes(tpDevice.deviceId))
		.map((tpDevice) => ({
			deviceId: tpDevice.deviceId,
			immatriculation: tpDevice.immatriculation,
			thirdPartyDeviceName: tpDevice.thirdPartyDeviceName
		}));

	const finalResult = smsResult.map((sms) => {
		const matchedDevice = filteredDevices.find((device) => device.deviceId === sms.deviceId);

		if (matchedDevice) {
			return {
				...sms,
				immatriculation: matchedDevice.immatriculation,
				thirdPartyDeviceName: matchedDevice.thirdPartyDeviceName
			};
		} else {
			return sms;
		}
	});

	const failedSms = finalResult.filter((sms) => !sms.success);
	const successfulSms = finalResult.filter((sms) => sms.success);

	/* Functions */
	function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
		setIsChecked(event.target.checked);
	}

	function sureSendSmsCheckbox(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.checked) {
			setSureSendSms(true);
		} else {
			setSureSendSms(false);
		}
	}

	function handleParamChange(
		index: number,
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		const newParamsList = paramsList.map((param, i) => {
			return i === index ? { ...param, param: event.target.value } : param;
		});

		setParamsList(newParamsList);
	}

	function handleValueChange(
		index: number,
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		const newParamsList = paramsList.map((param, i) => {
			return i === index ? { ...param, value: event.target.value } : param;
		});

		setParamsList(newParamsList);
	}

	function addParamsBox() {
		setParamsList([...paramsList, { param: '', value: '' }]);
	}

	function removeParamsBox(index: number) {
		const newParamsList = paramsList.filter((_, i) => i !== index);

		setParamsList(newParamsList);
	}

	function getSelectedCommand() {
		return smsCommands.find(
			(command) => `${command.command} (${command.description})` === smsCommand
		);
	}

	const selectedCommand = getSelectedCommand();

	function handleOpen() {
		if (!thirdParty) {
			showToast('Veuillez sélectionner une third party', 'error');

			return;
		}

		if (devices.length === 0) {
			showToast('Veuillez sélectionner au moins un device', 'error');

			return;
		}

		if (!smsCommand) {
			showToast('Veuillez saisir une commande SMS', 'error');

			return;
		}

		if (!sureSendSms) {
			showToast("Veuillez confirmer l'envoie de la commande SMS", 'error');

			return;
		}

		if (paramsList.length === 0) {
			showToast('Veuillez ajouter un paramètre', 'error');

			return;
		}

		if (selectedCommand && selectedCommand.parameterNumber > 0) {
			for (let i = 0; i < paramsList.length; i++) {
				if (!paramsList[i].param || (!paramsList[i].value && !selectedCommand.isIgnoredValue)) {
					showToast('Veuillez remplir tous les champs', 'error');

					return;
				}
			}
		}

		setOpenDevicesModal(true);
	}

	function handleOpenModal() {
		setOpenSmsModal(true);
	}

	function handleClose() {
		setOpenDevicesModal(false);
	}

	function handleCloseModal() {
		setOpenSmsModal(false);
	}

	async function sendSmsDevice() {
		let smsCommandToSend = `${smsCommand.split('(')[0].trim()} ${paramsList
			.map((param) => `${param.param}:${param.value}`)
			.join(';')}`;

		if (selectedCommand?.isIgnoredValue) {
			smsCommandToSend = `${smsCommand.split('(')[0].trim()} ${paramsList
				.map((param) => `${param.param}`)
				.join(';')}`;
		}

		let finalCommand = null;

		if (!paramsList[0].param && !paramsList[0].value) {
			finalCommand = smsCommandToSend.slice(0, -2);
		} else if (selectedCommand?.isIgnoredValue) {
			finalCommand = smsCommandToSend + ';';
		} else {
			finalCommand = smsCommandToSend;
		}

		try {
			const sms = await sendSms({ devices, finalCommand }).unwrap();

			setSmsResult(sms);

			devices.forEach((device) => {
				if (!sms.some((sms) => sms.deviceId === device)) {
					setSmsResult((prev) => [
						...prev,
						{
							success: false,
							message: `La sim est désactivé ou le device n'est pas branché : l'envoi de sms a échoué pour le device ${device}`,
							simType: '',
							deviceId: device
						}
					]);
				}
			});
		} catch (error) {
			showToast("Erreur lors de l'envoi de la commande SMS", 'error');
		}
	}

	const PaperList = useMemo(
		() =>
			({ children }: { children: ReactNode }) =>
				(
					<Paper
						sx={{
							'& .MuiAutocomplete-listbox': {
								backgroundColor: Theme.dark.background.secondary,
								color: Theme.dark.text.primary,
								maxHeight: '200px',
								overflow: 'auto',
								textAlign: 'left'
							}
						}}
					>
						{children}
					</Paper>
				),
		[]
	);

	/* Render */
	return (
		<SmsFooterBox>
			<SmsCommandBox>
				<CheckboxStyled>
					<Checkbox
						sx={{
							color: yellow[800],
							'&.Mui-checked': {
								color: yellow[800]
							},
							position: 'relative',
							left: -10
						}}
						onChange={handleCheckboxChange}
					/>
					<Typography
						component='p'
						variant='body1'
						align='center'
						color='whitesmoke'
						sx={{ fontSize: '0.8rem', position: 'relative', left: -10 }}
					>
						Sélectionner tous les devices
					</Typography>
				</CheckboxStyled>
				<AutocompleteStyled
					id='smsCommand'
					disablePortal
					options={smsCommandLabels}
					onChange={(_, value) => {
						value ? setSmsCommand(value as string) : '';
						setParamsList([{ param: '', value: '' }]);
					}}
					// @ts-expect-error - Nécessaire car la prop PaperComponent n'est pas reconnue par Autocomplete mais ça fonctionne
					PaperComponent={PaperList}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Rechercher une commande SMS'
						/>
					)}
				/>
				{selectedCommand &&
					selectedCommand.parameterNumber > 0 &&
					paramsList.map((param, index) => (
						<ParamsValuesBox key={index}>
							<TextFieldStyled
								id={`params-${index}`}
								label='Paramètres'
								value={param.param}
								onChange={(event) => handleParamChange(index, event)}
							/>

							<TextFieldStyled
								id={`value-${index}`}
								label='Valeurs'
								value={param.value}
								disabled={selectedCommand.isIgnoredValue}
								onChange={(event) => handleValueChange(index, event)}
							/>
							<RemoveParamButton onClick={() => removeParamsBox(index)}>x</RemoveParamButton>
						</ParamsValuesBox>
					))}
				{selectedCommand && selectedCommand.parameterNumber > 0 && (
					<NewParamButton onClick={addParamsBox}>Ajouter un nouveau paramètre</NewParamButton>
				)}
				<CheckboxStyled>
					<Checkbox
						sx={{
							color: '#E9696D',
							'&.Mui-checked': {
								color: '#E9696D'
							}
						}}
						onChange={sureSendSmsCheckbox}
					/>
					<Typography
						component='p'
						variant='body1'
						align='center'
						color='whitesmoke'
						sx={{ fontSize: '0.8rem', color: '#E9696D' }}
					>
						{"Je suis sur d'envoyer la commande SMS"}
					</Typography>
				</CheckboxStyled>
				<VerifyFormButton onClick={handleOpen}>Envoyer</VerifyFormButton>
				<Modal
					open={openDevicesModal}
					onClose={handleClose}
				>
					<ModalBox>
						<Typography variant='h6'>
							⚠️ Vous vous apprêtez à modifier des boitiers clients !
						</Typography>
						<Typography
							variant='h6'
							sx={{ mt: 3, marginBottom: 5 }}
						>
							Envoyez la commande SMS <ModalTitle>{smsCommand.split('(')[0].trim()}</ModalTitle> sur
							les {devices.length} boitiers suivants ?
						</Typography>
						{tpDevicesLabel.map((device) => (
							<Typography
								key={device.deviceId}
								sx={{ marginBottom: 1 }}
							>
								{`${device.deviceId} - ${device.immatriculation} - ${device.thirdPartyDeviceName}`}
							</Typography>
						))}
						<ButtonBox>
							<CancelButton onClick={handleClose}>Annuler</CancelButton>
							<SendButton
								onClick={() => {
									sendSmsDevice();
									handleOpenModal();
								}}
							>
								Confirmer
							</SendButton>

							<Modal
								open={openSmsModal}
								onClose={() => setOpenSmsModal(false)}
							>
								<CommandsResultBox>
									<FailedCommandsBox>
										<Typography variant='h6'>Commandes non abouties</Typography>
										{failedSms.map((sms: SmsCommand, index) => {
											return (
												<FailedCommands key={index}>
													{sms.message} - {sms.immatriculation} - {sms.thirdPartyDeviceName}
												</FailedCommands>
											);
										})}
									</FailedCommandsBox>
									<SuccessfulCommandsBox>
										<Typography variant='h6'>Commandes abouties</Typography>
										{successfulSms.map((sms: SmsCommand, index) => {
											return (
												<SuccessfulCommands key={index}>
													{sms.message} - {sms.immatriculation} - {sms.thirdPartyDeviceName}
												</SuccessfulCommands>
											);
										})}
									</SuccessfulCommandsBox>
									<CancelSmsButton onClick={handleCloseModal}>Fermer</CancelSmsButton>
								</CommandsResultBox>
							</Modal>
						</ButtonBox>
					</ModalBox>
				</Modal>
			</SmsCommandBox>
		</SmsFooterBox>
	);
}
