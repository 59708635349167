type AdviceSvgProps = {
	width: number;
	height: number;
};

function AdviceSvg({ width, height }: AdviceSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 29 40'
			fill='none'
		>
			<path
				d='M8.1039 38C8.1039 39.1 9.01558 40 10.1299 40H18.2338C19.3481 40 20.2597 39.1 20.2597 38V36H8.1039V38ZM14.1818 0C6.36156 0 0 6.28 0 14C0 18.76 2.41091 22.94 6.07792 25.48V30C6.07792 31.1 6.98961 32 8.1039 32H20.2597C21.374 32 22.2857 31.1 22.2857 30V25.48C25.9527 22.94 28.3636 18.76 28.3636 14C28.3636 6.28 22.0021 0 14.1818 0ZM19.9558 22.2L18.2338 23.4V28H10.1299V23.4L8.40779 22.2C5.67273 20.32 4.05195 17.26 4.05195 14C4.05195 8.48 8.59013 4 14.1818 4C19.7735 4 24.3117 8.48 24.3117 14C24.3117 17.26 22.6909 20.32 19.9558 22.2Z'
				fill='#F2C94C'
			/>
		</svg>
	);
}

export default AdviceSvg;
