type GetParamSvgProps = {
	width: number;
	height: number;
};

function GetParamSvg({ width, height }: GetParamSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 17 17'
			fill='none'
		>
			<path
				d='M13.5 1C12.837 1 12.2011 1.26339 11.7322 1.73223C11.2634 2.20107 11 2.83696 11 3.5V13.5C11 14.163 11.2634 14.7989 11.7322 15.2678C12.2011 15.7366 12.837 16 13.5 16C14.163 16 14.7989 15.7366 15.2678 15.2678C15.7366 14.7989 16 14.163 16 13.5C16 12.837 15.7366 12.2011 15.2678 11.7322C14.7989 11.2634 14.163 11 13.5 11H3.5C2.83696 11 2.20107 11.2634 1.73223 11.7322C1.26339 12.2011 1 12.837 1 13.5C1 14.163 1.26339 14.7989 1.73223 15.2678C2.20107 15.7366 2.83696 16 3.5 16C4.16304 16 4.79893 15.7366 5.26777 15.2678C5.73661 14.7989 6 14.163 6 13.5V3.5C6 2.83696 5.73661 2.20107 5.26777 1.73223C4.79893 1.26339 4.16304 1 3.5 1C2.83696 1 2.20107 1.26339 1.73223 1.73223C1.26339 2.20107 1 2.83696 1 3.5C1 4.16304 1.26339 4.79893 1.73223 5.26777C2.20107 5.73661 2.83696 6 3.5 6H13.5C14.163 6 14.7989 5.73661 15.2678 5.26777C15.7366 4.79893 16 4.16304 16 3.5C16 2.83696 15.7366 2.20107 15.2678 1.73223C14.7989 1.26339 14.163 1 13.5 1Z'
				stroke='#F2C94C'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default GetParamSvg;
