/* REACT */
import { ReactElement } from 'react';

/* COMPONENTS */
import LoginHeader from './LoginHeader';
import LoginForm from './LoginForm';

/* CSS */
import styled from 'styled-components';
import { Theme } from '../../styles/Theme';

const LoginSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	gap: 160px;
	background-color: ${Theme.dark.background.primary};
`;

function Login(): ReactElement {
	return (
		<LoginSection>
			<LoginHeader />
			<LoginForm />
		</LoginSection>
	);
}

export default Login;
