/* REACT */
import { type ChangeEvent, type ReactElement } from 'react';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* MUI */
import {
	Grid,
	Divider,
	Typography,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	TextField,
	Link
} from '@mui/material';

/* TYPES */
import { ExportRange, ExportType } from 'types/Form';

/* COMPONENT */
export default function RapportForm(): ReactElement {
	/* States */

	/* Hooks */
	const clientInfos = useNewClientContext().clientInfos;
	const setClientInfos = useNewClientContext().setClientInfos;

	/* Render */
	return (
		<Grid
			container
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
		>
			<Divider sx={{ width: '700px', marginBottom: 3 }}>
				<Typography variant='h6'> Rapport Automatique</Typography>
			</Divider>
			<Grid
				container
				justifyContent='space-around'
			>
				<Grid
					container
					flexDirection='column'
					alignContent='center'
					xs={5}
				>
					<FormControl
						variant='standard'
						fullWidth
					>
						<InputLabel id='exportRange'>{"Choisir un type d'export"}</InputLabel>
						<Select
							labelId='exportType'
							label="Type d'utilisateur"
							value={clientInfos.rapportAutoInfos.exportType}
							onChange={(event) => {
								setClientInfos({
									...clientInfos,
									rapportAutoInfos: {
										...clientInfos.rapportAutoInfos,
										exportType: event.target.value as ExportType
									}
								});
							}}
						>
							<MenuItem value={'pdf'}>PDF</MenuItem>
							<MenuItem value={'xls'}>XLS</MenuItem>
							<MenuItem value={'csv'}>CSV</MenuItem>
						</Select>
					</FormControl>
					<FormControl
						variant='standard'
						fullWidth
					>
						<InputLabel id='exportRange'>{'Choisir un type '}</InputLabel>
						<Select
							labelId='exportRange'
							label='Choisir un '
							value={clientInfos.rapportAutoInfos.exportRange}
							onChange={(event) => {
								setClientInfos({
									...clientInfos,
									rapportAutoInfos: {
										...clientInfos.rapportAutoInfos,
										exportRange: event.target.value as ExportRange
									}
								});
							}}
						>
							<MenuItem value='today'>Today</MenuItem>
							<MenuItem value='yesterday'>Yesterday</MenuItem>
							<MenuItem value='currentWeek'>CurrentWeek</MenuItem>
							<MenuItem value='lastWeek'>LastWeek</MenuItem>
							<MenuItem value='currentMonth'>CurrentMonth</MenuItem>
							<MenuItem value='lastMonth'>LastMonth</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid
					container
					flexDirection='column'
					alignContent='center'
					xs={5}
				>
					<TextField
						fullWidth
						id='exportFrequency'
						label='Fréquence d’export'
						autoComplete='exportFrequency'
						variant='standard'
						value={clientInfos.rapportAutoInfos.exportFrequency}
						placeholder='* * * * * (format cron job)'
						onChange={(event: ChangeEvent<HTMLInputElement>) => {
							setClientInfos({
								...clientInfos,
								rapportAutoInfos: {
									...clientInfos.rapportAutoInfos,
									exportFrequency: event.target.value as string
								}
							});
						}}
					/>
					<TextField
						fullWidth
						id='exportCustomIds'
						label='Custom Ids à exp'
						autoComplete='exportCustomIds'
						variant='standard'
						value={clientInfos.rapportAutoInfos.exportCustomIds}
						onChange={(event: ChangeEvent<HTMLInputElement>) => {
							setClientInfos({
								...clientInfos,
								rapportAutoInfos: {
									...clientInfos.rapportAutoInfos,
									exportCustomIds: event.target.value as string
								}
							});
						}}
					/>
				</Grid>

				<Typography mt={5}>
					<Link
						href='https://crontab.guru'
						underline='none'
						target='_blank'
					>
						LIEN VERS LE FORMAT CRON
					</Link>
				</Typography>
			</Grid>
		</Grid>
	);
}
