/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetBeaconOptionQuery } from '../../../api/fleetApiSlice';

/* SVG */
import LinkSvg from '../../../components/svg/LinkSvg';

/* TYPES */
import { DeviceBeaconOption } from '../../../types/Devices';

/* CSS */
import styled from 'styled-components';

const LogsSection = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	background-color: #222b32;
	color: #f2f2f2;
	border-radius: 10px;
	height: 50%;
	width: 50%;
`;

const LinkButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 30px;
	width: 180px;
	border-radius: 12px;
	background-color: inherit;
	border: solid #f2c94c 1px;
	cursor: pointer;
	color: #f2c94c;
	&:hover {
		transition: 0.2s;
		transform: scale(1.1);
	}
	&:focus,
	&:active,
	&:visited,
	&:link {
		color: #f2c94c;
	}

	a {
		color: #f2c94c;
		text-decoration: none;
	}
`;

type LogsProps = {
	deviceId: string;
};

function Logs({ deviceId }: LogsProps): ReactElement {
	const { data: beaconOptions } = useGetBeaconOptionQuery({ deviceId });

	const verifyBeaconOption = beaconOptions?.some(
		(element: DeviceBeaconOption) => element.beaconOption === 1
	);

	const beaconOptionUrl = verifyBeaconOption
		? '%0A%22%5C%22id%5C%22:385%22%20OR%20%22%5C%22id%5C%22:548%22'
		: '';

	return (
		<LogsSection>
			<h3>Journaux GCP</h3>
			<LinkButton>
				<LinkSvg
					height={15}
					width={15}
				/>
				<a
					href={`https://console.cloud.google.com/logs/query;query=logName%3D%22projects%2Fprimal-cascade-268515%2Flogs%2Fteltonika-devices%22%0A%22${deviceId}%22${beaconOptionUrl};timeRange=P1D;cursorTimestamp=2023-03-14T15:50:29.161999940Z?hl=fr&project=primal-cascade-268515&pli=1`}
					target='_blank'
					rel='noreferrer'
				>
					Acceder aux journaux
				</a>
			</LinkButton>
		</LogsSection>
	);
}

export default Logs;
