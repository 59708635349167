import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from './reducers/authSlice';
import devicesSlice from './reducers/devicesSlice';
import { fleetApiSlice } from '../api/fleetApiSlice';

const allReducers = combineReducers({
	authenticate: authSlice,
	selectedDeviceId: devicesSlice,
	[fleetApiSlice.reducerPath]: fleetApiSlice.reducer
});

const store = configureStore({
	reducer: allReducers,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(fleetApiSlice.middleware)
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
