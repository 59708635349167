/* REACT */
import { ReactElement, useState } from 'react';

/* REDUX */
import { useSendSmsCommandMutation } from '../../../api/fleetApiSlice';

/* CONTEXTS */
import { useToast } from '../../../contexts/ToastContext';

/* MUI */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* COMPONENT */
import LinkButton from '../../../components/button/LinkButton';

/* SVG */
import GetParamSvg from '../../../components/svg/GetParamSvg';
import IdSvg from '../../../components/svg/IdSvg';

/* STYLED COMPONENTS VARIABLES */
const SendSmsFormBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5%;
`;

/* TYPES */
import { FMBAndGHCommands } from '../../../types/Devices';
import SendSvg from '../../../components/svg/SendSvg';

type SendSmsFormProps = {
	smsCommandsList: FMBAndGHCommands[] | [];
	deviceId: string;
};

export default function SendSmsForm({ smsCommandsList, deviceId }: SendSmsFormProps): ReactElement {
	const [selectedCommand, setSelectedCommand] = useState<FMBAndGHCommands | null>(null);
	const [parameterValue, setParameterValue] = useState('');
	const [sendSms] = useSendSmsCommandMutation();
	const { showToast } = useToast();

	async function verifyParamaterAndSendSms() {
		setParameterValue('');

		const parameters = parameterValue;

		const splitParameters: string[] = parameters.split(
			selectedCommand ? selectedCommand.parameterSeparator : ';'
		);

		splitParameters.pop();

		if (!selectedCommand) {
			return showToast('Veuillez choisir une commande SMS', 'info');
		}

		if (selectedCommand.parameterNumber < splitParameters.length) {
			return showToast('Format des paramètres incorrect', 'info');
		}

		if (selectedCommand && selectedCommand.parameterNumber > 0 && !parameterValue) {
			return showToast('Veuillez saisir une ou plusieurs commandes', 'info');
		}

		let totalCommands = splitParameters.join(';');
		totalCommands += ';';

		const finalCommandToSend = selectedCommand.command + totalCommands;

		try {
			const sendSmsresult = await sendSms({
				deviceId,
				smsCommand: finalCommandToSend
			}).unwrap();

			showToast(sendSmsresult.message, 'success');
		} catch {
			showToast('Failed to send message', 'error');
		}
	}

	return (
		<>
			<SendSmsFormBox>
				<GetParamSvg
					width={18}
					height={18}
				/>
				<Autocomplete
					id='dashboard-autocomplete'
					value={selectedCommand}
					options={smsCommandsList ? smsCommandsList : []}
					getOptionLabel={(option: FMBAndGHCommands) => `${option.command}`}
					onChange={(event, newValue) => setSelectedCommand(newValue)} // newValue represente la valeur selectionne dans la liste
					sx={{
						width: 200,
						outline: 'none',
						border: 'none',
						color: 'white'
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant='filled'
							placeholder='Entrez votre commande'
							sx={{ input: { color: 'white' } }}
						/>
					)}
					renderOption={(props, option, state) => (
						<li
							{...props}
							key={state.index}
						>
							{option.command}
						</li>
					)}
				/>
			</SendSmsFormBox>
			{selectedCommand && (
				<>
					{selectedCommand.parameterNumber > 0 && (
						<Input
							value={parameterValue}
							onChange={(event) => setParameterValue(event.target.value)}
							placeholder='Entrez vos paramètres'
							sx={{
								color: 'white'
							}}
							id='input-with-icon-adornment'
							startAdornment={
								<InputAdornment position='start'>
									<IdSvg
										width={17}
										height={17}
									/>
								</InputAdornment>
							}
						/>
					)}

					<span onClick={verifyParamaterAndSendSms}>
						<LinkButton
							width={250}
							disabled={false}
						>
							<SendSvg
								height={15}
								width={15}
							/>
							Send SMS Command
						</LinkButton>
					</span>
				</>
			)}
		</>
	);
}
