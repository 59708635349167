/* STYLED COMPONENT */
import styled, { css } from 'styled-components';

/* TYPES */
type LinkButtonProps = React.PropsWithChildren<{
	width?: number;
	height?: number;
	color?: string;
	disabled: boolean;
}>;

/* STYLED COMPONENT VARIABLES */
const StyledLinkButton = styled.button<LinkButtonProps>`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: ${(props) => props.height || 30}px;
	width: ${(props) => props.width || 180}px;
	border-radius: 12px;
	background-color: inherit;
	border: solid #f2c94c 1px;
	cursor: pointer;
	color: ${(props) => props.color || '#f2c94c'};
	&:hover {
		transition: 0.2s;
		transform: scale(1.1);
	}
	&:focus,
	&:active,
	&:visited,
	&:link {
		${(props) => props.color || '#f2c94c'};
	}
	${(props) =>
		props.disabled &&
		css`
			cursor: not-allowed;
			cursor: no-drop;
		`}
`;

export default function LinkButton({ children, width, height, color, disabled }: LinkButtonProps) {
	return (
		<StyledLinkButton
			width={width}
			height={height}
			color={color}
			disabled={disabled}
		>
			{children}
		</StyledLinkButton>
	);
}
