/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetPreviousDayGpsPointsQuery } from '../../../api/fleetApiSlice';

/* SVG */
import GpsPointSvg from '../../../components/svg/GpsPointSvg';
import FalseSvg from '../../../components/svg/CrossSvg';
import ConfirmSvg from '../../../components/svg/ConfirmSvg';

/* CSS */
import styled from 'styled-components';

const GpsPositionDaysSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 10%;
    min-width: 28%;
    height: 75px;
    background: #222b32;
    font-size: 0.7rem;
    color: white;
    border-radius: 10px;
    padding 0 15px;
    text-align: center;
`;

function GpsPositionDays({ deviceId }: { deviceId: string }): ReactElement {
	const { data: gpsPointPreviousDay } = useGetPreviousDayGpsPointsQuery({ deviceId });

	function displayCountPosition(gpsDatas = gpsPointPreviousDay) {
		if (gpsDatas) {
			return (
				<>
					<div>
						<p style={{ fontWeight: 'bold' }}>GPS Position J-1</p>
						{gpsDatas[0].previousDayGpsPointsNumber >= 24 ? (
							<p>{`${gpsDatas[0].previousDayGpsPointsNumber} positions`}</p>
						) : (
							<p>Aucune position</p>
						)}
					</div>
					{gpsDatas[0].previousDayGpsPointsNumber >= 24 ? (
						<ConfirmSvg
							width={35}
							height={35}
						/>
					) : (
						<FalseSvg
							width={35}
							height={35}
						/>
					)}
				</>
			);
		} else {
			return (
				<>
					<div>
						<p style={{ fontWeight: 'bold' }}>GPS Position J-1</p>
						<p>Nombres de positions GPS indisponible</p>
					</div>

					<FalseSvg
						width={35}
						height={35}
					/>
				</>
			);
		}
	}

	return (
		<GpsPositionDaysSection>
			<GpsPointSvg
				height={30}
				width={30}
			/>
			{displayCountPosition()}
		</GpsPositionDaysSection>
	);
}

export default GpsPositionDays;
