/* REACT */
import type { ReactElement } from 'react';

/* REACT TOASTIFY */
import { ToastContainer } from 'react-toastify';

/* COMPONENTS */
import SmsForm from './send-sms-form/SmsForm';

/* CSS */
import { Theme } from '../../styles/Theme';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const SendSmsSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	height: auto;
	width: 100%;
	position: absolute;
	right: 0;
	background-color: ${Theme.dark.background.primary};
`;

/* COMPONENT qui est une section qui contient le formulaire de liaison d'un device à une ou plusieurs third parties */
export default function DevicesLink(): ReactElement {
	/* Render */
	return (
		<SendSmsSection>
			<SmsForm />
			<ToastContainer />
		</SendSmsSection>
	);
}
