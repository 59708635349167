type DeviceModelSvgProps = {
	width: number;
	height: number;
};

function DeviceModelSvg({ width, height }: DeviceModelSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
		>
			<path
				d='M14.8585 3.43096C14.1383 2.78434 12.1445 1.28138 9.25472 1.20274C6.10148 1.1241 3.93199 2.76686 3.14148 3.47465C3.04486 3.57077 2.99216 3.70184 3.00095 3.83291C3.00973 3.96398 3.08 4.08632 3.19418 4.16496L8.64867 7.73011C8.71893 7.7738 8.80677 7.80001 8.88582 7.80001C8.96487 7.80001 9.0527 7.7738 9.12297 7.73011L14.797 4.12127C14.9112 4.05136 14.9903 3.92903 14.9991 3.78922C15.0078 3.64941 14.9551 3.51834 14.8497 3.43096H14.8585ZM13.4707 4.10379C13.339 4.26982 13.1018 4.29603 12.935 4.1737C12.2674 3.65815 10.8709 2.80181 8.90338 2.83677C7.03252 2.87172 5.68866 3.71058 5.05626 4.20865C4.98599 4.26108 4.90694 4.28729 4.81911 4.28729C4.70492 4.28729 4.59952 4.23486 4.52047 4.14748C4.38872 3.98146 4.41507 3.74553 4.58196 3.61446C5.29341 3.05522 6.78659 2.12024 8.88582 2.07655C11.0729 2.03286 12.6539 2.99405 13.4005 3.57077C13.5674 3.70184 13.5937 3.93777 13.4707 4.10379Z'
				fill='white'
				fillOpacity='0.8'
			/>
			<path
				d='M10.6747 10.2H6.72533C5.99338 10.2 5.40002 10.791 5.40002 11.52V15.48C5.40002 16.209 5.99338 16.8 6.72533 16.8H10.6747C11.4067 16.8 12 16.209 12 15.48V11.52C12 10.791 11.4067 10.2 10.6747 10.2Z'
				fill='white'
				fillOpacity='0.8'
			/>
		</svg>
	);
}

export default DeviceModelSvg;
