/* REACT */
import { type ChangeEvent, type ReactElement } from 'react';

/* CONTEXT */
import { useNewClientContext } from 'contexts/NewClientContext';

/* MUI */
import { Button, Grid, TextField } from '@mui/material';

/* LIBRARIES */
import generatePassword from 'omgopass';

/* COMPONENT */
export default function ClientInformations(): ReactElement {
	/* Hooks */
	const { clientInfos, register, setClientInfos, errors, setError, clearErrors } =
		useNewClientContext();

	/* Functions */
	function changeNewClientInfos(event: ChangeEvent<HTMLInputElement>) {
		setClientInfos({
			...clientInfos,
			[event.target.name]: event.target.value
		});

		if (event.target.name === 'lastname') {
			if (!/^[a-zA-Z\s\W]{1,45}$/.test(event.target.value)) {
				setError('lastname', {
					type: 'manual',
					message: 'Le nom ne doit pas dépasser 45 caractères.'
				});
			} else {
				clearErrors('lastname');
			}
		} else if (event.target.name === 'firstname') {
			if (!/^[a-zA-Z\s\W]{1,45}$/.test(event.target.value)) {
				setError('firstname', {
					type: 'manual',
					message: 'Le prénom ne doit pas dépasser 45 caractères.'
				});
			} else {
				clearErrors('firstname');
			}
		} else if (event.target.name === 'name') {
			if (!/^[a-zA-Z\s\W0-9]{1,255}$/.test(event.target.value)) {
				setError('name', {
					type: 'manual',
					message: "Le nom de l'entreprise ne doit pas dépasser 255 caractères."
				});
			} else {
				clearErrors('name');
			}
		} else if (event.target.name === 'location') {
			if (!/^[\p{L}0-9\s'.,]{1,255}$/u.test(event.target.value)) {
				setError('location', {
					type: 'manual',
					message:
						"L'adresse ne doit pas contenir de caractères spéciaux (sauf .,-) et ne doit pas dépasser 255 caractères."
				});
			} else {
				clearErrors('location');
			}
		} else if (event.target.name === 'phone') {
			if (!/^\+?[0-9\s]{10,20}$/.test(event.target.value)) {
				setError('phone', {
					type: 'manual',
					message:
						'Le numéro de téléphone ne doit pas contenir de caractères spéciaux, doit contenir minimum 10 caractères et maximum 20 caractères.'
				});
			} else {
				clearErrors('phone');
			}
		} else if (event.target.name === 'email') {
			if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/i.test(event.target.value)) {
				setError('email', {
					type: 'manual',
					message: "Le format de l'adresse mail est incorrecte."
				});
			} else {
				clearErrors('email');
			}
		}
	}

	function generatedPassword(): void {
		setClientInfos({
			...clientInfos,
			password: generatePassword({
				syllablesCount: 2,
				minSyllableLength: 4,
				maxSyllableLength: 4,
				vowels: '0123456789',
				hasNumbers: false
			})
		});
	}

	/* Render */
	return (
		<Grid
			container
			spacing={5}
		>
			<Grid
				item
				xs={12}
				sm={6}
			>
				<TextField
					required
					error={Boolean(errors.lastname)}
					helperText={errors.lastname ? errors.lastname.message : ''}
					id='lastname'
					name='lastname'
					label='Nom'
					fullWidth
					autoComplete='lastname'
					variant='standard'
					value={clientInfos.lastname}
					{...register('lastname', {
						required: true,
						maxLength: 45,
						pattern: /^[a-zA-Z\s\W]{1,45}$/
					})}
					onChange={changeNewClientInfos}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
			>
				<TextField
					required
					error={Boolean(errors.firstname)}
					helperText={errors.firstname ? errors.firstname.message : ''}
					fullWidth
					id='firstname'
					label='Prénom'
					autoComplete='firstname'
					variant='standard'
					value={clientInfos.firstname}
					{...register('firstname', {
						required: true,
						maxLength: 45,
						pattern: /^[a-zA-Z\s\W]{1,45}$/
					})}
					onChange={changeNewClientInfos}
				/>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<TextField
					required
					error={Boolean(errors.name)}
					helperText={errors.name ? errors.name.message : ''}
					fullWidth
					id='name'
					label="Nom de l'entreprise"
					autoComplete='name'
					variant='standard'
					value={clientInfos.name}
					{...register('name', {
						required: true,
						maxLength: 255,
						pattern: /^[a-zA-Z0-9\s'.,-]{1,255}$/
					})}
					onChange={changeNewClientInfos}
				/>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<TextField
					required
					error={Boolean(errors.location)}
					helperText={errors.location ? errors.location.message : ''}
					fullWidth
					id='location'
					label="Adresse de l'entreprise"
					autoComplete='location'
					variant='standard'
					value={clientInfos.location}
					{...register('location', {
						required: true,
						maxLength: 255,
						pattern: /^[a-zA-Z0-9\s'.,-]{1,255}$/
					})}
					onChange={changeNewClientInfos}
				/>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<TextField
					required
					error={Boolean(errors.phone)}
					helperText={errors.phone ? errors.phone.message : ''}
					fullWidth
					id='phone'
					label='Numéro de téléphone'
					autoComplete='phone'
					variant='standard'
					value={clientInfos.phone}
					{...register('phone', { required: true, maxLength: 45, pattern: /^\+?[0-9]{6,45}$/ })}
					onChange={changeNewClientInfos}
				/>
			</Grid>
			<Grid
				item
				xs={16}
				sm={5}
			>
				<TextField
					required
					error={Boolean(errors.email)}
					helperText={errors.email ? errors.email.message : ''}
					fullWidth
					id='email'
					label='Adresse email'
					autoComplete='email'
					variant='standard'
					value={clientInfos.email}
					{...register('email', {
						required: true,
						maxLength: 45,
						pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/i
					})}
					onChange={changeNewClientInfos}
				/>
			</Grid>
			<Grid
				item
				xs={6}
				sm={4}
			>
				<TextField
					required
					error={Boolean(errors.password)}
					helperText={errors.password ? errors.password.message : ''}
					fullWidth
					id='password'
					autoComplete='off'
					type='text'
					label='Mot de passe'
					variant='standard'
					value={clientInfos.password}
					{...register('password', {
						required: true,
						maxLength: 255
					})}
				/>
			</Grid>
			<Grid
				item
				xs={6}
				sm={3}
			>
				<Button
					onClick={generatedPassword}
					size='small'
				>
					Générer un mot de passe
				</Button>
			</Grid>
		</Grid>
	);
}
