/* REACT */
import { ReactElement, useState, useEffect } from 'react';

/* MUI */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

/* TYPES */
import { Devices } from '../../../types/Devices';

/* REDUX */
import { useDispatch } from 'react-redux';
import {
	getSelectedDeviceId,
	getSelectedThirdPartyId,
	getSelectedThirdPartyName
} from '../../../store/reducers/devicesSlice';
import { useGetAllDevicesQuery } from '../../../api/fleetApiSlice';

/* LIBRARIES */
import Cookies from 'universal-cookie';

/* Composant qui permet de rechercher un device */
function SearchBar(): ReactElement {
	/* States */
	const [selectedValue, setSelectedValue] = useState<Devices | null>(null);

	/* Hooks */
	const { data: devices, isSuccess } = useGetAllDevicesQuery();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSelectedDeviceId(selectedValue?.deviceId));
		dispatch(getSelectedThirdPartyId(selectedValue?.thirdPartyId));
		dispatch(getSelectedThirdPartyName(selectedValue?.thirdPartyName));

		const cookies = new Cookies(null, { path: '/' });
		cookies.remove('refreshToken');
	}, [selectedValue]);

	/* Render */
	return (
		<Autocomplete
			id='dashboard-autocomplete'
			value={selectedValue}
			options={isSuccess ? devices : []}
			getOptionLabel={(option: Devices) =>
				`${option.thirdPartyDeviceName} (${option.thirdPartyName}) / ${option.immatriculation} / ${option.deviceId} /${option.thirdPartyId}`
			}
			onChange={(_, newValue) => setSelectedValue(newValue)} // newValue represente la valeur selectionne dans la liste
			sx={{ width: 420, backgroundColor: '#f2c94c' }}
			renderInput={(params) => (
				<TextField
					{...params}
					label='Recherche par IMEI, entreprise ou immatriculation'
				/>
			)}
			renderOption={(props, option, state) => (
				<li
					{...props}
					key={state.index}
				>
					{option.label}
				</li>
			)}
		/>
	);
}
export default SearchBar;
