export const ALL_DEVICES_TYPE = [
	'FMB001',
	'FMB010',
	'FMB002',
	'FMB020',
	'FMB003',
	'FMB110',
	'FMB120',
	'FMB122',
	'FMB125',
	'FMB130',
	'FMB202',
	'FMB204',
	'FMB208',
	'FMB900',
	'FMB920',
	'FMB962',
	'FMB964',
	'FM3001',
	'FMT100',
	'FMC001',
	'FMC003',
	'FMC00A',
	'FMM001',
	'FMM003',
	'FMM00A',
	'FMC125',
	'FMC130',
	'FMM125',
	'FMM130',
	'FMU125',
	'FMU126',
	'FMU130',
	'MTB100',
	'FMB140',
	'TMT250',
	'GH5200',
	'TST100',
	'TFT100',
	'TAT100',
	'FMC880',
	'FTC881'
] as const;

export const ALL_VEHICLE_TYPE = [
	'voiture (car)',
	'camionnette (van)',
	'camion (truck)',
	'benne (dumpster)',
	'camion benne (truck_dumpster)',
	'bus (bus)',
	'remorque (trailer)',
	'entrepot (warehouse)',
	'badgeuse (clocking)',
	'courreur (human)',
	'brise-roche hydraulique (brh)',
	'broyeur (crusher)',
	'dumper (dumper)',
	'pelleteuse (excavator)',
	'chargeuse (loader)',
	'nacelle (manlift)',
	'rouleau compacteur (roller_compactor)',
	'compacteur pied de mouton (sheepsfoot_compactor)',
	'Pilonneuse (tamping_rammer)',
	'télescopique (telescopic_boom_lift)',
	'plaque vibrante (vibratory_plate_compactor)',
	'compresseur (compressor)',
	'balayeuse (street_sweepers)',
	'laveuse (street_cleaning)',
	'saleuse (salt_spreader)'
] as const;
