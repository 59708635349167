/* REACT */
import { ReactElement, useEffect } from 'react';

/* COMPONENTS */
import DeviceState from './DeviceState';
import DeviceModel from './DeviceModel';
import DeviceImmatriculation from './DeviceImmatriculation';
import DeviceName from './DeviceName';
import DeviceSim from './DeviceSim';

/* STYLES */
import { Theme } from 'styles/Theme';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const SmsDevicesBox = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-auto-rows: auto;
	gap: 10px 10px;
	height: auto;
	width: 95%;
	font-size: 0.8rem;
`;

interface DevicesBoxProps {
	isSelected: boolean;
}

const DevicesBox = styled.div<DevicesBoxProps>`
	display: flex;
	flex-direction: column;
	height: 160px;
	width: 180px;
	background-color: ${Theme.dark.background.secondary};
	border-radius: 10px;
	color: white;
	border: 1px solid ${({ isSelected }) => (isSelected ? 'gold' : Theme.dark.background.secondary)};
	cursor: pointer;
`;

/* TYPES */
import { Devices } from 'types/Devices';

interface SmsFormDevicesProps {
	devices: string[];
	setDevices: (devices: string[]) => void;
	selectedValue: string;
	tpDevices: Devices[];
	isChecked: boolean;
}

/* Composant qui regroupe la commande SMS ainsi que la checkbox de validation et d'envoi du formulaire */
export default function SmsFormDevices({
	devices,
	setDevices,
	selectedValue,
	tpDevices = [],
	isChecked
}: SmsFormDevicesProps): ReactElement {
	/* Function */
	function handleClick(deviceId: string) {
		if (!devices.includes(deviceId)) {
			setDevices([...devices, deviceId]);
		} else {
			setDevices(devices.filter((device) => device !== deviceId));
		}
	}

	/* Variables */
	const filteredDevices = selectedValue
		? tpDevices.filter(
				(device) =>
					device.deviceId.includes(selectedValue) ||
					(device.immatriculation && device.immatriculation.includes(selectedValue)) ||
					(device.thirdPartyDeviceName && device.thirdPartyDeviceName.includes(selectedValue))
		  )
		: tpDevices;

	useEffect(() => {
		if (isChecked) {
			setDevices(tpDevices.map((device) => device.deviceId));
		} else {
			setDevices([]);
		}
	}, [isChecked, tpDevices]);

	/* Render */
	return (
		<SmsDevicesBox>
			{filteredDevices.map((device) => {
				const isSelected = devices.includes(device.deviceId);

				return (
					<DevicesBox
						key={device.deviceId}
						onClick={() => handleClick(device.deviceId)}
						isSelected={isSelected}
					>
						<DeviceState deviceId={device.deviceId} />
						<DeviceImmatriculation
							immatriculation={device.immatriculation ?? 'No immatriculation'}
						/>
						<DeviceName name={device.thirdPartyDeviceName ?? 'No name'} />
						<DeviceModel deviceId={device.deviceId} />
						<DeviceSim deviceId={device.deviceId} />
					</DevicesBox>
				);
			})}
		</SmsDevicesBox>
	);
}
