import React from 'react';

/* HOOKS */
import { useNewClientContext } from 'contexts/NewClientContext';

/* STYLES COMPONENTS */
import { StyledTableCell } from './StylesTableReview';

/* MATERIAL UI */
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography
} from '@mui/material';

/* STYLES */
function ReviewRapportForm(): React.ReactElement {
	/* Hooks */
	const privateModeInfos = useNewClientContext().clientInfos.privateModeInfos;

	return (
		<TableContainer
			component={Paper}
			sx={{
				marginTop: 4
			}}
		>
			<Typography
				variant='h6'
				id='tableTitle'
			>
				Récapitulatif du mode privé
			</Typography>
			<Table
				sx={{ minWidth: 650 }}
				aria-label='simple table'
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align='right'>
							Jour et heure d&lsquo;activation du mode privé
						</StyledTableCell>
						<StyledTableCell align='right'>
							Jour et heure de désactivation du mode privé
						</StyledTableCell>
						<StyledTableCell align='right'>
							Afficher les distances des trajets privés
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow
						key={privateModeInfos.privateStart}
						sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
					>
						<TableCell align='right'>{privateModeInfos.privateStart}</TableCell>
						<TableCell align='right'>{privateModeInfos.privateEnd}</TableCell>
						<TableCell align='right'>
							{privateModeInfos.distanceOption ? 'ACTIVER' : 'DESACTIVER'}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ReviewRapportForm;
