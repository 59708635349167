/* REACT */
import { ReactElement } from 'react';

/* TYPES */
import { APIFotaDeviceAndSimInformations } from '../../../types/Devices';

type DeviceModelProps = {
	deviseSimInfos: APIFotaDeviceAndSimInformations;
};

/* STYLED COMPONENTS */
import styled from 'styled-components';

const ModelDeviceBox = styled.div``;

const ModelDevice = styled.p`
	font-weight: bold;
`;

const FirmwareText = styled.p``;

/* COMPONENTS */
function DeviceModel({ deviseSimInfos }: DeviceModelProps): ReactElement {
	return (
		<ModelDeviceBox>
			<ModelDevice>{(deviseSimInfos && deviseSimInfos?.model) ?? 'Modèle inconnu'}</ModelDevice>
			<FirmwareText>FW</FirmwareText>
		</ModelDeviceBox>
	);
}

export default DeviceModel;
