/* REACT */
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

/* REDUX */
import { useDispatch } from 'react-redux';
import { login } from '../../store/reducers/authSlice';

/* REACT-HOOK-FORM */
import { useForm } from 'react-hook-form';

/* HELPERS */
import LoginAPI from '../../api/login.api';

/* MUI */
import { TextField, Button, inputLabelClasses, Alert } from '@mui/material';

/* CSS */
import { Theme } from '../../styles/Theme';

/* TYPES */
import { Authentication } from '../../types/User';

type DataForm = {
	email: string;
	password: string;
};

function LoginForm(): ReactElement {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		register,
		setError,
		formState: { errors },
		handleSubmit
	} = useForm<DataForm>();

	/* Cette regex utilise les classes de caractères pour correspondre à toutes les lettres majuscules et minuscules, tous les chiffres,
	ainsi que les caractères spéciaux autorisés dans les adresses e-mail.
	Il vérifie également que le domaine de l'adresse e-mail contient au moins deux lettres après le point final.
	*/
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

	function handleSubmitForm(data: DataForm) {
		if (emailRegex.test(data.email)) {
			LoginAPI(data.email, data.password)
				.then((result: Authentication) => {
					if (result.data.user.type.includes('admin')) {
						sessionStorage.setItem('token', result.data.token);
						dispatch(login(result.data.token));
						navigate('/');
						window.location.reload();
					} else {
						setError('email', { types: { incorrect: 'identifiant ou mot de passe incorrecte' } });
					}
				})
				.catch(() => {
					setError('email', { types: { incorrect: 'identifiant ou mot de passe incorrecte' } });
				});
		} else {
			setError('email', { types: { incorrect: 'Les caractères spéciaux ne sont pas autorisés' } });
		}
	}

	return (
		<form
			style={{
				height: '30%',
				width: '30%',
				display: 'flex',
				flexDirection: 'column',
				gap: 15
			}}
			onSubmit={handleSubmit(handleSubmitForm)}
		>
			<TextField
				id='email'
				label="Nom d'utilisateur"
				variant='standard'
				type='text'
				sx={{ input: { color: `${Theme.dark.text.primary}` } }}
				InputLabelProps={{
					sx: {
						// set the color of the label when not shrinked
						color: `${Theme.dark.text.primary}`,
						[`&.${inputLabelClasses.shrink}`]: {
							// set the color of the label when shrinked (usually when the TextField is focused)
							color: `${Theme.dark.text.primary}`
						}
					}
				}}
				{...register('email', { required: true })}
			/>
			<TextField
				id='password'
				label='Mot de passe'
				variant='standard'
				type='password'
				sx={{ input: { color: `${Theme.dark.text.primary}` } }}
				InputLabelProps={{
					sx: {
						// set the color of the label when not shrinked
						color: `${Theme.dark.text.primary}`,
						[`&.${inputLabelClasses.shrink}`]: {
							// set the color of the label when shrinked (usually when the TextField is focused)
							color: `${Theme.dark.text.primary}`
						}
					}
				}}
				{...register('password', { required: true })}
			/>
			<Button
				variant='contained'
				sx={{
					backgroundColor: `${Theme.primary}`,
					color: 'black',
					borderRadius: 5
				}}
				type='submit'
				onClick={() => <p>{'Identifiant ou mot de passe incorrect.'}</p>}
			>
				{"S'identifier"}
			</Button>
			{errors.email && errors.email.types && (
				<Alert severity='error'>{errors.email.types.incorrect}</Alert>
			)}
		</form>
	);
}

export default LoginForm;
