type ObdSvgProps = {
	width: number;
	height: number;
};

function ObdSvg({ width, height }: ObdSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 40 20'
			fill='none'
		>
			<path
				d='M0.141729 6.57113C-0.574749 3.88945 1.51327 0.777891 4.42013 0.163766C4.87048 0.0614124 5.34131 0 5.79167 0C15.0445 0 24.2973 0 33.5296 0C36.6412 0 38.9953 2.12896 39.3433 5.17911C39.3638 5.42476 39.4047 5.69088 39.3024 5.93653C38.7292 6.46877 38.0741 6.30501 37.4395 6.1003C37.112 5.93653 37.112 5.609 37.0711 5.32241C36.7231 3.35721 35.5357 2.35414 33.5091 2.33367C24.3177 2.33367 15.1059 2.33367 5.9145 2.33367C3.86742 2.33367 2.6187 3.50051 2.39352 5.50665C2.35258 5.77277 2.39352 6.07983 2.18881 6.30501C1.55421 6.71442 0.858207 6.57113 0.1622 6.55066L0.141729 6.57113Z'
				fill='#F2C94C'
			/>
			<path
				d='M36.0476 16.1716C35.5768 17.1747 34.9013 18.0345 34.0006 18.6895C32.7928 19.5698 31.4212 19.9997 29.9269 19.9997C23.0691 19.9997 16.2319 19.9997 9.37418 19.9997C6.81533 19.9997 4.80919 18.8943 3.51953 16.6425C3.62189 16.1921 3.96989 16.1716 4.33836 16.1307C4.78872 16.0898 5.23908 16.2535 5.68944 16.1512C6.01697 16.1921 6.22168 16.4173 6.46733 16.622C7.38851 17.3794 8.45299 17.7684 9.6403 17.7684C16.3752 17.7684 23.1101 17.7684 29.8655 17.7684C31.1756 17.7684 32.3015 17.2975 33.2636 16.3968C33.5093 16.1512 33.714 15.8441 34.1029 15.7622C34.5533 15.7622 35.0036 15.6189 35.4745 15.6803C35.7815 15.7213 36.109 15.7622 36.109 16.1716H36.0476Z'
				fill='#F2C94C'
			/>
			<path
				d='M36.0469 16.1722C35.4123 15.8856 34.7368 16.0698 34.0817 16.0084C33.8361 15.8037 33.8975 15.558 33.9998 15.3124C34.573 13.9408 34.962 12.4874 35.4737 11.0954C35.5966 10.7474 35.6784 10.338 36.1493 10.2766C36.5587 10.1742 36.9476 10.1128 37.357 10.1947C37.6436 10.2356 37.9098 10.3175 37.9098 10.686C37.3366 12.5284 36.8657 14.4117 36.0469 16.1722Z'
				fill='#F2C94C'
			/>
			<path
				d='M5.67149 16.4791C4.95502 16.52 4.21807 16.3563 3.52206 16.6429C2.92841 15.8445 2.74417 14.9028 2.45758 13.9817C2.19146 13.1424 1.92534 12.3031 1.67969 11.4433C2.10957 10.604 2.80558 10.9725 3.44018 11.0543C3.911 11.1362 4.07477 11.5252 4.1976 11.8936C4.66842 13.2242 4.95502 14.6163 5.69196 15.8445C5.81479 16.0492 5.85573 16.2744 5.69196 16.4791H5.67149Z'
				fill='#F2C94C'
			/>
			<path
				d='M3.52221 11.2796C2.90809 11.3001 2.27349 11.1772 1.65937 11.4434C1.08619 9.84663 0.472064 8.24991 0.144531 6.5713C0.656301 5.93671 1.35231 6.36659 1.96643 6.24377C2.31444 6.28471 2.37585 6.59177 2.4782 6.83742C2.94903 7.98379 3.31751 9.17109 3.70645 10.3584C3.8088 10.6859 3.97257 11.0544 3.54268 11.3001L3.52221 11.2796Z'
				fill='#F2C94C'
			/>
			<path
				d='M37.914 10.7062C37.3203 10.3991 36.6857 10.5834 36.0921 10.5219C35.8464 10.3787 35.8669 10.133 35.9078 9.90782C36.174 8.63863 36.6448 7.45133 37.0951 6.24355C37.1566 6.05931 37.2794 5.95696 37.4841 5.91602C38.0982 5.91602 38.6919 5.91602 39.306 5.91602C39.0808 7.57415 38.5281 9.12993 37.9344 10.6652L37.914 10.7062Z'
				fill='#F2C94C'
			/>
			<path
				d='M19.5713 10.8499C15.3952 10.8499 11.2396 10.8499 7.06359 10.8499C6.83841 10.8499 6.59277 10.8499 6.36759 10.8499C5.81487 10.809 5.52828 10.5224 5.50781 9.96969C5.50781 9.41698 5.81487 9.10991 6.32664 9.02803C6.55182 8.98709 6.777 9.00756 7.02265 9.00756C15.3543 9.00756 23.6859 9.00756 32.038 9.00756C33.1229 9.00756 33.5733 9.27368 33.5938 9.92875C33.5938 10.5838 33.1843 10.8499 32.0789 10.8499C27.9029 10.8499 23.7473 10.8499 19.5713 10.8499Z'
				fill='#F2C94C'
			/>
			<path
				d='M20.5511 14.391C20.5511 14.6366 20.5511 14.9028 20.4897 15.1484C20.3669 15.5783 20.0598 15.8239 19.589 15.8444C19.1591 15.8444 18.8316 15.6397 18.7087 15.2303C18.5245 14.6571 18.545 14.0635 18.7087 13.5107C18.8316 13.0809 19.1591 12.8352 19.6299 12.8557C20.1212 12.8761 20.4283 13.1627 20.5102 13.654C20.5511 13.8997 20.5102 14.1658 20.5102 14.4115C20.5102 14.4115 20.5306 14.4115 20.5511 14.4115V14.391Z'
				fill='#F2C94C'
			/>
			<path
				d='M13.3828 14.3093C13.3828 14.1046 13.3828 13.8999 13.3828 13.6952C13.4442 13.1629 13.7513 12.8559 14.2835 12.8354C14.8158 12.8149 15.1638 13.1424 15.2457 13.6337C15.3071 14.0227 15.3071 14.4526 15.2661 14.8415C15.2252 15.4147 14.9386 15.8241 14.3245 15.8241C13.6694 15.8241 13.4033 15.3738 13.3828 14.7596C13.3828 14.6163 13.3828 14.4526 13.3828 14.3093Z'
				fill='#F2C94C'
			/>
			<path
				d='M20.5086 5.50646C20.5086 5.73164 20.529 5.95682 20.5086 6.182C20.4267 6.65283 20.1606 6.98036 19.6488 7.00083C19.1166 7.0213 18.7481 6.77565 18.6662 6.24341C18.6048 5.81353 18.6048 5.38364 18.6457 4.95375C18.6867 4.38057 19.0142 3.97115 19.6283 4.0121C20.222 4.05304 20.529 4.44198 20.529 5.03564C20.529 5.17893 20.529 5.3427 20.529 5.48599L20.5086 5.50646Z'
				fill='#F2C94C'
			/>
			<path
				d='M13.3672 5.52727C13.3672 5.32256 13.3672 5.11785 13.3672 4.91315C13.4286 4.40138 13.7357 4.05337 14.2474 4.01243C14.7797 3.97149 15.1277 4.27855 15.2096 4.79032C15.271 5.17927 15.2914 5.58868 15.2505 5.9981C15.2096 6.59175 14.9025 7.02164 14.2474 7.00117C13.6128 6.9807 13.3877 6.55081 13.3672 5.97763C13.3672 5.83433 13.3672 5.67057 13.3672 5.52727Z'
				fill='#F2C94C'
			/>
			<path
				d='M9.98853 5.54739C9.98853 5.75209 9.98853 5.9568 9.98853 6.16151C9.94759 6.69375 9.64053 7.00081 9.10829 7.02128C8.53511 7.02128 8.20758 6.71422 8.16663 6.14104C8.12569 5.77256 8.16663 5.38362 8.16663 5.01514C8.16663 4.42149 8.49417 4.03255 9.10829 4.05302C9.70194 4.05302 10.0295 4.42149 10.0295 5.03562C10.0295 5.21985 10.0295 5.38362 10.0295 5.56786L9.98853 5.54739Z'
				fill='#F2C94C'
			/>
			<path
				d='M23.9102 14.35C23.9102 13.2855 24.1763 12.8146 24.8313 12.8351C25.4864 12.8351 25.773 13.3059 25.773 14.3704C25.773 14.5547 25.773 14.7184 25.773 14.9027C25.7321 15.4963 25.4045 15.8648 24.7904 15.8443C24.2377 15.8238 23.9306 15.4758 23.9102 14.9027C23.9102 14.7184 23.9102 14.5547 23.9102 14.3704V14.35Z'
				fill='#F2C94C'
			/>
			<path
				d='M31.0114 14.3091C31.0114 15.4145 30.7657 15.8239 30.1106 15.8239C29.4351 15.8239 29.1485 15.3736 29.169 14.3091C29.169 14.1658 29.169 14.002 29.169 13.8587C29.1895 13.2446 29.4965 12.8147 30.1516 12.8352C30.7657 12.8352 31.0318 13.2446 31.0318 13.8587C31.0318 14.002 31.0318 14.1658 31.0318 14.3091H31.0114Z'
				fill='#F2C94C'
			/>
			<path
				d='M25.7731 5.48618C25.7731 6.57113 25.5274 7.00102 24.8724 7.02149C24.1968 7.02149 23.9102 6.5916 23.9307 5.46571C23.9307 5.32241 23.9307 5.15865 23.9307 5.01535C23.9307 4.40123 24.2582 4.05322 24.8519 4.05322C25.4046 4.05322 25.7321 4.40123 25.7731 4.97441C25.7731 5.15865 25.7731 5.32241 25.7731 5.50665V5.48618Z'
				fill='#F2C94C'
			/>
			<path
				d='M9.98428 14.3291C9.98428 14.5338 9.98428 14.7385 9.98428 14.9432C9.94334 15.5164 9.63628 15.844 9.0631 15.844C8.48991 15.844 8.18285 15.496 8.14191 14.9432C8.12144 14.5952 8.14191 14.2268 8.14191 13.8788C8.14191 13.2442 8.46944 12.8348 9.12451 12.8552C9.71816 12.8757 10.0048 13.2851 10.0048 13.8788C10.0048 14.0221 10.0048 14.1858 10.0048 14.3291H9.98428Z'
				fill='#F2C94C'
			/>
			<path
				d='M31.0113 5.48614C31.0113 6.57109 30.7452 7.02145 30.0901 7.02145C29.435 7.02145 29.1484 6.57109 29.1484 5.48614C29.1484 5.28143 29.1484 5.07673 29.1484 4.87202C29.2303 4.33978 29.5579 4.03271 30.0696 4.03271C30.6223 4.03271 30.9703 4.36025 30.9703 4.9539C30.9703 5.13814 30.9703 5.3019 30.9703 5.48614H31.0113Z'
				fill='#F2C94C'
			/>
		</svg>
	);
}

export default ObdSvg;
