type IccidSvgProps = {
	width: number;
	height: number;
};

function IccidSvg({ width, height }: IccidSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 19 25'
			fill='none'
		>
			<path
				d='M2.35 0.5H16.45C17.4726 0.5 18.3 1.32678 18.3 2.34375V22.6562C18.3 23.6733 17.473 24.5 16.45 24.5H2.35C1.32707 24.5 0.5 23.6729 0.5 22.6562V2.34375C0.5 1.32671 1.327 0.5 2.35 0.5ZM13.0333 10.9375C13.0333 8.93403 11.4056 7.3125 9.4 7.3125C7.39688 7.3125 5.76667 8.93647 5.76667 10.9375C5.76667 12.9383 7.39418 14.5625 9.4 14.5625C11.4056 14.5625 13.0333 12.941 13.0333 10.9375ZM4.7 20.8125H14.1C14.8057 20.8125 15.3833 20.2383 15.3833 19.5312C15.3833 17.0966 13.4045 15.125 10.9667 15.125H7.83333C5.39449 15.125 3.41667 17.0956 3.41667 19.5312C3.41667 20.2401 3.99252 20.8125 4.7 20.8125ZM7.05 5.1875H11.75C12.4549 5.1875 13.0333 4.6161 13.0333 3.90625C13.0333 3.1964 12.4549 2.625 11.75 2.625H7.05C6.3451 2.625 5.76667 3.1964 5.76667 3.90625C5.76667 4.6161 6.3451 5.1875 7.05 5.1875Z'
				stroke='#F2C94C'
			/>
		</svg>
	);
}

export default IccidSvg;
