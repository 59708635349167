/* MUI */
import { IconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type NavBarListProps = {
	listLabel: string;
	Icon: OverridableComponent<IconTypeMap<unknown, 'svg'>>;
};

function NavBarList({ listLabel, Icon }: NavBarListProps) {
	return (
		<div className='list'>
			<Icon />
			<li>{listLabel}</li>
		</div>
	);
}

export default NavBarList;
