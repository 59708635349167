/* REACT */
import { ReactElement } from 'react';

/* SVG */
import CrossSvg from '../../../components/svg/CrossSvg';
import ConfirmSvg from '../../../components/svg/ConfirmSvg';

/* TYPES */
import { DeviceStateType } from '../../../types/Devices';

type DeviceStateSVGProps = {
	stateDevice: DeviceStateType;
};

function DeviceStateSVG({ stateDevice }: DeviceStateSVGProps): ReactElement {
	if (
		(stateDevice && stateDevice[0].state === 'moving') ||
		(stateDevice && stateDevice[0].state === 'stopped')
	) {
		return (
			<ConfirmSvg
				width={35}
				height={35}
			/>
		);
	} else {
		return (
			<CrossSvg
				width={35}
				height={35}
			/>
		);
	}
}

export default DeviceStateSVG;
