/* REACT */
import { ReactElement } from 'react';

/* STYLED COMPONENTS */
import styled from 'styled-components';

/* COMPONENTS */
import Iccid from './Iccid';
import SimExpiration from './SimExpiration';
import Network from './Network';
import DeviceId from './DeviceId';

/* TYPES */
type GsmProps = {
	deviceId: string;
};

/* VARIABLES STYLED COMPONENTS */
const SimSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	background: #222b32;
	border-radius: 10px;
	color: white;
`;

/* Component */
export default function Sim({ deviceId }: GsmProps): ReactElement {
	/* Render */
	return (
		<SimSection>
			<Network deviceId={deviceId} />
			<SimExpiration deviceId={deviceId} />
			<Iccid deviceId={deviceId} />
			<DeviceId deviceId={deviceId} />
		</SimSection>
	);
}
