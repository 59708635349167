/* REACT ROUTER DOM */
import { Link } from 'react-router-dom';

/* COMPONENTS */
import NavbarLiensUtilesList from './NavbarLiensUtilesLi';

/* MUI */
import TextsmsIcon from '@mui/icons-material/Textsms';
import GradingIcon from '@mui/icons-material/Grading';

/* CSS */
import styled from 'styled-components';

const NavbarLiensUtilesSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LabelSection = styled.p`
	color: lightgray;
	font-style: italic;
`;

const NavbarLiensUtilesUl = styled.ul`
	list-style: none;
	text-decoration: none;
	color: white;
	&:hover,
	&:focus,
	&:active,
	&:visited,
	&:link {
		color: white;
	}
`;

function NavbarLiensUtiles() {
	return (
		<NavbarLiensUtilesSection>
			<LabelSection>Liens utiles</LabelSection>
			<NavbarLiensUtilesUl>
				<Link to={'/help'}>
					<NavbarLiensUtilesList
						listLabel='VIP Helps Desks'
						Icon={TextsmsIcon}
						notification={5}
					/>
				</Link>
				<NavbarLiensUtilesList
					listLabel='Documentation'
					Icon={GradingIcon}
					notification={0}
				/>
			</NavbarLiensUtilesUl>
		</NavbarLiensUtilesSection>
	);
}

export default NavbarLiensUtiles;
