type OfflineSvgProps = {
	width: number;
	height: number;
};

function OfflineSvg({ width, height }: OfflineSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 19 18'
			fill='none'
		>
			<g clipPath='url(#clip0_1514_3774)'>
				<path
					d='M2.96755 1.5L2.01505 2.46L3.55255 3.9975C2.57755 4.4475 1.64005 5.0025 0.800049 5.67C3.82255 9.465 9.51505 16.545 9.51505 16.545L12.4475 12.8925L14.9375 15.3825L15.89 14.43M9.51505 2.67C8.46505 2.67 7.42255 2.7975 6.41005 3.045L14.15 10.7775C15.515 9.045 17.0525 7.17 18.2525 5.67C15.755 3.7275 12.68 2.67 9.51505 2.67Z'
					fill='#E9696D'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1514_3774'>
					<rect
						width='18'
						height='18'
						fill='white'
						transform='translate(0.800049)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default OfflineSvg;
