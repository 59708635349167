/* REACT */
import { ReactElement } from 'react';

/* COMPONENTS */
import SearchBar from './searchBar/SearchBar';
import ResetButton from '../../components/button/ResetButton';
import ConnectButton from 'components/button/ConnectButton';

/* SVG */
import ResetConnectionSvg from '../../components/svg/ResetConnectionSvg';
import ResetCpuSvg from '../../components/svg/ResetCpuSvg';
import ResetSdCard from '../../components/svg/ResetSdCard';
import WebConnectSvg from '../../components/svg/WebConnectSvg';
import ConnectSvg from 'components/svg/ConnectSvg';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const DashboardHeaderSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 3% 0;
	width: 100%;
`;

const ResetSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	gap: 2%;
`;

/* Composant qui affiche la barre de recherche et les boutons d'envoie de commandes sms + connection à un client client */
function DashboardHeader(): ReactElement {
	/* Render */
	return (
		<DashboardHeaderSection>
			<SearchBar />
			<ResetSection>
				<ResetButton
					label={'Calibrate'}
					svg={
						<ResetSdCard
							height={17}
							width={15}
							color={'#F2C94C'}
						/>
					}
					smsCommand={''}
				/>
				<ResetButton
					label={'Web Connect'}
					svg={
						<WebConnectSvg
							height={17}
							width={15}
							color={'#F2C94C'}
						/>
					}
					smsCommand={'web_connect'}
				/>
				<ResetButton
					label={'Reset Connection'}
					svg={
						<ResetConnectionSvg
							height={17}
							width={15}
							color={'#F2C94C'}
						/>
					}
					smsCommand={''}
				/>
				<ResetButton
					label={'Reset CPU'}
					svg={
						<ResetCpuSvg
							height={17}
							width={15}
							color={'#F2C94C'}
						/>
					}
					smsCommand={'cpureset'}
				/>
				<ResetButton
					label={'Reset SD Card'}
					svg={
						<ResetSdCard
							height={17}
							width={15}
							color={'#F2C94C'}
						/>
					}
					smsCommand={'deleterecords'}
				/>
			</ResetSection>
			<ConnectButton
				label={'Connexion client'}
				svg={
					<ConnectSvg
						height={17}
						width={15}
					/>
				}
			/>
		</DashboardHeaderSection>
	);
}

export default DashboardHeader;
