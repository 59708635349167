/* MUI */
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

/* CSS */
import styled from 'styled-components';

type NavbarLiensUtilesListProps = {
	listLabel: string;
	Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
	notification: number;
};

const NotifSpan = styled.span`
	padding: 5px;
	border-radius: 11px;
	background: #f76519;
	font-weight: bold;
`;

function NavbarLiensUtilesList({ listLabel, Icon, notification }: NavbarLiensUtilesListProps) {
	return (
		<div className='list'>
			<Icon />
			<li>{listLabel}</li>
			{notification > 0 && <NotifSpan>{notification}</NotifSpan>}
		</div>
	);
}

export default NavbarLiensUtilesList;
