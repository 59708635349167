/* STYLED COMPONENTS */
import styled from 'styled-components';
import { Theme } from '../../../styles/Theme';

/* MATERIAL UI */
import { TableCell, TableRow, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: Theme.common.black,
		color: Theme.common.white
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}));

export const StyledTableRow = styled(TableRow)(() => ({
	'&:nth-of-type(odd)': {
		backgroundColor: Theme.common.turquoise
	},
	'&:last-child td, &:last-child th': {
		border: 0
	}
}));
