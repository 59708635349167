/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetDeviceSimInfosQuery } from 'api/fleetApiSlice';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const ModelBox = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	position: relative;
	top: -25px;
	left: 15px;
	gap: 5px;
`;

/* SVG */
import DeviceModelSvg from 'components/svg/DeviceModel';

/* TYPES */
interface DeviceModelProps {
	deviceId: string;
}

/* Component qui permet de vérifier le model du device */
export default function DeviceModel({ deviceId }: DeviceModelProps): ReactElement {
	/* Hooks */
	const { data: deviseSimInfos } = useGetDeviceSimInfosQuery({ deviceId });

	/* Render */
	return (
		<ModelBox>
			<DeviceModelSvg
				width={15}
				height={15}
			/>
			{deviseSimInfos?.model ?? 'Type inconnu'}
		</ModelBox>
	);
}
