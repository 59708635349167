/* REACT ROUTER DOM */
import { Link } from 'react-router-dom';

/* COMPONENTS */
import NavBarList from './NavbarList';

/* MUI */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AddLinkIcon from '@mui/icons-material/AddLink';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';

/* CSS */
import './Navbar.css';
import styled from 'styled-components';

const NavListSections = styled.ul`
	list-style: none;
	text-decoration: none;
	width: 100%;
`;

/* Navbar qui contient les liens vers les différentes sections de l'application */
function NavbarListCategories() {
	return (
		<nav>
			<NavListSections>
				<Link to={'/'}>
					<NavBarList
						listLabel='Dashboard Support'
						Icon={InsertChartIcon}
					/>
				</Link>
				<Link to={'/new-account'}>
					<NavBarList
						listLabel='Création de compte'
						Icon={AddCircleOutlineIcon}
					/>
				</Link>
				<Link to={'/devices-link'}>
					<NavBarList
						listLabel='Liaison de devices'
						Icon={AddLinkIcon}
					/>
				</Link>
				<Link to={'/send-sms'}>
					<NavBarList
						listLabel='Envoi de SMS'
						Icon={SendToMobileIcon}
					/>
				</Link>
				<Link to={'/anomalies'}>
					<NavBarList
						listLabel="Rapport d'anomalies"
						Icon={ReportProblemIcon}
					/>
				</Link>
				<Link to={'/delete-data'}>
					<NavBarList
						listLabel='Suppression de données'
						Icon={DeleteOutlineIcon}
					/>
				</Link>
			</NavListSections>
		</nav>
	);
}

export default NavbarListCategories;
