/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import {
	useGetSimStatusAndOperatorQuery,
	useGetSimSessionQuery,
	useGetSimBrandQuery
} from '../../../api/fleetApiSlice';

/* SVG */
import ConfirmSvg from '../../../components/svg/ConfirmSvg';
import CrossSvg from '../../../components/svg/CrossSvg';
import OnceSvg from '../../../components/svg/OnceSvg';
import TruephoneSvg from '../../../components/svg/TruephoneSvg';

/* CSS */
import styled from 'styled-components';

const DeviceSimSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 10%;
  min-width: 28%;
  height: 75px;
  background: #222b32;
  font-size: 0.7rem;
  color: white;
  border-radius: 10px;
  padding 0 15px;
  text-align: center;
`;

const StatusSessionText = styled.p`
	color: ${(props) => props.color};
`;

const StatusText = styled.p`
	font-weight: bold;
`;

type DeviceSimProps = {
	deviceId: string;
};

function DeviceSim({ deviceId }: DeviceSimProps): ReactElement {
	const { data: simStatus } = useGetSimStatusAndOperatorQuery({ deviceId });
	const { data: simSession } = useGetSimSessionQuery({ deviceId });
	const { data: simBrand } = useGetSimBrandQuery({ deviceId });

	function simInformations(
		deviceSimStatus = simStatus,
		deviceSimSession = simSession
	): ReactElement[] {
		if (deviceSimStatus && deviceSimSession) {
			return [
				<div key='status'>
					{deviceSimSession && deviceSimSession.status && deviceSimSession.status === 'Enabled' ? (
						<StatusSessionText color='#6FCF97'>Activée</StatusSessionText>
					) : (
						<StatusSessionText color='#EB5757'>Désactivée</StatusSessionText>
					)}
					{deviceSimStatus && deviceSimStatus.status?.toLowerCase() === 'online' ? (
						<StatusSessionText color='#6FCF97'>En ligne</StatusSessionText>
					) : (
						<StatusSessionText color='#EB5757'>{deviceSimStatus.status}</StatusSessionText>
					)}
				</div>,
				deviceSimSession && deviceSimSession.status === 'Enabled' ? (
					<ConfirmSvg
						key='confirm'
						width={35}
						height={35}
					/>
				) : (
					<CrossSvg
						key='cross'
						width={35}
						height={35}
					/>
				)
			];
		} else {
			return [
				<div key='status'>
					<p>Statut SIM inconnu</p>
				</div>,
				<CrossSvg
					key='cross'
					width={35}
					height={35}
				/>
			];
		}
	}

	return (
		<DeviceSimSection>
			{simBrand === '1nce' ? (
				<OnceSvg
					height={40}
					width={40}
				/>
			) : (
				<TruephoneSvg
					height={40}
					width={40}
				/>
			)}

			<div>
				<StatusText>SIM</StatusText>
				<StatusText>SESSION</StatusText>
			</div>
			{simInformations()}
		</DeviceSimSection>
	);
}

export default DeviceSim;
