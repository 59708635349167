/* REACT */
import { ReactElement } from 'react';

/* REDUX */
import { useGetPreviousHoursGpsPointsQuery } from '../../../api/fleetApiSlice';

/* SVG */
import GpsPointSvg from '../../../components/svg/GpsPointSvg';
import FalseSvg from '../../../components/svg/CrossSvg';
import ConfirmSvg from '../../../components/svg/ConfirmSvg';

/* CSS */
import styled from 'styled-components';

const GpsPositionHoursSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 10%;
    min-width: 28%;
    height: 75px;
    background: #222b32;
    font-size: 0.7rem;
    color: white;
    border-radius: 10px;
    padding 0 15px;
    text-align: center;
`;

function GpsPositionHours({ deviceId }: { deviceId: string }): ReactElement {
	const { data: gpsPointPreviousHour } = useGetPreviousHoursGpsPointsQuery({ deviceId });

	function gpsPointCount(previousHourGpsPoints = gpsPointPreviousHour) {
		if (previousHourGpsPoints) {
			return (
				<>
					<div>
						<p style={{ fontWeight: 'bold' }}>GPS Position H-1</p>

						{previousHourGpsPoints[0].previousHoursGpsPointsNumber > 0 ? (
							<p>{`${previousHourGpsPoints[0].previousHoursGpsPointsNumber} positions`}</p>
						) : (
							<p>Aucune position</p>
						)}
					</div>
					{previousHourGpsPoints[0].previousHoursGpsPointsNumber > 0 ? (
						<ConfirmSvg
							width={35}
							height={35}
						/>
					) : (
						<FalseSvg
							width={35}
							height={35}
						/>
					)}
				</>
			);
		} else {
			return (
				<>
					<div>
						<p style={{ fontWeight: 'bold' }}>GPS Position H-1</p>
						<p>Nombres de positions GPS indisponible</p>
					</div>
					<FalseSvg
						width={35}
						height={35}
					/>
				</>
			);
		}
	}

	return (
		<GpsPositionHoursSection>
			<GpsPointSvg
				height={30}
				width={30}
			/>
			{gpsPointCount()}
		</GpsPositionHoursSection>
	);
}

export default GpsPositionHours;
