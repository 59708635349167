/* REACT */
import { ReactElement } from 'react';

/* SVG */
import ImmatriculationSvg from 'components/svg/ImmatriculationSvg';

/* TYPES */
interface DeviceImmatriculationProps {
	immatriculation: string;
}

/* STYLED COMPONENTS */
import styled from 'styled-components';

const ImmatriculationBox = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	position: relative;
	left: 15px;
	gap: 5px;
`;

/* Component qui affiche l'immatriculation d'un device */
export default function DeviceImmatriculation({
	immatriculation
}: DeviceImmatriculationProps): ReactElement {
	/* Render */
	return (
		<ImmatriculationBox>
			<ImmatriculationSvg
				width={15}
				height={15}
			/>
			<p>{immatriculation}</p>
		</ImmatriculationBox>
	);
}
