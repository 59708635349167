type CrossSvgProps = {
	width: number | string;
	height: number | string;
};

function CrossSvg({ width, height }: CrossSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 31 31'
			fill='none'
		>
			<circle
				cx='15.5'
				cy='15.5'
				r='15'
				stroke='#EB5757'
			/>
			<path
				d='M22.5 9.91L21.09 8.5L15.5 14.09L9.91 8.5L8.5 9.91L14.09 15.5L8.5 21.09L9.91 22.5L15.5 16.91L21.09 22.5L22.5 21.09L16.91 15.5L22.5 9.91Z'
				fill='#EB5757'
			/>
		</svg>
	);
}

export default CrossSvg;
