/* REACT */
import { type ReactElement } from 'react';

/* MUI */
import { ListItem, ListItemText, ListItemAvatar, Avatar, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

/* TYPES */
type ReviewListItemProps = {
	Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
		muiName: string;
	};
	label: string;
	data: string;
	color?:
		| 'primary'
		| 'secondary'
		| 'action'
		| 'error'
		| 'disabled'
		| 'inherit'
		| 'info'
		| 'success'
		| 'warning'
		| 'green';
};

/* COMPONENT */
export default function ReviewListItem({
	Icon,
	label,
	data,
	color
}: ReviewListItemProps): ReactElement {
	/* Render */
	return (
		<ListItem>
			<ListItemAvatar>
				<Avatar style={{ backgroundColor: color }}>
					<Icon />
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				color='success'
				primary={label}
				secondary={data}
				secondaryTypographyProps={{ color: color }}
				primaryTypographyProps={{ color: color }}
			/>
		</ListItem>
	);
}
