type UnplugSvgProps = {
	width: number;
	height: number;
};

function UnplugSvg({ width, height }: UnplugSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 23 24'
			fill='none'
		>
			<path
				d='M9.21327 0H6.68562V2.37599L9.21327 4.90363V0ZM16.7962 7.58294V12.4866L19.0711 14.7615L19.3239 14.5087V7.58294C19.3239 6.19273 18.1864 5.05529 16.7962 5.05529V0H14.2686V5.05529H9.36493L11.8926 7.58294H16.7962ZM1.78199 1.06161L0 2.8436L4.15798 7.00158V14.534L8.58136 18.9573V22.7488H14.9005V18.9573L15.5071 18.3507L21.1564 24L22.9384 22.218L1.78199 1.06161ZM12.3728 17.9084V20.2212H11.109V17.9084L6.68562 13.4597V9.52923L13.7251 16.5687L12.3728 17.9084Z'
				fill='#EB5757'
			/>
		</svg>
	);
}

export default UnplugSvg;
