/* REACT */
import { ReactElement } from 'react';

/* CONTEXTS */
import { useToast } from '../../../contexts/ToastContext';

/* STYLED COMPONENTS */
import styled from 'styled-components';
import IdSvg from '../../../components/svg/IdSvg';

/* TYPES */
type DeviceIdProps = {
	deviceId: string;
};

/* VARIABLES STYLED COMPONENTS */
const DeviceBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	margin-left: 10px;
`;

const DeviceText = styled.p``;

const Deviceid = styled.p`
	color: grey;
	&:hover {
		cursor: pointer;
	}
`;

/* Component */
export default function DeviceId({ deviceId }: DeviceIdProps): ReactElement {
	/* Hooks */
	const { showToast } = useToast();

	// Function qui copie le deviceId dans le presse papier
	function copyToClipboard() {
		navigator.clipboard.writeText(deviceId);

		return showToast('Numéro du Device copié dans le presse-papier !', 'success');
	}

	// Function qui check si le deviceId existe (si oui on l'affiche sinon on affiche indisponible)
	function checkDeviceIsExist(): string {
		if (deviceId) {
			return deviceId;
		} else {
			return 'Indisponible';
		}
	}

	/* Render */
	return (
		<DeviceBox>
			<IdSvg
				height={20}
				width={20}
			/>
			<DeviceText>N° du Device</DeviceText>
			<Deviceid onClick={copyToClipboard}>{checkDeviceIsExist()}</Deviceid>
		</DeviceBox>
	);
}
