type PlugSvgProps = {
	width: number;
	height: number;
};

function PlugSvg({ width, height }: PlugSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 16 24'
			fill='none'
		>
			<path
				d='M13.3333 8V14.2133L8.66667 18.8933V21.3333H7.33333V18.8933L2.66667 14.2V8H13.3333ZM13.3333 0H10.6667V5.33333H5.33333V0H2.66667V5.33333H2.65333C1.2 5.32 0 6.52 0 7.97333V15.3333L4.66667 20V24H11.3333V20L16 15.32V8C16 6.53333 14.8 5.33333 13.3333 5.33333V0Z'
				fill='#6FCF97'
			/>
		</svg>
	);
}

export default PlugSvg;
