/* REACT */
import { ReactElement } from 'react';

/* COMPONENTS */
import FirmwareInformations from './FirmwareInformations';
import DeviceStateSVG from './DeviceStateSVG';

/* TYPES */
import { DeviceStateType, APIFotaDeviceAndSimInformations } from '../../../types/Devices';

type DeviceStateStatusProps = {
	stateDevice: DeviceStateType;
	deviseSimInfos: APIFotaDeviceAndSimInformations;
};

/* STYLED COMPONENTS */
import styled from 'styled-components';

const StateDeviceBox = styled.div``;

const UnknownState = styled.p``;

const Plugged = styled.p`
	font-weight: bold;
	color: #6fcf97;
	text-align: center;
`;

const Unplugged = styled.p`
	font-weight: bold;
	color: #eb5757;
`;

/* COMPONENT */
function DeviceStateStatus({ stateDevice, deviseSimInfos }: DeviceStateStatusProps): ReactElement {
	return (
		<>
			{stateDevice ? (
				<StateDeviceBox>
					{stateDevice[0]?.state === 'moving' || stateDevice[0].state === 'stopped' ? (
						<Plugged>Branché</Plugged>
					) : (
						<Unplugged>Débranché</Unplugged>
					)}

					<FirmwareInformations deviseSimInfos={deviseSimInfos} />
				</StateDeviceBox>
			) : (
				<UnknownState>State inconnu</UnknownState>
			)}
			<DeviceStateSVG stateDevice={stateDevice} />
		</>
	);
}

export default DeviceStateStatus;
