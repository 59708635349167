/* REACT */
import { ReactElement } from 'react';

/* SVG */
import CarSvg from 'components/svg/CarSvg';

/* TYPES */
interface DeviceNameProps {
	name: string;
}

/* STYLED COMPONENTS */
import styled from 'styled-components';

const NameBox = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	position: relative;
	top: -20px;
	left: 15px;
	gap: 5px;
`;

/* Component qui affiche le nom d'un device */
export default function DeviceName({ name }: DeviceNameProps): ReactElement {
	/* Render */
	return (
		<NameBox>
			<CarSvg
				width={15}
				height={15}
			/>
			<p>{name.slice(0, 20)}</p>
			{name.length > 20 ? '...' : ''}
		</NameBox>
	);
}
