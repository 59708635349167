/* YUP */
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	deviceId: Yup.string()
		.min(15, 'Le numéro de device doit être une chaîne de 15 chiffres')
		.matches(/^\d{15}$/, 'Le numéro de device doit être une chaîne de 15 chiffres')
		.required('Le numéro de device est requis'),
	vehicleName: Yup.string()
		.max(255, 'Le nom du véhicule doit être inférieur à 255 caractères')
		.required('Le nom du véhicule est requis'),
	immatriculation: Yup.string()
		.max(20, "L'immatriculation du véhicule doit être inférieur à 20 caractères")
		.required("L'immatriculation du véhicule est requis"),
	deviceType: Yup.string()
		.max(15)
		.required('Le type du device est requis et doit être inférieur à 20 caractères'),
	type: Yup.string().max(50).required('Le type du device est requis'),
	thirdParties: Yup.array().required('Un ou plusiurs third parties sont requis')
});

export default validationSchema;
