/* REACT */
import { ReactElement } from 'react';

/* CSS */
import { Theme } from '../../styles/Theme';

/* REACT TOSTIFY */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* COMPONENTS */
import DeleteDataForm from './DeleteDataForm';

/* STYLED COMPONENTS */
import styled from 'styled-components';

const DeleteDataSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	height: auto;
	width: 100%;
	position: absolute;
	right: 0;
	background-color: ${Theme.dark.background.primary};
`;

/* Section de formulaire de suppression de données */
export default function DeleteData(): ReactElement {
	/* render */
	return (
		<DeleteDataSection>
			<DeleteDataForm />
			<ToastContainer />
		</DeleteDataSection>
	);
}
