type OnlineSvgProps = {
	width: number;
	height: number;
};

function OnlineSvg({ width, height }: OnlineSvgProps): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 19 18'
			fill='none'
		>
			<g clipPath='url(#clip0_1514_2889)'>
				<path
					d='M9.31485 2.25C6.15735 2.25 3.08985 3.3075 0.599854 5.25C3.62235 9.045 6.23235 12.2775 9.31485 16.125C12.3749 12.315 15.4949 8.43 18.0524 5.25C15.5549 3.3075 12.4799 2.25 9.31485 2.25ZM9.31485 3.75C11.6174 3.75 13.8824 4.395 15.8474 5.5875L14.3924 7.41C13.2599 6.75 11.4749 6 9.31485 6C7.06485 6 5.32485 6.75 4.22235 7.38L2.76735 5.58C4.74735 4.3875 7.01235 3.75 9.31485 3.75Z'
					fill='#72CD94'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1514_2889'>
					<rect
						width='18'
						height='18'
						fill='white'
						transform='translate(0.599854)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default OnlineSvg;
