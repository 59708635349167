/* CSS */
import styled from 'styled-components';

/* SVG */
import AdviceSvg from '../../../components/svg/AdviceSvg';

const AdviceSection = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	height: auto;
	background: rgba(242, 201, 76, 0.1);
	border-radius: 10px;
	width: 100%;
`;

const AdviceInformations = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	font-size: 0.8rem;
`;

function Advice() {
	return (
		<AdviceSection>
			<AdviceSvg
				width={30}
				height={40}
			/>
			<AdviceInformations>
				<p style={{ fontWeight: 'bold' }}>Vérifier le bon branchement du boitier</p>
				<p>
					Demander confirmation au client que le boitier est bien branché sur le véhicule,
					directement sur le pord OBD, sans rallonge. <br />
					Si tel est le cas, effectuer un cpureset et un reset de la SD Card. <br />
					Une fois lers commandes envoyées, après 24h, si le problème persiste, remplacer le
					boitier.
				</p>
			</AdviceInformations>
		</AdviceSection>
	);
}

export default Advice;
