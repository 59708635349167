type LinkSvgProps = {
	height: number;
	width: number;
};

function LinkSvg({ height, width }: LinkSvgProps) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 15 14'
			fill='none'
		>
			<path
				d='M5.80078 7.60397C6.06017 7.95075 6.39111 8.23769 6.77115 8.44532C7.15119 8.65295 7.57144 8.77642 8.00339 8.80736C8.43535 8.8383 8.8689 8.77597 9.27465 8.62462C9.6804 8.47326 10.0488 8.23641 10.355 7.93014L12.167 6.11811C12.7172 5.54852 13.0216 4.78565 13.0147 3.9938C13.0078 3.20196 12.6902 2.44449 12.1303 1.88455C11.5703 1.32461 10.8128 1.007 10.021 1.00011C9.22915 0.993233 8.46628 1.29764 7.89669 1.84776L6.8578 2.88062'
				stroke='#FAD121'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.21402 6.39616C7.95463 6.04938 7.62369 5.76245 7.24365 5.55481C6.86361 5.34718 6.44336 5.22371 6.01141 5.19277C5.57946 5.16184 5.1459 5.22416 4.74015 5.37552C4.33441 5.52687 3.96595 5.76372 3.65979 6.07L1.84776 7.88203C1.29764 8.45161 0.993233 9.21448 1.00011 10.0063C1.007 10.7982 1.32461 11.5556 1.88455 12.1156C2.44449 12.6755 3.20196 12.9931 3.9938 13C4.78565 13.0069 5.54852 12.7025 6.11811 12.1524L7.15096 11.1195'
				stroke='#FAD121'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default LinkSvg;
