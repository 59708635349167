import React from 'react';

/* HOOKS */
import { useNewClientContext } from 'contexts/NewClientContext';

/* STYLES COMPONENTS */
import { StyledTableCell } from './StylesTableReview';

/* MATERIAL UI */
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography
} from '@mui/material';

function ReviewBeaconForm(): React.ReactElement {
	/* Hooks */
	const clientInfos = useNewClientContext().clientInfos;

	return (
		<TableContainer
			component={Paper}
			sx={{
				marginTop: 4
			}}
		>
			<Typography
				variant='h6'
				id='tableTitle'
			>
				Récapitulatif des badges beacons
			</Typography>
			<Table
				sx={{ minWidth: 650 }}
				aria-label='sticky table'
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align='right'>ID</StyledTableCell>
						<StyledTableCell align='right'>Nom</StyledTableCell>
						<StyledTableCell align='right'>Mac</StyledTableCell>
						<StyledTableCell align='right'>type</StyledTableCell>
						<StyledTableCell align='right'>AttributedTo</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{clientInfos.beaconInfos.map((row) => (
						<TableRow
							key={row.id}
							sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
						>
							<TableCell align='right'>{row.id}</TableCell>
							<TableCell align='right'>{row.name}</TableCell>
							<TableCell align='right'>{row.mac}</TableCell>
							<TableCell align='right'>{row.beaconType}</TableCell>
							<TableCell align='right'>{row.attributedTo}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ReviewBeaconForm;
