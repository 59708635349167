/* REACT */
import { ReactElement } from 'react';

/* COMPONENTS */
import DeviceSim from './DeviceSim';
import DeviceState from './DeviceState';
import GpsPositionDays from './GpsPositionDays';
import GpsPositionHours from './GpsPositionHours';
import LastCompletTrip from './LastCompletTrip';
import LastIncompleteTrip from './LastIncompleteTrip';

/* CSS */
import styled from 'styled-components';

const KPISection = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 10px;
	width: 100%;
`;

type KeyPerformanceIndicatorProps = {
	deviceId: string;
};

function KeyPerformanceIndicator({ deviceId }: KeyPerformanceIndicatorProps): ReactElement {
	return (
		<KPISection>
			<DeviceState deviceId={deviceId} />
			<LastCompletTrip deviceId={deviceId} />
			<GpsPositionHours deviceId={deviceId} />
			<DeviceSim deviceId={deviceId} />
			<LastIncompleteTrip deviceId={deviceId} />
			<GpsPositionDays deviceId={deviceId} />
		</KPISection>
	);
}

export default KeyPerformanceIndicator;
